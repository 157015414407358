var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { ref: "visualiserContainer", staticClass: "relative" }, [
      _vm.loadingProgress > 0 && _vm.loadingProgress < 100
        ? _c(
            "div",
            {
              staticClass:
                "absolute top-1/2 left-1/2 flex flex-col justify-center items-center transform -translate-y-1/2 -translate-x-1/2 p-4 z-50",
            },
            [
              _c("div", { staticClass: "loading-bars" }),
              _vm._v(" "),
              _c("small", { staticClass: "opacity-50 pt-4" }, [
                _vm._v(_vm._s(_vm.loadingProgressMessage)),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { ref: "visualiser" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "hidden md:flex items-center absolute bottom-0 right-0 opacity-50 text-eerie-black font-light m-5 pointer-events-none z-10",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "inline-block h-5 ml-2",
              attrs: {
                className: "svg-icon-mouse",
                xmlns: "http://www.w3.org/2000/svg",
                id: "svg8",
                version: "1.1",
                viewBox: "0 0 384 512",
              },
            },
            [
              _c("path", {
                attrs: {
                  fill: "var(--erie-black)",
                  className: "mouseLeftPad",
                  d: "M176 0h-16C71.63 0 0 71.63 0 160v32h176z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  fill: "var(--erie-black)",
                  className: "mouseBody",
                  d: "M0 352c0 88.38 71.63 160 160 160h64c88.38 0 160-71.63 160-160V224H0Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  fill: "var(--erie-black)",
                  className: "mouseRightPad",
                  d: "M224 0h-16v192h176v-32C384 71.63 312.4 0 224 0Z",
                },
              }),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _vm._v("\n        Left Click + Drag to "),
      _c("b", [_vm._v("Spin")]),
      _vm._v("  |  Right Click + Drag to\n        "),
      _c("b", [_vm._v("Pan")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }