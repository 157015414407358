import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { BasisTextureLoader } from "three/examples/jsm/loaders/BasisTextureLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Stats from "stats.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { gsap } from "gsap/all";
import CustomEase from "gsap/CustomEase";

import { swatchTargets } from "../ecoDrifter3DVisualiser/swatchTargets"
import { transparentObjects } from "../ecoDrifter3DVisualiser/transparentObjects"

/** Current timestamp (Date.now()) */
const cacheId = 1691695242200;

gsap.registerPlugin(CustomEase);

/**
 * Important notes:
 * We update mesh matrixes manually on objects that change position / orientation, all other objects won't update their matrixes automatically.
 * This can lead to weird behaviour if you forget! We do this for performance benefits.
 */
export class ThreeVisualiser {
    static IS_DEBUGGING = false;

    static Group_Name_For_Volkswagen_Transporter_Van_Mesh =
        "Volkswagen_Transporter_(Mk5)_(T5)_California_2011";

    static Model_Eco_Drifter_In_A_Volkswagen_Transporter_Transparent_Object_Names = transparentObjects;

    static Clear_Color = new THREE.Color("#ccc"); // background color

    static Camera_Controls_Min_Distance = 1; // min zoom distance
    static Camera_Controls_Max_Distance = 4; // max zoom distance
    static Camera_Controls_Enable_Damping = true; // smoothing of camera when moving
    static Camera_Controls_Damping_Factor = 0.5; // how *much* smoothing...

    /**
     * @param canvasElement {Element} why not use THREE fiber? Because this project is being used on a website that does not include React.
     * @param initCanvasWidth {number}
     * @param initCanvasHeight {number}
     * @param loadingProgressCallback {function} `(EcoDrifter2DVisualiser, progress) => { ... }`
     * @param readyCallback {function} `(EcoDrifter2DVisualiser) => { ... }`
     * @param isOrbitControlsEnabledOnModelLoad {boolean}
     * @param hasOpacityEffectOnCameraDistance {boolean} should the van's opacity change based on how close camera is
     * @param hasLoadingAnimation {boolean}
     */
    constructor(
        canvasElement,
        initCanvasWidth = window.innerWidth,
        initCanvasHeight = window.innerHeight,
        modelUrl,
        modelSize,
        conversionRootNodeName,
        swatchTargets,
        loadingProgressCallback = () => {},
        readyCallback = () => {},
        isOrbitControlsEnabledOnModelLoad = false,
        hasOpacityEffectOnCameraDistance = true,
        hasLoadingAnimation = true
    ) {
        this.canvasElement = canvasElement;
        this.modelUrl = modelUrl;
        this.modelSize = modelSize;
        this.conversionRootNodeName = conversionRootNodeName;
        this.swatchTargets = swatchTargets;
        this.isRendering = false;
        this.isDestoyed = false;
        this.hasFailedLoadingModel = false;
        this.stats = null;
        this.loadingProgressCallback = loadingProgressCallback;
        this.readyCallback = readyCallback;
        this.cameraDistanceToWorldCenter = 0;
        this.hasOpacityEffectOnCameraDistance = hasOpacityEffectOnCameraDistance;
        this.hasLoadingAnimation = hasLoadingAnimation
        
        // Initialize an empty mapping for quick lookups
        this.meshObjectMap = {};

        this.restoreBeforeSwitchingSwatch = null;

        this.restoreInitialTargets = {};

        this.hasLogged = null;

        console.log("iT ME", this)

        this.renderer = new THREE.WebGLRenderer({
            powerPreference: "high-performance",
            antialias: true || window.pixelRatio > 1, // only do antialias on devices that don't have a high resolution display
            stencil: false,
        });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(initCanvasWidth, initCanvasHeight);
        this.renderer.sortObjects = true;
        this.renderer.autoClear = false;
        this.renderer.shadowMap.enabled = true;

        canvasElement.innerHTML = "";
        canvasElement.appendChild(this.renderer.domElement);

        this.camera = new THREE.PerspectiveCamera(
            75,
            initCanvasWidth / initCanvasHeight,
            0.05,
            10
        );

        this.scene = new THREE.Scene();
        this.scene.background = EcoDrifter2DVisualiser.Clear_Color;

        this.scene.add(this.camera);

        if (EcoDrifter2DVisualiser.IS_DEBUGGING) {
            this.stats = new Stats();
            document.body.appendChild(this.stats.dom);

            const axesHelper = new THREE.AxesHelper(5);
            this.scene.add(axesHelper);
        }

        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.minDistance =
            EcoDrifter2DVisualiser.Camera_Controls_Min_Distance;
        this.controls.maxDistance =
            EcoDrifter2DVisualiser.Camera_Controls_Max_Distance;
        this.controls.enableDamping =
            EcoDrifter2DVisualiser.Camera_Controls_Enable_Damping;
        this.controls.dampingFactor =
            EcoDrifter2DVisualiser.Camera_Controls_Damping_Factor;
        this.controls.enabled = false;

        this.raycaster = new THREE.Raycaster();
        this.pointer = new THREE.Vector2();

        this.canvasElement.addEventListener(
            "mousedown",
            this.onPointerDown.bind(this)
        );
        this.canvasElement.addEventListener(
            "mousemove",
            this.onPointerMove.bind(this)
        );

        this.setupLighting();

        this.resetCameraPosition();

        this.startupRendering();

        this.loadModel().then(() => {
            this.controls.enabled = isOrbitControlsEnabledOnModelLoad;

            if (this.hasLoadingAnimation) {
                this.animateCameraPositionSidewaysTowardsVan();
            }

            this.readyCallback(this);

            this.buildMeshObjectMap();

            // Initialize raycaster and mouse vector
            const raycaster = new THREE.Raycaster();
            const mouse = new THREE.Vector2();

            function isObjectVisible(object) {
                if (object.visible === false) {
                    return false;
                }

                if (object.parent) {
                    return isObjectVisible(object.parent);
                }

                return true;
            }

            // Add a click event listener to the canvas
            this.canvasElement.addEventListener('click', (event) => {
                // Check if the Alt key is held down
                if (event.altKey) {
                    // Calculate mouse position relative to the canvas
                    const rect = this.canvasElement.getBoundingClientRect();
                    mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
                    mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

                    // Update the raycaster
                    raycaster.setFromCamera(mouse, this.camera);

                    // Perform raycasting
                    const intersects = raycaster.intersectObjects(this.scene.children);

                    // Filter out objects that are not visible or their parents are not visible
                    const visibleIntersects = intersects.filter(intersect => isObjectVisible(intersect.object));

                    if (visibleIntersects.length > 0) {
                        // Output the names of the hit meshes to the console
                        const hitMeshNames = visibleIntersects.map(intersect => intersect.object.name);
                        console.log('Hit Mesh Names:', hitMeshNames);
                        window.x = [...(window.x ? window.x : []), hitMeshNames[0]]
                        console.log("Recent mesh hits: ", window.x)
                    }
                }
            });
        });
    }

    get metalTargets() {
        if (typeof this.swatchTargets === "undefined" || this.swatchTargets === null) return []

        return Object.values(this.swatchTargets)
    }

    startupRendering() {
        if (this.isRendering) return;
        this.isRendering = true;
        this.renderFrame();
    }

    stopRendering() {
        this.isRendering = false;
    }

    renderFrame() {
        if (this.isRendering === false) return;
        if (this.isDestoyed)
            throw new Error(
                "This instance of EcoDrifter2DVisualiser has been destroyed, create a new one."
            );

        requestAnimationFrame(this.renderFrame.bind(this));

        if (this.stats) this.stats.begin();

        this.controls.update();

        const currentCameraDistanceToWorldCenter = this.camera.position.distanceTo(
            new THREE.Vector3(0, 3, 0)
        );

        if (
            this.hasOpacityEffectOnCameraDistance &&
            this.cameraDistanceToWorldCenter !== currentCameraDistanceToWorldCenter
        ) {
            // Change the opacity of Van based on how close the camera is to the world center
            const minDistance = 2.75;
            const maxDistance = 1.5;

            const distance = Math.max(
                0,
                currentCameraDistanceToWorldCenter - minDistance
            );

            const object = this.scene.getObjectByName(
                EcoDrifter2DVisualiser.Group_Name_For_Volkswagen_Transporter_Van_Mesh
            );

            if (object) {
                EcoDrifter2DVisualiser.Model_Eco_Drifter_In_A_Volkswagen_Transporter_Transparent_Object_Names.map(
                    (name) => {
                        const childObject = object.getObjectByName(name);

                        if (childObject && childObject.material) {
                            childObject.material.opacity =
                                distance < maxDistance
                                    ? Math.max(0.045, distance / maxDistance)
                                    : 1;
                            childObject.material.transparent = true;
                            return;
                        }
                    }
                );
            }
        }

        this.cameraDistanceToWorldCenter = currentCameraDistanceToWorldCenter;

        this.renderer.render(this.scene, this.camera);
        this.renderer.clearDepth();

        if (this.stats) this.stats.end();
    }

    resize(width, height) {
        if (this.isDestoyed) return;

        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(width, height);
    }

    destroy() {
        if (this.isDestoyed) return;

        this.glb.scene.traverse((object) => {
            if (object.geometry) {
                object.geometry.dispose()
            }
            if (object.material) {
                object.material.dispose()
            }
            object = null
        })

        this.stopRendering();
        this.isDestoyed = true;
        this.canvasElement.removeEventListener(
            "mousedown",
            this.onPointerDown.bind(this)
        );
        this.scene.remove(this.glb.scene);
        this.glb = null;
        this.renderer.renderLists.dispose()
        this.renderer.forceContextLoss()
            
    }

    /**
     * Fetch + Load model into scene
     */
    loadModel() {
        return new Promise((resolve) => {
            this.fetchModel().then(() => {
                this.debug("model loaded", this);

                const clickablesGroup = this.glb.scene.getObjectByName("Clickables");

                if (clickablesGroup) clickablesGroup.removeFromParent();

                this.scene.add(this.glb.scene);

                const loader = new THREE.TextureLoader();

                loader.load(
                    "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/1214/conversions/60ed7973f49a56f8997db579_WSA2004_Farmhouse-Oak-min-webp.webp?v=1656595183",
                    (texture) => {
                        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
                        texture.offset.set(0, 0);
                        texture.repeat.set(2, 2);

                        const targets =
                            this.swatchTargets.flooringRange.reduce(
                                (carry, swatchTarget) => {
                                    const node = this.glb.scene.getObjectByName(swatchTarget);
                                    if (node && node.material) {


                                        carry.push({
                                            node,
                                            material: node.material,
                                        });
                                    } else if (
                                        node.children.length >= 2 &&
                                        node.children[0].material
                                    ) {

                                        node.children[0].material.side = THREE.DoubleSide

                                        carry.push({
                                            node: node.children[0],
                                            material: node.children[0].material,
                                        });
                                    }
                                    return carry;
                                },
                                []
                            );

                        targets.forEach((target) => {
                            target.node.material = new THREE.MeshBasicMaterial({
                                map: texture.clone(),
                            });
                            // target.node.material.side = THREE.DoubleSide
                            target.node.material.needsUpdate = true;
                        });

                        resolve();
                    }
                );

                // end of flooring
            });
        });
    }

    resetVanTextures() {
        if (
            this.restoreInitialTargets &&
            Object.keys(this.restoreInitialTargets).length
        ) {
            Object.values(this.restoreInitialTargets).forEach((target) => {
                target.node.material = target.material;
            });
            this.restoreInitialTargets = {};
        }
    }

    switchSwatch(
        swatchTargets,
        imageTextureUrl,
        imageTextureSize = 1.25,
        isAnimating = true,
        progress = () => {}
    ) {
        return new Promise((resolve, reject) => {
            if (typeof swatchTargets === "undefined" || swatchTargets === null) {
                reject();
                return;
            }

            let targets = swatchTargets.reduce((carry, swatchTarget) => {

                const node = this.glb.scene.getObjectByName(swatchTarget);

                if (node === null) {
                    return carry;
                }
                
                if (node && node.material) {


                    carry.push({
                        node,
                        material: node.material,
                        swatchTarget,
                    });
                } else if (node && node.children.length >= 1) {

                    for (const childNode of node.children) {

                        // ignore bare edges
                        if (childNode.material && childNode.material.name.match(/edge/gi)) {
                            continue;
                        }

                        carry.push({
                            node: childNode,
                            material: childNode.material,
                        })
                    }
                }
                return carry;
            }, []);

            if (isAnimating) {

                 const sums = targets.reduce(
                    (carry, target) => {
                        const position = new THREE.Vector3()                            
                        target.node.getWorldPosition(position)

                        const size = new THREE.Vector3()
                        new THREE.Box3().setFromObject(target.node).getSize(size)

                        return {
                            y: carry.y + position.y + 0.7,
                            size: carry.size + Math.max(...size.toArray()),
                        }
                    },
                    {
                        y: 0,
                        size: 0,
                    }
                )

                const averageYOfTargetsWithCappedRange = sums.y / targets.length;
                const averageSize = sums.size / targets.length;

                // this.animateCameraToObjects(targets.map(target => target.node))

                console.log("looking at ", averageYOfTargetsWithCappedRange, averageSize)

                this.animateCameraPositionSidewaysTowardsVanQuick(
                    averageYOfTargetsWithCappedRange,
                    averageSize,
                );
            }

            // If no texture, reset model textures
            if (typeof imageTextureUrl === "undefined" || imageTextureUrl === null) {
                targets.forEach((target) => {
                    if (this.restoreInitialTargets[target.node.uuid]) {
                        target.node.material = this.restoreInitialTargets[target.node.uuid].material
                        delete this.restoreInitialTargets[target.node.uuid]
                    }
                })
                resolve()
                return
            }

            const loader = new THREE.TextureLoader();

            loader.load(imageTextureUrl, (texture) => {
                texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
                texture.offset.set(0, 0);
                texture.repeat.set(imageTextureSize, imageTextureSize);

                // store later for resetting, if user decides to reset van
                targets.forEach((target) => {
                    if (
                        typeof this.restoreInitialTargets[target.node.uuid] === "undefined"
                    ) {
                        this.restoreInitialTargets[target.node.uuid] = target;
                    }
                });

                const isMetalMaterial = true || !!this.metalTargets.find(
                    (metaTarget) => !!swatchTargets.includes(metaTarget)
                );

                targets.forEach((target) => {
                    target.node.material = isMetalMaterial
                        ? new THREE.MeshStandardMaterial({
                            map: texture.clone(),
                            roughness: 0.9,
                            metalness: 0.7,
                            opacity: 0.9,
                        })
                        : new THREE.MeshBasicMaterial({ map: texture.clone() });

                    target.node.material.side = THREE.DoubleSide

                    target.node.material.needsUpdate = true;
                });

                resolve();
            });
        });
    }

    /**
     * Fetch model from window instance if previously fetched, or fetch anew.
     * Saves model to local instance `this.glb`
     */
    fetchModel() {
        return new Promise((resolve, reject) => {
            const loader = new GLTFLoader();
            const dracoLoader = new DRACOLoader();
            dracoLoader.setDecoderConfig({ type: "wasm" });
            dracoLoader.setDecoderPath("/draco/");
            dracoLoader.setWorkerLimit(10);
            loader.setDRACOLoader(dracoLoader);

            loader.load(
                this.modelUrl + "?" + cacheId,
                (glb) => {
                    glb = this.prepareEcoDrifterModel(glb);

                    this.glb = glb;
                    this.hasFailedLoadingModel = false;
                    this.loadingProgressCallback(this, 100);

                    dracoLoader.dispose() // cleanup
                    resolve();
                },
                (xhr) => {
                    this.loadingProgressCallback(
                        this,
                        Math.min(
                            99,
                            (xhr.loaded /
                                xhr.total) *
                            100
                        ),
                        xhr,
                    );
                },
                (error) => {
                    this.hasFailedLoadingModel = true;
                    reject("Could not load model");
                }
            );
        });
    }

    buildMeshObjectMap() {
        // Traverse the scene and build the mapping
        this.glb.scene.traverse((object) => {
            if (object) {
                this.meshObjectMap[object.name] = object;
            }
        });
    }

    /**
     * 
     * @param {object} showHideMeshes `{ show: str[], hide: str[] }`
     */
    showAndHideMeshes(showHideMeshes) {

        // Build the mapping if it doesn't exist - dramatically speeds up finding mesh objects
        if (Object.keys(this.meshObjectMap).length === 0) {
            this.buildMeshObjectMap();
        }

        // Hide meshes
        for (const name of showHideMeshes.hide) {
            const object = this.meshObjectMap[name];

            if (object) {
                object.visible = false;
                object.needsUpdate = true;
            }
        }

        // Show meshes
        for (const name of showHideMeshes.show) {
            const object = this.meshObjectMap[name];

            if (object) {
                object.visible = true;
                object.needsUpdate = true;
            }
        }
    }

    removeObject3D(object3D) {
        if (!(object3D instanceof THREE.Object3D)) return false;

        // for better memory management and performance
        if (object3D.geometry) object3D.geometry.dispose();

        if (object3D.material) {
            if (object3D.material instanceof Array) {
                // for better memory management and performance
                object3D.material.forEach((material) => material.dispose());
            } else {
                // for better memory management and performance
                object3D.material.dispose();
            }
        }
        object3D.removeFromParent(); // the parent might be the scene or another Object3D, but it is sure to be removed this way
        return true;
    }

    /**
     * Metal materials don't transpose well from Blender to Three JS. This method prepares the GLB model accordingly.
     * (prepare GBL for better rendering of metal materials on THREE JS)
     * @param glb
     * @returns {*}
     */
    prepareEcoDrifterModel(glb) {
        let toRemove = [];

        glb.scene.traverse((node) => {
            
            node.renderOrder = 2;
            node.matrixAutoUpdate = false;
            node.updateMatrix();

            if (node.material && node.material.name === "SolidBareEdgeBrown") {
                node.material.metalness = 0.7;
                node.material.roughness = 1;
            } else if (node.material && node.material.name !== "Pine") {
                node.material.metalness = 0.5;
                node.material.roughness = 0.7;
            }
        });

        const conversion = glb.scene.getObjectByName(this.conversionRootNodeName);

        if (typeof conversion === "undefined" || conversion === null) {
            console.error("Unable to find converion node? ", this.conversionRootNodeName)
        }

        conversion.traverse((node) => {
            node.castShadow = true;
            node.receiveShadow = true;
            if (node.material) {
                node.material.side = THREE.FrontSide;
            }
        });

        const dashboard = glb.scene.getObjectByName("dashboard001");
        dashboard.traverse((node) => {
            node.receiveShadow = true;
        });

        toRemove.forEach((node) => {
            if (node) {
                this.removeObject3D(node);
            }
        });

        return glb;
    }

    setupLighting() {
        const light = new THREE.AmbientLight(0x404040, 7); // soft white light
        this.scene.add(light);

        const directionalLight = new THREE.SpotLight(0xffffff, 2, 15, 0.6);
        directionalLight.position.set(6, 7, 0.75);
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.width = 1000;
        directionalLight.shadow.mapSize.height = 1000;
        directionalLight.shadow.camera.top = 5;
        directionalLight.shadow.camera.bottom = -5;
        directionalLight.shadow.camera.left = -5;
        directionalLight.shadow.camera.right = 5;
        directionalLight.shadow.camera.near = 0.1;
        directionalLight.shadow.camera.far = 20;
        directionalLight.shadow.bias = -0.005;
        directionalLight.shadow.radius = 10
        directionalLight.shadow.blurSamples = 10
        this.scene.add(directionalLight);

        console.log("lighting OIIIII", this)

        const directionalLight2 = new THREE.SpotLight(0xffffff, 1.5, 15, 0.6);
        directionalLight2.position.set(-6, 6, 3);
        this.scene.add(directionalLight2);
    }

    resetCameraPosition() {
        this.camera.position.x = 2;
        this.camera.position.y = 2;
        this.camera.position.z = 5;
        this.controls.target = new THREE.Vector3(0, 1, 0);
    }

    animateCameraPositionToDefault() {
        const durationOfAnimationSeconds = 1;
        const delayAnimationSeconds = 0;
        const easing = CustomEase.create("custom", "M0,0 C0.626,0 0.504,1 1,1");

        return new Promise((resolve) => {
            const shouldReEnableControlsAfterAnimation = this.controls.enabled; // true only if orbit controls not already disabled
            this.controls.enabled = false; // disable orbit controls

            // calculate from --> to vectors for position/rotation of camera
            const fromPosition = this.camera.position;
            const toPosition = new THREE.Vector3(2.5, 2, 5);
            const fromTarget = this.controls.target;
            const toTarget = new THREE.Vector3(0, 1, 0);

            // setup lerp variables and callbacks
            let lerpedPosition = fromPosition.clone();
            const updateCameraPositionFromLerpedPosition = () => {
                this.camera.position.set(
                    lerpedPosition.x,
                    lerpedPosition.y,
                    lerpedPosition.z
                );
            };

            let lerpedTarget = fromTarget.clone();
            const updateOrbitControlsTargetFromLerpedTarget = () => {
                this.controls.target = lerpedTarget;
            };

            // gsap timeline
            const timeline = gsap.timeline({
                onComplete: () => {
                    if (shouldReEnableControlsAfterAnimation)
                        this.controls.enabled = true;
                    resolve();
                },
            });

            // animate!
            timeline.to(
                lerpedPosition,
                {
                    ...toPosition,
                    onUpdate: updateCameraPositionFromLerpedPosition,
                    onComplete: updateCameraPositionFromLerpedPosition,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds
            );

            timeline.to(
                lerpedTarget,
                {
                    ...toTarget,
                    onUpdate: updateOrbitControlsTargetFromLerpedTarget,
                    onComplete: updateOrbitControlsTargetFromLerpedTarget,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds
            );
        });
    }

    /**
     * @param state {boolean}
     */
    setOrbitControlsEnabled(state) {
        this.controls.enabled = state;
    }

    animateCameraPositionSidewaysTowardsVan() {
        const durationOfAnimationSeconds = 1;
        const delayAnimationSeconds = 0;
        const easing = CustomEase.create("custom", "M0,0 C0.626,0 0.504,1 1,1");

        return new Promise((resolve) => {
            const shouldReEnableControlsAfterAnimation = this.controls.enabled; // true only if orbit controls not already disabled
            this.controls.enabled = false; // disable orbit controls

            // calculate from --> to vectors for position/rotation of camera
            const fromPosition = this.camera.position;
            const toPosition = new THREE.Vector3(2, 1.5, 0.5);
            const fromTarget = this.controls.target;
            const toTarget = new THREE.Vector3(
                this.glb.scene.position.x,
                this.glb.scene.position.y + 1,
                this.glb.scene.position.z - 0.75
            );

            // setup lerp variables and callbacks
            let lerpedPosition = fromPosition.clone();
            const updateCameraPositionFromLerpedPosition = () => {
                this.camera.position.set(
                    lerpedPosition.x,
                    lerpedPosition.y,
                    lerpedPosition.z
                );
            };

            let lerpedTarget = fromTarget.clone();
            const updateOrbitControlsTargetFromLerpedTarget = () => {
                this.controls.target = lerpedTarget;
            };

            // gsap timeline
            const timeline = gsap.timeline({
                onComplete: () => {
                    if (shouldReEnableControlsAfterAnimation)
                        this.controls.enabled = true;
                    resolve();
                },
            });

            // animate!
            timeline.to(
                lerpedPosition,
                {
                    ...toPosition,
                    onUpdate: updateCameraPositionFromLerpedPosition,
                    onComplete: updateCameraPositionFromLerpedPosition,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds
            );

            timeline.to(
                lerpedTarget,
                {
                    ...toTarget,
                    onUpdate: updateOrbitControlsTargetFromLerpedTarget,
                    onComplete: updateOrbitControlsTargetFromLerpedTarget,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds
            );
        });
    }

    animateCameraPositionSidewaysTowardsVanQuick(lookAtY = 1.3, size = 0.8) {
        const durationOfAnimationSeconds = 0.8;
        const delayAnimationSeconds = 0.1;
        const easing = CustomEase.create("custom", "M0,0 C0.626,0 0.504,1 1,1");

        lookAtY = Math.max(Math.min(lookAtY, 2.8), 1.3) // upper and lower limit

        return new Promise((resolve) => {
            const shouldReEnableControlsAfterAnimation = this.controls.enabled; // true only if orbit controls not already disabled
            this.controls.enabled = false; // disable orbit controls

            // calculate suitable position and rotation of camera, pointing into the side of the van

            const fromPosition = this.camera.position;

            const toPosition = new THREE.Vector3(
                2.842303012388909 * Math.min(Math.max(size, 1), 1.3), // how far away from side of van
                lookAtY + 0.1, // how high up the van (slightly raised above what we're looking at)
                -0.226135607836432 * Math.max(Math.min(1 - size, 1), 0.7) // how far panned left or right
            );
            const fromTarget = this.controls.target;
            const toTarget = new THREE.Vector3(
                -0.2942381565258469,
                lookAtY,
                -0.27438631379240835
            );

            // setup lerp variables and callbacks
            let lerpedPosition = fromPosition.clone();
            const updateCameraPositionFromLerpedPosition = () => {
                this.camera.position.set(
                    lerpedPosition.x,
                    lerpedPosition.y,
                    lerpedPosition.z
                );
            };

            let lerpedTarget = fromTarget.clone();
            const updateOrbitControlsTargetFromLerpedTarget = () => {
                this.controls.target = lerpedTarget;
            };

            // gsap timeline
            const timeline = gsap.timeline({
                onComplete: () => {
                    if (shouldReEnableControlsAfterAnimation)
                        this.controls.enabled = true;
                    resolve();
                },
            });

            // animate!
            timeline.to(
                lerpedPosition,
                {
                    ...toPosition,
                    onUpdate: updateCameraPositionFromLerpedPosition,
                    onComplete: updateCameraPositionFromLerpedPosition,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds
            );

            timeline.to(
                lerpedTarget,
                {
                    ...toTarget,
                    onUpdate: updateOrbitControlsTargetFromLerpedTarget,
                    onComplete: updateOrbitControlsTargetFromLerpedTarget,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds
            );
        });
    }

    /**
     * Animate camera towards vector. Disables orbit controls only while animating.
     * Note: this method is weighted, to encourage the camera to view vectors from 1 side of the van.
     * Returns promise which resolves once animation is complete and orbit controls re-enabled (only if not disabled before).
     * @param vector3 {THREE.Vector3}
     * @param preferredDistance {number} default = 3
     * @returns {Promise<void>}
     */
    cameraLookAt(vector3, preferredDistance = 3) {
        let durationOfAnimationSeconds = 0.8;
        const delayAnimationSeconds = 0;
        const easing = CustomEase.create("custom", "M0,0 C0.5,0 0.5,1 1,1");
        const cameraYOffset = 1; // always position camera slightly above target
        const cameraXWeight = 3;
        const cameraZWeight = 3;

        return new Promise((resolve) => {
            const shouldReEnableControlsAfterAnimation = this.controls.enabled; // true only if orbit controls not already disabled
            this.controls.enabled = false; // disable orbit controls

            // calculate from --> to vectors for position/rotation of camera
            const fromPosition = this.camera.position;
            const toPosition = vector3
                .clone()
                .add(this.camera.position.clone())
                .setLength(3);
            const fromTarget = this.controls.target;
            const toTarget = vector3.clone();

            // apply weights on camera position...
            toPosition.setY(vector3.y + 1); // always slightly above target vector3
            toPosition.setX(Math.max(1, toPosition.x * 1.25)); // encourage camera to be min 1 away from target
            toPosition.setZ(Math.min(0, toPosition.z * 0.5)); // encourage camera to tend towards the center of the Z axis

            const distanceBetweenFromAndToPosition =
                fromPosition.distanceTo(toPosition);
            const distanceBetweenCameraAndTarget =
                this.camera.position.distanceTo(vector3);

            // may need to increase duration of animation if changing position is quite far...
            if (distanceBetweenFromAndToPosition > 2) {
                durationOfAnimationSeconds = Math.max(
                    durationOfAnimationSeconds,
                    Math.min(3, 0.5 * distanceBetweenFromAndToPosition)
                ); // no less than default duration or more than 3s.
            }

            // setup lerp variables and callbacks
            let lerpedPosition = fromPosition.clone();
            const updateCameraPositionFromLerpedPosition = () => {
                this.camera.position.set(
                    lerpedPosition.x,
                    lerpedPosition.y,
                    lerpedPosition.z
                );
            };

            let lerpedTarget = fromTarget.clone();
            const updateOrbitControlsTargetFromLerpedTarget = () => {
                this.controls.target = lerpedTarget;
            };

            // gsap timeline
            const timeline = gsap.timeline({
                onComplete: () => {
                    if (shouldReEnableControlsAfterAnimation)
                        this.controls.enabled = true;
                    resolve();
                },
            });

            // animate!
            timeline.to(
                lerpedPosition,
                {
                    ...toPosition,
                    onUpdate: updateCameraPositionFromLerpedPosition,
                    onComplete: updateCameraPositionFromLerpedPosition,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds +
                (distanceBetweenCameraAndTarget < distanceBetweenFromAndToPosition
                    ? 0
                    : 0.35)
            );

            timeline.to(
                lerpedTarget,
                {
                    ...toTarget,
                    onUpdate: updateOrbitControlsTargetFromLerpedTarget,
                    onComplete: updateOrbitControlsTargetFromLerpedTarget,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds +
                (distanceBetweenCameraAndTarget > distanceBetweenFromAndToPosition
                    ? 0
                    : 0.35)
            );
        });
    }

    /**
     * Updates raycaster to point in new direction based on mouse pointer (event) and camera
     * @param event
     */
    updateRaycasterWithMouseEvent(event) {
        const rect = this.canvasElement.getBoundingClientRect();

        this.pointer.x =
            ((event.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
        this.pointer.y =
            -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;

        this.raycaster.setFromCamera(this.pointer, this.camera);
    }

    onPointerDown(event) {
        this.updateRaycasterWithMouseEvent(event);
    }

    onPointerMove(event) {
        this.updateRaycasterWithMouseEvent(event);
    }

    get isLoadingModel() {
        return this.glb === undefined && !this.hasFailedLoadingModel;
    }

    /**
     * Only console logs if in debug mode
     */
    debug() {
        if (EcoDrifter2DVisualiser.IS_DEBUGGING)
            console.log("[EcoDrifter2DVisualiser]", ...arguments);
    }
}
