var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pb-2" }, [
    _c(
      "div",
      {
        staticClass: "px-4 py-2 bg-cultured sticky z-10",
        staticStyle: { top: "11rem" },
      },
      [
        _c("h4", { staticClass: "text-flame font-raleway-semibold" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { class: { "mb-16": _vm.isSmall !== true } }, [
      _c("div", [
        _vm.optionsGroupedByCategory !== null
          ? _c(
              "div",
              _vm._l(
                Object.entries(_vm.optionsGroupedByCategory),
                function (ref) {
                  var groupName = ref[0]
                  var group = ref[1]
                  return _c("div", [
                    _vm.hideGroupTitles === false
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "py-2 px-4 text-dim-gray uppercase text-sm font-bold font-inter",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(groupName) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "builder-scrollbar p-4 border-t border-ash-gray mb-5",
                        style: Object.assign(
                          {},
                          {
                            position: "relative",
                            maxWidth: "100%",
                            display: "grid",
                            gridAutoFlow: "column",
                            gridAutoColumns: "125px",
                            gridGap: "5px",
                            columnCount: 1,
                            overflow: "auto",
                          },
                          group.length < 5
                            ? {
                                gridTemplateRows: "125px",
                              }
                            : {
                                gridTemplateRows: "125px 125px",
                                backgroundImage:
                                  "linear-gradient(to right, var(--cultured), var(--cultured)),\n    linear-gradient(to right, var(--cultured), var(--cultured)),\n  \n \n    linear-gradient(to right, rgba(0,0,0,.25), rgba(255,255,255,0)),\n    linear-gradient(to left, rgba(0,0,0,.25), rgba(255,255,255,0))",
                                backgroundPosition:
                                  "left center, right center, left center, right center",
                                backgroundRepeat: "no-repeat",
                                backgroundColor: "var(--cultured)",
                                backgroundSize:
                                  "20px 100%, 20px 100%, 10px 100%, 10px 100%",
                                backgroundAttachment:
                                  "local, local, scroll, scroll",
                              }
                        ),
                      },
                      _vm._l(group, function (ref) {
                        var option = ref.option
                        var index = ref.index
                        return _c("div", { key: index }, [
                          _c("div", { staticClass: "relative h-full" }, [
                            _c(
                              "button",
                              {
                                class: {
                                  "absolute top-0 left-0 w-full h-full border-2 focus:outline-none": 1,
                                  "border-ash-gray hover:border-silver-chalice":
                                    !_vm._selectedIndexes.includes(
                                      Number.parseInt(index)
                                    ),
                                  "border-flame focus:border-flame bg-ash-gray":
                                    _vm._selectedIndexes.includes(
                                      Number.parseInt(index)
                                    ),
                                },
                                attrs: { title: option.title },
                                on: {
                                  click: function ($event) {
                                    _vm.handleClick(option, index)
                                  },
                                },
                              },
                              [
                                typeof option.imageUrl !== "undefined" &&
                                option.imageUrl
                                  ? _c("img", {
                                      staticClass:
                                        "w-full h-full object-cover lazyload",
                                      attrs: { "data-src": option.imageUrl },
                                    })
                                  : option.title
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "inline-flex text-xs leading-2",
                                      },
                                      [_vm._v(_vm._s(option.title))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._selectedIndexes.includes(
                                  Number.parseInt(index)
                                )
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "absolute top-0 right-0 bg-flame text-white px-2 w-8",
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              viewBox: "0 0 448 512",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z",
                                                fill: "currentColor",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            option.title && _vm.disableTitleTag === false
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "-translate-x-1/2 absolute bg-eerie-black bottom-0 lazyloading left-1/2 max-w-xs mb-2 p-1 text-center text-xs text-white transform w-full pointer-events-none",
                                    staticStyle: { width: "calc(100% - 1rem)" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(option.title) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ])
                }
              ),
              0
            )
          : _c(
              "div",
              {
                staticClass: "builder-scrollbar p-4 border-b border-ash-gray",
                style: Object.assign(
                  {},
                  {
                    position: "relative",
                    maxWidth: "100%",
                    display: "grid",
                    gridAutoFlow: "column",
                    gridAutoColumns: "125px",
                    gridGap: "5px",
                    columnCount: 1,
                    overflow: "auto",
                  },
                  _vm.options.length < 5
                    ? {
                        gridTemplateRows: "125px",
                      }
                    : {
                        gridTemplateRows: "125px 125px",
                        backgroundImage:
                          "linear-gradient(to right, var(--cultured), var(--cultured)),\n    linear-gradient(to right, var(--cultured), var(--cultured)),\n  \n \n    linear-gradient(to right, rgba(0,0,0,.25), rgba(255,255,255,0)),\n    linear-gradient(to left, rgba(0,0,0,.25), rgba(255,255,255,0))",
                        backgroundPosition:
                          "left center, right center, left center, right center",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "var(--cultured)",
                        backgroundSize:
                          "20px 100%, 20px 100%, 10px 100%, 10px 100%",
                        backgroundAttachment: "local, local, scroll, scroll",
                      }
                ),
              },
              _vm._l(_vm.options, function (item, index) {
                return _c("div", { key: index }, [
                  _c("div", { staticClass: "relative h-full" }, [
                    _c(
                      "button",
                      {
                        class: {
                          "relative absolute top-0 left-0 w-full h-full border-2 focus:outline-none": 1,
                          "border-ash-gray hover:border-silver-chalice":
                            !_vm._selectedIndexes.includes(
                              Number.parseInt(index)
                            ),
                          "border-flame focus:border-flame bg-ash-gray":
                            _vm._selectedIndexes.includes(
                              Number.parseInt(index)
                            ),
                        },
                        attrs: { title: item.title },
                        on: {
                          click: function ($event) {
                            _vm.handleClick(item, index)
                          },
                        },
                      },
                      [
                        item.imageUrl
                          ? _c("img", {
                              staticClass: "w-full h-full object-cover",
                              attrs: { src: item.imageUrl },
                            })
                          : item.title
                          ? _c("div", {
                              staticClass:
                                "w-full h-full flex items-center justify-center",
                              domProps: {
                                innerHTML: _vm._s(
                                  item.svg ? item.svg : item.title
                                ),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._selectedIndexes.includes(Number.parseInt(index))
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "absolute top-0 right-0 bg-flame text-white px-2 w-8",
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 448 512",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z",
                                        fill: "currentColor",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    item.title && _vm.disableTitleTag === false
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "-translate-x-1/2 absolute bg-eerie-black bottom-0 lazyloading left-1/2 max-w-xs mb-2 p-1 text-center text-xs text-white transform w-full pointer-events-none",
                            staticStyle: { width: "calc(100% - 1rem)" },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.title) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ])
              }),
              0
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }