var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "relative" },
    [
      _c(
        "div",
        {
          ref: "sidebar",
          staticClass:
            "builder-sidebar sticky px-4 py-2 bg-silver-chalice text-white z-20 -mb-24",
        },
        [
          _c("b", [
            _vm._v(
              _vm._s(_vm.currentSectionNumber + 1) +
                "/" +
                _vm._s(_vm.sectionCount)
            ),
          ]),
          _vm._v(": " + _vm._s(_vm.currentSectionTitle) + "\n    "),
          _c(
            "div",
            { staticClass: "flex -mx-1 mt-1" },
            _vm._l(_vm.sectionCount, function (index) {
              return _c("div", {
                key: "headerblock" + index,
                class: {
                  "bg-black h-2 w-full mx-1 transition duration-200": 1,
                  "opacity-75": index - 1 <= _vm.currentSectionNumber,
                  "opacity-25": index - 1 > _vm.currentSectionNumber,
                },
              })
            }),
            0
          ),
          _vm._v(" "),
          _c("div", {
            style: {
              position: "absolute",
              top: 0,
              left: "-100000px",
              width: "100000000px",
              height: "100000000px",
              background: "var(--cultured)",
              transform: "translateY(-100%)",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pt-24" }),
      _vm._v(" "),
      _c(
        "SidebarSection",
        {
          ref: "section1",
          attrs: {
            title: "Select a Van",
            description: "Pick the van type that best describes your vehicle.",
          },
        },
        [
          _c("SectionPicker", {
            attrs: {
              title: "Choose VW, Ford or Other",
              options: _vm.$root.options.vanType.options,
              selectedIndex: _vm.$root.selected.vanType.itemIndex,
              disableTitleTag: "",
            },
            on: {
              onChange: function (item, itemIndex) {
                _vm.$root.setSelected(
                  "vanType",
                  "",
                  item,
                  itemIndex,
                  "Van Type " + item.title
                )
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "px-4 font-inter text-dim-gray",
              staticStyle: { "margin-top": "-4rem" },
            },
            [
              _c("i", [
                _vm._v(
                  _vm._s(
                    _vm.$root.options.vanType.options[
                      _vm.$root.selected.vanType.itemIndex
                    ].message
                  )
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "SidebarSection",
        {
          ref: "section2",
          attrs: {
            title: "Select a Wheelbase",
            description: "Pick if your van is a short or long wheelbase.",
          },
        },
        [
          _c("SectionPicker", {
            attrs: {
              title: "Choose SWB or LWB",
              options: _vm.$root.options.wheelbase.options,
              selectedIndex: _vm.$root.selected.wheelbase.itemIndex,
              disableTitleTag: "",
            },
            on: {
              onChange: function (item, itemIndex) {
                _vm.$root.setSelected(
                  "wheelbase",
                  "",
                  item,
                  itemIndex,
                  "Wheelbase " + item.title
                )
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "SidebarSection",
        {
          ref: "section2a",
          attrs: {
            title: "Select a Boot",
            description: "Pick if your boot has Barn Doors or Tailgate Doors",
          },
        },
        [
          _c("SectionPicker", {
            attrs: {
              title: "Choose Boot Type",
              options: _vm.$root.options.boot.options,
              selectedIndex: _vm.$root.selected.boot.itemIndex,
            },
            on: {
              onChange: function (item, itemIndex) {
                _vm.$root.setSelected(
                  "boot",
                  "",
                  item,
                  itemIndex,
                  "boot " + item.title
                )
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "SidebarSection",
        {
          ref: "section3",
          attrs: {
            title: "Select a Conversion",
            description: "Pick a conversion to fit into your van.",
          },
        },
        [
          _c("SectionPicker", {
            attrs: {
              title: "Conversions",
              options: _vm.$root.options.conversions.filter(function (
                conversion
              ) {
                return !conversion.key.startsWith("eco")
              }),
              selectedIndex: _vm.$root.selected.conversions.itemIndex,
              "big-cards": "",
            },
            on: {
              onChange: function (item, itemIndex) {
                _vm.$root.setSelected(
                  "conversions",
                  "",
                  item,
                  itemIndex,
                  "(Conversion) " + item.title
                )
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.sectionDictionary, function (section, index) {
        return _c(
          "SidebarSection",
          {
            key: index,
            ref: section.ref,
            refInFor: true,
            attrs: { title: section.title, description: section.description },
          },
          _vm._l(section.categories, function (category, index) {
            return _c(
              "div",
              { key: index },
              [
                category.type === "swatch" &&
                (_vm.$root.selected.vanType.itemIndex !== 2 ||
                  category.name !== "blindsCurtains") &&
                _vm.$root.selected.conversions.value.swatchTargets.hasOwnProperty(
                  category.name
                )
                  ? _c("SectionPicker", {
                      attrs: {
                        title: _vm.$root.options.swatches[category.name].title,
                        options:
                          _vm.$root.options.swatches[category.name].options,
                        selectedIndex: _vm.$root.selected[
                          "swatches" + category.name
                        ]
                          ? _vm.$root.selected["swatches" + category.name]
                              .itemIndex
                          : -1,
                        "has-pagination": "",
                      },
                      on: {
                        onChange: function (item, itemIndex) {
                          _vm.$root.setSelected(
                            "swatches",
                            category.name,
                            item,
                            itemIndex,
                            "(Swatch) " + item.title
                          )
                        },
                      },
                    })
                  : category.type === "extra"
                  ? _c(
                      "div",
                      [
                        (category.selectType === undefined ||
                          category.selectType === "multiple") &&
                        !(
                          category.name === "additionalKitchenStorage" &&
                          ![
                            "ecoVoyagerSWB",
                            "ecoExpeditionSWB",
                            "ecoDrifterSWB",
                            "standardVoyagerSWB",
                            "standardExpeditionSWB",
                            "standardDrifterSWB",
                          ].includes(_vm.$root.selected.conversions.value.key)
                        )
                          ? _c("SectionExtra", {
                              attrs: {
                                title:
                                  _vm.$root.options.extras[category.name].title,
                                "category-name": category.name,
                                options:
                                  _vm.$root.options.extras[category.name]
                                    .options,
                                selectedIndexes: Object.keys(
                                  _vm.$root.selected
                                ).reduce(function (carry, selectedKey) {
                                  var reg = new RegExp(
                                    "^extras" + category.name
                                  )
                                  var extractedIndex = selectedKey.replace(
                                    reg,
                                    ""
                                  )
                                  if (
                                    _vm.$root.options.extras[category.name]
                                      .options[extractedIndex]
                                  ) {
                                    carry.push(
                                      _vm.$root.selected[selectedKey].itemIndex
                                    )
                                  }
                                  return carry
                                }, []),
                                "has-pagination": "",
                                "hide-group-titles": "",
                              },
                              on: {
                                onChange: function (item, itemIndex) {
                                  _vm.$root.setToggle(
                                    "extras",
                                    category.name + itemIndex,
                                    item,
                                    itemIndex,
                                    "(Extra, " +
                                      _vm.$root.options.extras[category.name]
                                        .title +
                                      ") " +
                                      item.title
                                  )
                                },
                              },
                            })
                          : category.selectType === "hasRules"
                          ? _c("SectionExtra", {
                              attrs: {
                                title:
                                  _vm.$root.options.extras[category.name].title,
                                options:
                                  _vm.$root.options.extras[category.name]
                                    .options,
                                "category-name": category.name,
                                selectedIndexes: Object.keys(
                                  _vm.$root.selected
                                ).reduce(function (carry, selectedKey) {
                                  var reg = new RegExp(
                                    "^extras" + category.name
                                  )
                                  var extractedIndex = selectedKey.replace(
                                    reg,
                                    ""
                                  )
                                  if (
                                    _vm.$root.options.extras[category.name]
                                      .options[extractedIndex]
                                  ) {
                                    carry.push(
                                      _vm.$root.selected[selectedKey].itemIndex
                                    )
                                  }
                                  return carry
                                }, []),
                                "has-pagination": "",
                                "hide-group-titles": "",
                              },
                              on: {
                                onChange: function (item, itemIndex) {
                                  _vm.$root.setToggle(
                                    "extras",
                                    category.name + itemIndex,
                                    item,
                                    itemIndex,
                                    "(Extra, " +
                                      _vm.$root.options.extras[category.name]
                                        .title +
                                      ") " +
                                      item.title,
                                    category.rules
                                  )
                                },
                              },
                            })
                          : category.selectType === "single"
                          ? _c("SectionExtra", {
                              attrs: {
                                title:
                                  _vm.$root.options.extras[category.name].title,
                                options:
                                  _vm.$root.options.extras[category.name]
                                    .options,
                                "category-name": category.name,
                                selectedIndex: Object.keys(
                                  _vm.$root.selected
                                ).reduce(function (carry, selectedKey) {
                                  var reg = new RegExp(
                                    "^extras" + category.name
                                  )
                                  var extractedIndex = selectedKey.replace(
                                    reg,
                                    ""
                                  )
                                  if (
                                    _vm.$root.options.extras[category.name]
                                      .options[extractedIndex]
                                  ) {
                                    carry =
                                      _vm.$root.selected[selectedKey].itemIndex
                                  }
                                  return carry
                                }, null),
                                "has-pagination": "",
                                "hide-group-titles": "",
                              },
                              on: {
                                onChange: function (item, itemIndex) {
                                  // Remove any existing in this category
                                  Object.keys(_vm.$root.selected).forEach(
                                    function (selectedKey) {
                                      var reg = new RegExp(
                                        "^extras" + category.name
                                      )
                                      var extractedIndex = selectedKey.replace(
                                        reg,
                                        ""
                                      )
                                      var foundItem =
                                        _vm.$root.options.extras[category.name]
                                          .options[extractedIndex]

                                      if (
                                        foundItem &&
                                        extractedIndex != itemIndex
                                      ) {
                                        _vm.log("🚀", {
                                          extractedIndex: extractedIndex,
                                          foundItem: foundItem,
                                          item: item,
                                          itemIndex: itemIndex,
                                          cat_name: category.name,
                                        })
                                        _vm.$root.setToggle(
                                          "extras",
                                          category.name + extractedIndex,
                                          foundItem,
                                          extractedIndex,
                                          "(Extra, " +
                                            _vm.$root.options.extras[
                                              category.name
                                            ].title +
                                            ") " +
                                            foundItem.title
                                        )
                                      }
                                    },
                                    null
                                  )

                                  _vm.$nextTick(function () {
                                    _vm.$root.setToggle(
                                      "extras",
                                      category.name + itemIndex,
                                      item,
                                      itemIndex,
                                      "(Extra, " +
                                        _vm.$root.options.extras[category.name]
                                          .title +
                                        ") " +
                                        item.title
                                    )
                                  })
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : category.type === "windows"
                  ? _c("div")
                  : category.type === "configured"
                  ? _c("SectionPicker", {
                      attrs: {
                        title: _vm.$root.options[category.optionIndex].title,
                        options:
                          _vm.$root.options[category.optionIndex].options,
                        selectedIndex:
                          _vm.$root.selected[category.optionIndex].itemIndex,
                        disableTitleTag:
                          typeof category.showTitles === "undefined" ||
                          category.showTitles === false,
                      },
                      on: {
                        onChange: function (item, itemIndex) {
                          _vm.$root.setSelected(
                            category.optionIndex,
                            "",
                            item,
                            itemIndex,
                            "" + item.title
                          )
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                category.hasMessages !== undefined
                  ? _c(
                      "div",
                      {
                        staticClass: "px-4 font-inter text-dim-gray mb-16",
                        staticStyle: { "margin-top": "-4rem" },
                      },
                      [
                        _c("i", [
                          _vm._v(
                            _vm._s(
                              category.optionIndex
                                ? _vm.$root.options[category.optionIndex]
                                    .options[
                                    _vm.$root.selected[category.optionIndex]
                                      .itemIndex
                                  ].message
                                : _vm.$root.selected[
                                    "extras" + category.extraIndex
                                  ]
                                ? _vm.$root.options.extras[category.extraIndex]
                                    .options.extras[
                                    _vm.$root.selected[
                                      "extras" + category.extraIndex
                                    ].itemIndex
                                  ].message
                                : ""
                            )
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        )
      }),
      _vm._v(" "),
      _c(
        "SidebarSection",
        {
          ref: "section15",
          attrs: {
            title: "Contact Information",
            description:
              "We'll get in touch to talk through your van together and share a quote.",
          },
        },
        [_c("ContactInformationForm")],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }