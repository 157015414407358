import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { BasisTextureLoader } from "three/examples/jsm/loaders/BasisTextureLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Stats from "stats.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { gsap } from "gsap/all";
import CustomEase from "gsap/CustomEase";

import { swatchTargets } from "./swatchTargets"
import { clickableMeshes } from "./clickableMeshes"
import { transparentObjects } from "./transparentObjects"
import { hiddenObjects } from "./hiddenObjects"

gsap.registerPlugin(CustomEase);

/**
 * Important notes:
 * We update mesh matrixes manually on objects that change position / orientation, all other objects won't update their matrixes automatically.
 * This can lead to weird behaviour if you forget! We do this for performance benefits.
 */
export class EcoDrifter2DVisualiser {
    static IS_DEBUGGING = false;

    static Model_Eco_Drifter_In_A_Volkswagen_Transporter_GLB =
        "/media/webgl/eco-drifter-in-a-volkswagen-transporter-112.glb"; // public path of GLB file for model
    static Model_Eco_Drifter_In_A_Volkswagen_Transporter_GLB_Size_In_Bytes = 5098000;

    static Group_Name_For_Volkswagen_Transporter_Van_Mesh =
        "Volkswagen_Transporter_(Mk5)_(T5)_California_2011";

    static Model_Eco_Drifter_In_A_Volkswagen_Transporter_Transparent_Object_Names = transparentObjects;
    static Model_Eco_Drifter_In_A_Volkswagen_Transporter_Hidden_Object_Names = hiddenObjects;

    static SwatchTargets = swatchTargets;

    static metalTargets = [
        ...swatchTargets.vanColours,
        ...swatchTargets.seatingOuter,
        ...swatchTargets.seatingInner,
        ...swatchTargets.fridge,
    ];

    static Clickable_Meshes = clickableMeshes;

    static get Clickable_Meshes_Keys() {
        return Object.keys(EcoDrifter2DVisualiser.Clickable_Meshes);
    }

    static Clear_Color = new THREE.Color("#ccc"); // background color

    static Camera_Controls_Min_Distance = 1; // min zoom distance
    static Camera_Controls_Max_Distance = 4; // max zoom distance
    static Camera_Controls_Enable_Damping = true; // smoothing of camera when moving
    static Camera_Controls_Damping_Factor = 0.5; // how *much* smoothing...

    /**
     * @param canvasElement {Element} why not use THREE fiber? Because this project is being used on a website that does not include React.
     * @param initCanvasWidth {number}
     * @param initCanvasHeight {number}
     * @param loadingProgressCallback {function} `(EcoDrifter2DVisualiser, progress) => { ... }`
     * @param readyCallback {function} `(EcoDrifter2DVisualiser) => { ... }`
     * @param onClickableChangeCallback {function} `(EcoDrifter2DVisualiser, Clickable, clickableNameKey) => { ... }`
     * @param isOrbitControlsEnabledOnModelLoad {boolean}
     * @param isShowingClickableMeshes {boolean}
     * @param hasOpacityEffectOnCameraDistance {boolean} should the van's opacity change based on how close camera is
     * @param hasLoadingAnimation {boolean}
     */
    constructor(
        canvasElement,
        initCanvasWidth = window.innerWidth,
        initCanvasHeight = window.innerHeight,
        loadingProgressCallback = () => {},
        readyCallback = () => {},
        onClickableChangeCallback = () => {},
        isOrbitControlsEnabledOnModelLoad = false,
        isShowingClickableMeshes = true,
        hasOpacityEffectOnCameraDistance = true,
        hasLoadingAnimation = true
    ) {
        this.canvasElement = canvasElement;
        this.isRendering = false;
        this.isDestoyed = false;
        this.hasFailedLoadingModel = false;
        this.selectedClickableNameKey = null;
        this.stats = null;
        this.loadingProgressCallback = loadingProgressCallback;
        this.readyCallback = readyCallback;
        this.onClickableChangeCallback = onClickableChangeCallback;
        this.clickables = [];
        this.animatedClickableNames = {};
        this.cameraDistanceToWorldCenter = 0;
        this.isShowingClickableMeshes = isShowingClickableMeshes;
        this.hasOpacityEffectOnCameraDistance = hasOpacityEffectOnCameraDistance;

        this.restoreBeforeSwitchingSwatch = null;

        this.restoreInitialTargets = {};

        this.hasLogged = null;

        this.renderer = new THREE.WebGLRenderer({
            powerPreference: "high-performance",
            antialias: true || window.pixelRatio > 1, // only do antialias on devices that don't have a high resolution display
            stencil: false,
        });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(initCanvasWidth, initCanvasHeight);
        this.renderer.sortObjects = true;
        this.renderer.autoClear = false;
        this.renderer.shadowMap.enabled = true;

        canvasElement.innerHTML = "";
        canvasElement.appendChild(this.renderer.domElement);

        this.camera = new THREE.PerspectiveCamera(
            75,
            initCanvasWidth / initCanvasHeight,
            0.05,
            10
        );

        this.scene = new THREE.Scene();
        this.scene.background = EcoDrifter2DVisualiser.Clear_Color;

        this.clickablesScene = new THREE.Scene();

        if (EcoDrifter2DVisualiser.IS_DEBUGGING) {
            this.stats = new Stats();
            document.body.appendChild(this.stats.dom);

            const axesHelper = new THREE.AxesHelper(5);
            this.scene.add(axesHelper);
        }

        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.minDistance =
            EcoDrifter2DVisualiser.Camera_Controls_Min_Distance;
        this.controls.maxDistance =
            EcoDrifter2DVisualiser.Camera_Controls_Max_Distance;
        this.controls.enableDamping =
            EcoDrifter2DVisualiser.Camera_Controls_Enable_Damping;
        this.controls.dampingFactor =
            EcoDrifter2DVisualiser.Camera_Controls_Damping_Factor;
        this.controls.enabled = false;

        this.raycaster = new THREE.Raycaster();
        this.pointer = new THREE.Vector2();

        this.canvasElement.addEventListener(
            "mousedown",
            this.onPointerDown.bind(this)
        );
        this.canvasElement.addEventListener(
            "mousemove",
            this.onPointerMove.bind(this)
        );

        this.setupLighting();

        this.resetCameraPositionHeadOnTowardsVan();

        this.startupRendering();

        this.loadModel().then(() => {
            this.controls.enabled = isOrbitControlsEnabledOnModelLoad;

            if (this.hasLoadingAnimation) {
                this.animateCameraPositionSidewaysTowardsVan();
            }

            this.readyCallback(this);
        });
    }

    startupRendering() {
        if (this.isRendering) return;
        this.isRendering = true;
        this.renderFrame();
    }

    stopRendering() {
        this.isRendering = false;
    }

    renderFrame() {
        if (this.isRendering === false) return;
        if (this.isDestoyed)
            throw new Error(
                "This instance of EcoDrifter2DVisualiser has been destroyed, create a new one."
            );

        requestAnimationFrame(this.renderFrame.bind(this));

        if (this.stats) this.stats.begin();

        this.controls.update();

        // point all clickables towards camera
        if (this.isShowingClickableMeshes) {
            Object.keys(EcoDrifter2DVisualiser.Clickable_Meshes).forEach(
                (clickableMeshKey) => {
                    const object = this.clickablesScene.getObjectByName(clickableMeshKey);
                    if (object) {
                        object.traverse((node) => {
                            node.lookAt(this.camera.position.negate());
                            node.updateMatrix();
                        });
                    }
                }
            );
        }

        const currentCameraDistanceToWorldCenter = this.camera.position.distanceTo(
            new THREE.Vector3(0, 3, 0)
        );

        if (
            this.hasOpacityEffectOnCameraDistance &&
            this.cameraDistanceToWorldCenter !== currentCameraDistanceToWorldCenter
        ) {
            // Change the opacity of Van based on how close the camera is to the world center
            const minDistance = 2.75;
            const maxDistance = 1.5;

            const distance = Math.max(
                0,
                currentCameraDistanceToWorldCenter - minDistance
            );

            const object = this.scene.getObjectByName(
                EcoDrifter2DVisualiser.Group_Name_For_Volkswagen_Transporter_Van_Mesh
            );

            if (object) {
                EcoDrifter2DVisualiser.Model_Eco_Drifter_In_A_Volkswagen_Transporter_Transparent_Object_Names.map(
                    (name) => {
                        const childObject = object.getObjectByName(name);

                        if (childObject && childObject.material) {
                            childObject.material.opacity =
                                distance < maxDistance
                                    ? Math.max(0.045, distance / maxDistance)
                                    : 1;
                            childObject.material.transparent = true;
                            return;
                        }
                    }
                );
            }
        }

        this.cameraDistanceToWorldCenter = currentCameraDistanceToWorldCenter;

        this.renderer.render(this.scene, this.camera);
        this.renderer.clearDepth();
        this.renderer.render(this.clickablesScene, this.camera);

        if (this.stats) this.stats.end();
    }

    resize(width, height) {
        if (this.isDestoyed) return;

        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(width, height);
    }

    destroy() {
        if (this.isDestoyed) return;

        this.stopRendering();
        this.isDestoyed = true;
        this.canvasElement.removeEventListener(
            "mousedown",
            this.onPointerDown.bind(this)
        );
        this.scene.remove(this.glb.scene);
        this.glb = null;
    }

    /**
     * Fetch + Load model into scene
     */
    loadModel() {
        return new Promise((resolve) => {
            this.fetchModel().then(() => {
                this.debug("model loaded", this);

                const clickablesGroup = this.glb.scene.getObjectByName("Clickables");

                if (this.isShowingClickableMeshes && clickablesGroup) {
                    const clickablesGroupClone = clickablesGroup.clone();
                    this.clickablesScene.add(clickablesGroupClone);
                    this.clickables = clickablesGroupClone.children;
                }

                if (clickablesGroup) clickablesGroup.removeFromParent();

                this.scene.add(this.glb.scene);

                EcoDrifter2DVisualiser.Model_Eco_Drifter_In_A_Volkswagen_Transporter_Hidden_Object_Names.map(
                    (name) => {
                        const childObject = this.scene.getObjectByName(name);

                        if (childObject) {
                            childObject.visible = false
                            
                        }
                    }
                )

                const loader = new THREE.TextureLoader();

                loader.load(
                    "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/1214/conversions/60ed7973f49a56f8997db579_WSA2004_Farmhouse-Oak-min-webp.webp?v=1656595183",
                    (texture) => {
                        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
                        texture.offset.set(0, 0);
                        texture.repeat.set(2, 2);

                        const targets =
                            EcoDrifter2DVisualiser.SwatchTargets.Flooring.reduce(
                                (carry, swatchTarget) => {
                                    const node = this.glb.scene.getObjectByName(swatchTarget);
                                    if (node && node.material) {
                                        carry.push({
                                            node,
                                            material: node.material,
                                        });
                                    } else if (
                                        node.children.length >= 2 &&
                                        node.children[0].material
                                    ) {
                                        carry.push({
                                            node: node.children[0],
                                            material: node.children[0].material,
                                        });
                                    }
                                    return carry;
                                },
                                []
                            );

                        targets.forEach((target) => {
                            target.node.material = new THREE.MeshBasicMaterial({
                                map: texture.clone(),
                            });
                            target.node.material.needsUpdate = true;
                        });

                        resolve();
                    }
                );

                // end of flooring
            });
        });
    }

    resetVanTextures() {
        if (
            this.restoreInitialTargets &&
            Object.keys(this.restoreInitialTargets).length
        ) {
            Object.values(this.restoreInitialTargets).forEach((target) => {
                target.node.material = target.material;
            });
            this.restoreInitialTargets = {};
        }
    }

    switchSwatch(
        swatchTargets,
        imageTextureUrl,
        imageTextureSize = 1.25,
        isAnimating = true,
        progress = () => {}
    ) {
        return new Promise((resolve, reject) => {
            if (swatchTargets === null) {
                reject();
                return;
            }

            let targets = swatchTargets.reduce((carry, swatchTarget) => {
                const node = this.glb.scene.getObjectByName(swatchTarget);
                if (node && node.material) {
                    carry.push({
                        node,
                        material: node.material,
                    });
                } else if (node.children.length >= 2 && node.children[0].material) {
                    carry.push({
                        node: node.children[0],
                        material: node.children[0].material,
                    });
                }
                return carry;
            }, []);

            if (isAnimating) {
                const averageYOfTargetsWithCappedRange =
                    targets.reduce(
                        (carry, target) =>
                            carry +
                            Math.max(Math.min(target.node.parent.position.y, 2.1), 1.4),
                        0
                    ) / targets.length;

                this.animateCameraPositionSidewaysTowardsVanQuick(
                    averageYOfTargetsWithCappedRange
                );
            }

            const loader = new THREE.TextureLoader();

            loader.load(imageTextureUrl, (texture) => {
                texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
                texture.offset.set(0, 0);
                texture.repeat.set(imageTextureSize, imageTextureSize);

                // store later for resetting, if user decides to reset van
                targets.forEach((target) => {
                    if (
                        typeof this.restoreInitialTargets[target.node.uuid] === "undefined"
                    ) {
                        this.restoreInitialTargets[target.node.uuid] = target;
                    }
                });

                const isMetalMaterial = !!EcoDrifter2DVisualiser.metalTargets.find(
                    (metaTarget) => !!swatchTargets.includes(metaTarget)
                );

                targets.forEach((target) => {
                    target.node.material = isMetalMaterial
                        ? new THREE.MeshStandardMaterial({
                            map: texture.clone(),
                            roughness: 0.8,
                            metalness: 0.7,
                            opacity: 0.9,
                        })
                        : new THREE.MeshBasicMaterial({ map: texture.clone() });

                    target.node.material.needsUpdate = true;
                });

                resolve();
            });
        });
    }

    /**
     * Fetch model from window instance if previously fetched, or fetch anew.
     * Saves model to local instance `this.glb`
     */
    fetchModel() {
        return new Promise((resolve, reject) => {
            const loader = new GLTFLoader();
            const dracoLoader = new DRACOLoader();
            dracoLoader.setDecoderConfig({ type: "wasm" });
            dracoLoader.setDecoderPath("/draco/");
            dracoLoader.setWorkerLimit(10);
            loader.setDRACOLoader(dracoLoader);

            loader.load(
                EcoDrifter2DVisualiser.Model_Eco_Drifter_In_A_Volkswagen_Transporter_GLB,
                (glb) => {
                    glb = this.prepareEcoDrifterModel(glb);

                    this.glb = glb;
                    this.hasFailedLoadingModel = false;
                    this.loadingProgressCallback(this, 100);
                    resolve();
                },
                (xhr) => {
                    this.loadingProgressCallback(
                        this,
                        Math.min(
                            99,
                            (xhr.loaded /
                                EcoDrifter2DVisualiser.Model_Eco_Drifter_In_A_Volkswagen_Transporter_GLB_Size_In_Bytes) *
                            100
                        )
                    );
                },
                (error) => {
                    this.hasFailedLoadingModel = true;
                    reject("Could not load model");
                }
            );
        });
    }

    removeObject3D(object3D) {
        if (!(object3D instanceof THREE.Object3D)) return false;

        // for better memory management and performance
        if (object3D.geometry) object3D.geometry.dispose();

        if (object3D.material) {
            if (object3D.material instanceof Array) {
                // for better memory management and performance
                object3D.material.forEach((material) => material.dispose());
            } else {
                // for better memory management and performance
                object3D.material.dispose();
            }
        }
        object3D.removeFromParent(); // the parent might be the scene or another Object3D, but it is sure to be removed this way
        return true;
    }

    /**
     * Metal materials don't transpose well from Blender to Three JS. This method prepares the GLB model accordingly.
     * (prepare GBL for better rendering of metal materials on THREE JS)
     * @param glb
     * @returns {*}
     */
    prepareEcoDrifterModel(glb) {
        let toRemove = [];

        glb.scene.traverse((node) => {
            if (
                node.name &&
                (node.name === "Clickables" ||
                    Object.keys(EcoDrifter2DVisualiser.Clickable_Meshes).find(
                        (clickableName) => clickableName === node.name
                    ))
            ) {
                if (this.isShowingClickableMeshes === false) {
                    toRemove.push(node);
                    return;
                }

                node.traverse((childNode) => {
                    if (childNode.material) {
                        const texture = childNode.material.map;
                        if (childNode.name != "Blind") {
                            childNode.material = new THREE.MeshBasicMaterial({
                                map: texture,
                            });
                        }
                        childNode.material.depthTest = false;
                        childNode.receiveShadow = false;
                        childNode.castShadow = false;
                        childNode.material.depthWrite = false;
                        childNode.material.polygonOffset = true;
                        childNode.material.polygonOffsetFactor = -10000;
                        childNode.material.polygonOffsetUnits = 10;
                        childNode.material.transparent = true;
                        childNode.material.opacity = 0.8;
                    }
                    childNode.renderOrder = 999;
                });

                node.onBeforeRender = function (renderer) {
                    renderer.clearDepth();
                };

                node.renderOrder = 999;
                return;
            } else {
                node.renderOrder = 2;
                node.matrixAutoUpdate = false;
                node.updateMatrix();
            }

            if (node.material && node.material.name === "SolidBareEdgeBrown") {
                node.material.metalness = 0.7;
                node.material.roughness = 1;
            } else if (node.material && node.material.name !== "Pine") {
                node.material.metalness = 0.6;
                node.material.roughness = 0.8;
            }
        });

        const drifter = glb.scene.getObjectByName("ECO_Drifter_All_In_v3");
        drifter.traverse((node) => {
            node.castShadow = true;
            node.receiveShadow = true;
            if (node.material) {
                node.material.side = THREE.FrontSide;
            }
        });

        const dashboard = glb.scene.getObjectByName("dashboard001");
        dashboard.traverse((node) => {
            node.receiveShadow = true;
        });

        toRemove.forEach((node) => {
            if (node) {
                this.removeObject3D(node);
            }
        });

        return glb;
    }

    setupLighting() {
        const light = new THREE.AmbientLight(0x404040, 7); // soft white light
        this.scene.add(light);

        const directionalLight = new THREE.SpotLight(0xffffff, 2.5, 15, 0.6);
        directionalLight.position.set(6, 7, 0.75);
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.width = 1500;
        directionalLight.shadow.mapSize.height = 1500;
        directionalLight.shadow.camera.top = 5;
        directionalLight.shadow.camera.bottom = -5;
        directionalLight.shadow.camera.left = -5;
        directionalLight.shadow.camera.right = 5;
        directionalLight.shadow.camera.near = 0.1;
        directionalLight.shadow.camera.far = 20;
        this.scene.add(directionalLight);

        const directionalLight2 = new THREE.SpotLight(0xffffff, 1.5, 15, 0.6);
        directionalLight2.position.set(-6, 6, 3);
        this.scene.add(directionalLight2);
    }

    resetCameraPositionHeadOnTowardsVan() {
        this.camera.position.x = 0;
        this.camera.position.y = 2;
        this.camera.position.z = 5;
        this.controls.target = new THREE.Vector3(0, 1, 0);
    }

    /**
     * @param state {boolean}
     */
    setOrbitControlsEnabled(state) {
        this.controls.enabled = state;
    }

    animateCameraPositionSidewaysTowardsVan() {
        const durationOfAnimationSeconds = 3;
        const delayAnimationSeconds = 1;
        const easing = CustomEase.create("custom", "M0,0 C0.626,0 0.504,1 1,1");

        return new Promise((resolve) => {
            const shouldReEnableControlsAfterAnimation = this.controls.enabled; // true only if orbit controls not already disabled
            this.controls.enabled = false; // disable orbit controls

            // calculate from --> to vectors for position/rotation of camera
            const fromPosition = this.camera.position;
            const toPosition = new THREE.Vector3(1.75, 1.25, 1.25);
            const fromTarget = this.controls.target;
            const toTarget = new THREE.Vector3(
                this.glb.scene.position.x,
                this.glb.scene.position.y + 1,
                this.glb.scene.position.z - 0.75
            );

            // setup lerp variables and callbacks
            let lerpedPosition = fromPosition.clone();
            const updateCameraPositionFromLerpedPosition = () => {
                this.camera.position.set(
                    lerpedPosition.x,
                    lerpedPosition.y,
                    lerpedPosition.z
                );
            };

            let lerpedTarget = fromTarget.clone();
            const updateOrbitControlsTargetFromLerpedTarget = () => {
                this.controls.target = lerpedTarget;
            };

            // gsap timeline
            const timeline = gsap.timeline({
                onComplete: () => {
                    if (shouldReEnableControlsAfterAnimation)
                        this.controls.enabled = true;
                    resolve();
                },
            });

            // animate!
            timeline.to(
                lerpedPosition,
                {
                    ...toPosition,
                    onUpdate: updateCameraPositionFromLerpedPosition,
                    onComplete: updateCameraPositionFromLerpedPosition,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds
            );

            timeline.to(
                lerpedTarget,
                {
                    ...toTarget,
                    onUpdate: updateOrbitControlsTargetFromLerpedTarget,
                    onComplete: updateOrbitControlsTargetFromLerpedTarget,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds
            );
        });
    }

    animateCameraPositionSidewaysTowardsVanQuick(lookAtY = 1.425584240781334) {
        const durationOfAnimationSeconds = 0.8;
        const delayAnimationSeconds = 0.1;
        const easing = CustomEase.create("custom", "M0,0 C0.626,0 0.504,1 1,1");

        return new Promise((resolve) => {
            const shouldReEnableControlsAfterAnimation = this.controls.enabled; // true only if orbit controls not already disabled
            this.controls.enabled = false; // disable orbit controls

            // calculate from --> to vectors for position/rotation of camera
            const fromPosition = this.camera.position;
            const toPosition = new THREE.Vector3(
                2.842303012388909,
                Math.max(lookAtY * 0.95, 1.7222271501547604),
                -0.226135607836432
            );
            const fromTarget = this.controls.target;
            const toTarget = new THREE.Vector3(
                -0.2942381565258469,
                lookAtY,
                -0.27438631379240835
            );

            // setup lerp variables and callbacks
            let lerpedPosition = fromPosition.clone();
            const updateCameraPositionFromLerpedPosition = () => {
                this.camera.position.set(
                    lerpedPosition.x,
                    lerpedPosition.y,
                    lerpedPosition.z
                );
            };

            let lerpedTarget = fromTarget.clone();
            const updateOrbitControlsTargetFromLerpedTarget = () => {
                this.controls.target = lerpedTarget;
            };

            // gsap timeline
            const timeline = gsap.timeline({
                onComplete: () => {
                    if (shouldReEnableControlsAfterAnimation)
                        this.controls.enabled = true;
                    resolve();
                },
            });

            // animate!
            timeline.to(
                lerpedPosition,
                {
                    ...toPosition,
                    onUpdate: updateCameraPositionFromLerpedPosition,
                    onComplete: updateCameraPositionFromLerpedPosition,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds
            );

            timeline.to(
                lerpedTarget,
                {
                    ...toTarget,
                    onUpdate: updateOrbitControlsTargetFromLerpedTarget,
                    onComplete: updateOrbitControlsTargetFromLerpedTarget,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds
            );
        });
    }

    /**
     * Animate camera towards vector. Disables orbit controls only while animating.
     * Note: this method is weighted, to encourage the camera to view vectors from 1 side of the van.
     * Returns promise which resolves once animation is complete and orbit controls re-enabled (only if not disabled before).
     * @param vector3 {THREE.Vector3}
     * @param preferredDistance {number} default = 3
     * @returns {Promise<void>}
     */
    cameraLookAt(vector3, preferredDistance = 3) {
        let durationOfAnimationSeconds = 0.8;
        const delayAnimationSeconds = 0;
        const easing = CustomEase.create("custom", "M0,0 C0.5,0 0.5,1 1,1");
        const cameraYOffset = 1; // always position camera slightly above target
        const cameraXWeight = 3;
        const cameraZWeight = 3;

        return new Promise((resolve) => {
            const shouldReEnableControlsAfterAnimation = this.controls.enabled; // true only if orbit controls not already disabled
            this.controls.enabled = false; // disable orbit controls

            // calculate from --> to vectors for position/rotation of camera
            const fromPosition = this.camera.position;
            const toPosition = vector3
                .clone()
                .add(this.camera.position.clone())
                .setLength(3);
            const fromTarget = this.controls.target;
            const toTarget = vector3.clone();

            // apply weights on camera position...
            toPosition.setY(vector3.y + 1); // always slightly above target vector3
            toPosition.setX(Math.max(1, toPosition.x * 1.25)); // encourage camera to be min 1 away from target
            toPosition.setZ(Math.min(0, toPosition.z * 0.5)); // encourage camera to tend towards the center of the Z axis

            const distanceBetweenFromAndToPosition =
                fromPosition.distanceTo(toPosition);
            const distanceBetweenCameraAndTarget =
                this.camera.position.distanceTo(vector3);

            // may need to increase duration of animation if changing position is quite far...
            if (distanceBetweenFromAndToPosition > 2) {
                durationOfAnimationSeconds = Math.max(
                    durationOfAnimationSeconds,
                    Math.min(3, 0.5 * distanceBetweenFromAndToPosition)
                ); // no less than default duration or more than 3s.
            }

            // setup lerp variables and callbacks
            let lerpedPosition = fromPosition.clone();
            const updateCameraPositionFromLerpedPosition = () => {
                this.camera.position.set(
                    lerpedPosition.x,
                    lerpedPosition.y,
                    lerpedPosition.z
                );
            };

            let lerpedTarget = fromTarget.clone();
            const updateOrbitControlsTargetFromLerpedTarget = () => {
                this.controls.target = lerpedTarget;
            };

            // gsap timeline
            const timeline = gsap.timeline({
                onComplete: () => {
                    if (shouldReEnableControlsAfterAnimation)
                        this.controls.enabled = true;
                    resolve();
                },
            });

            // animate!
            timeline.to(
                lerpedPosition,
                {
                    ...toPosition,
                    onUpdate: updateCameraPositionFromLerpedPosition,
                    onComplete: updateCameraPositionFromLerpedPosition,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds +
                (distanceBetweenCameraAndTarget < distanceBetweenFromAndToPosition
                    ? 0
                    : 0.35)
            );

            timeline.to(
                lerpedTarget,
                {
                    ...toTarget,
                    onUpdate: updateOrbitControlsTargetFromLerpedTarget,
                    onComplete: updateOrbitControlsTargetFromLerpedTarget,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds +
                (distanceBetweenCameraAndTarget > distanceBetweenFromAndToPosition
                    ? 0
                    : 0.35)
            );
        });
    }

    /**
     * Updates raycaster to point in new direction based on mouse pointer (event) and camera
     * @param event
     */
    updateRaycasterWithMouseEvent(event) {
        const rect = this.canvasElement.getBoundingClientRect();

        this.pointer.x =
            ((event.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
        this.pointer.y =
            -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;

        this.raycaster.setFromCamera(this.pointer, this.camera);
    }

    /**
     * Check to see if our raycaster is hitting any of our clickables.
     * Updates `this.selectedClickableNameKey` and triggers `onClickableChangeCallback()` if changed
     */
    updateClickables(isMouseDown = false) {
        const intersections = this.raycaster.intersectObjects(
            this.clickables,
            true
        );

        if (isMouseDown) this.debug({ intersections });

        let hoveringObjectName = null;

        if (intersections && intersections.length > 0) {
            for (const intersection of intersections) {
                const clickableObject = intersection.object.parent;

                if (
                    clickableObject.name &&
                    clickableObject.name !== this.selectedClickableNameKey &&
                    Object.keys(EcoDrifter2DVisualiser.Clickable_Meshes).find(
                        (meshName) => meshName === clickableObject.name
                    )
                ) {
                    if (this.selectedClickableNameKey === clickableObject.name) break;

                    if (
                        this.animatedClickableNames[clickableObject.name] &&
                        this.animatedClickableNames[clickableObject.name].labels.sizeNormal
                    )
                        return;

                    hoveringObjectName = clickableObject.name;

                    // gsap timeline
                    const timeline = gsap.timeline({
                        onComplete: () => {
                            delete this.animatedClickableNames[clickableObject.name];
                        },
                    });

                    let lerpedScale = clickableObject.scale.clone();
                    let toScale = new THREE.Vector3(1.4, 1.4, 1.4);
                    const updateLerpedScale = () => {
                        clickableObject.scale.set(
                            lerpedScale.x,
                            lerpedScale.y,
                            lerpedScale.z
                        );
                    };

                    let lerpedOpacity = {
                        opacity: clickableObject.children[0].material.opacity,
                    };
                    let toOpacity = {
                        opacity: 1,
                    };
                    const updateLerpedOpacity = () => {
                        clickableObject.children[0].material.opacity =
                            lerpedOpacity.opacity;
                    };

                    if (isMouseDown) {
                        this.setActiveClickable(clickableObject.name, clickableObject);
                    } else {
                        toScale = new THREE.Vector3(1.1, 1.1, 1.1);
                        toOpacity.opacity = 0.9;
                    }

                    // animate!
                    timeline.to(
                        lerpedScale,
                        {
                            ...toScale,
                            onUpdate: updateLerpedScale,
                            onComplete: updateLerpedScale,
                            duration: 0.2,
                        },
                        0
                    );

                    timeline.to(
                        lerpedOpacity,
                        {
                            ...toOpacity,
                            onUpdate: updateLerpedOpacity,
                            onComplete: updateLerpedOpacity,
                            duration: 0.2,
                        },
                        0
                    );

                    this.animatedClickableNames[clickableObject.name] = timeline;

                    break;
                }
            }
        }

        for (const clickableObject of this.clickables) {
            if (
                this.selectedClickableNameKey === clickableObject.name ||
                hoveringObjectName === clickableObject.name
            )
                continue;

            if (clickableObject.scale.x !== 1) {
                if (
                    this.animatedClickableNames[clickableObject.name] &&
                    !this.animatedClickableNames[clickableObject.name].labels.sizeNormal
                ) {
                    this.animatedClickableNames[clickableObject.name].pause();
                } else if (this.animatedClickableNames[clickableObject.name]) {
                    continue;
                }

                // gsap timeline
                const timeline = gsap.timeline({
                    onComplete: () => {
                        delete this.animatedClickableNames[clickableObject.name];
                    },
                });

                timeline.addLabel("sizeNormal", 1);

                const lerpedScale = clickableObject.scale.clone();
                const toScale = new THREE.Vector3(1, 1, 1);
                const updateLerpedScale = () => {
                    clickableObject.scale.set(
                        lerpedScale.x,
                        lerpedScale.y,
                        lerpedScale.z
                    );
                };

                const lerpedOpacity = {
                    opacity: clickableObject.children[0].material.opacity,
                };
                const toOpacity = {
                    opacity: 0.8,
                };
                const updateLerpedOpacity = () => {
                    clickableObject.children[0].material.opacity = lerpedOpacity.opacity;
                };

                // animate!
                timeline.to(
                    lerpedScale,
                    {
                        ...toScale,
                        onUpdate: updateLerpedScale,
                        onComplete: updateLerpedScale,
                        duration: 0.1,
                    },
                    0
                );

                timeline.to(
                    lerpedOpacity,
                    {
                        ...toOpacity,
                        onUpdate: updateLerpedOpacity,
                        onComplete: updateLerpedOpacity,
                        duration: 0.1,
                    },
                    0
                );

                this.animatedClickableNames[clickableObject.name] = timeline;
            }
        }
    }

    clearActiveClickable() {
        this.selectedClickableNameKey = null;
    }

    setActiveClickable(key, clickableObject = null) {
        this.selectedClickableNameKey = key;
        const clickable =
            EcoDrifter2DVisualiser.Clickable_Meshes[this.selectedClickableNameKey];

        if (clickableObject === null) {
            clickableObject = this.clickablesScene.getObjectByName(key);
        }

        this.onClickableChangeCallback(this, clickable, key);
        this.debug("Found clickable!", clickableObject.name);
        this.cameraLookAt(clickableObject.position, clickable.distance);

        for (const otherClickableObject of this.clickables) {
            if (clickableObject.name === otherClickableObject.name) continue;

            if (otherClickableObject.scale.x !== 1) {
                if (
                    this.animatedClickableNames[otherClickableObject.name] &&
                    !this.animatedClickableNames[otherClickableObject.name].labels
                        .sizeNormal
                ) {
                    this.animatedClickableNames[otherClickableObject.name].pause();
                } else if (this.animatedClickableNames[otherClickableObject.name]) {
                    continue;
                }

                // gsap timeline
                const timeline = gsap.timeline({
                    onComplete: () => {
                        delete this.animatedClickableNames[otherClickableObject.name];
                    },
                });

                timeline.addLabel("sizeNormal", 1);

                const lerpedScale = otherClickableObject.scale.clone();
                const toScale = new THREE.Vector3(1, 1, 1);
                const updateLerpedScale = () => {
                    otherClickableObject.scale.set(
                        lerpedScale.x,
                        lerpedScale.y,
                        lerpedScale.z
                    );
                };

                const lerpedOpacity = {
                    opacity: otherClickableObject.children[0].material.opacity,
                };
                const toOpacity = {
                    opacity: 0.8,
                };
                const updateLerpedOpacity = () => {
                    otherClickableObject.children[0].material.opacity =
                        lerpedOpacity.opacity;
                };

                // animate!
                timeline.to(
                    lerpedScale,
                    {
                        ...toScale,
                        onUpdate: updateLerpedScale,
                        onComplete: updateLerpedScale,
                        duration: 0.1,
                    },
                    0
                );

                timeline.to(
                    lerpedOpacity,
                    {
                        ...toOpacity,
                        onUpdate: updateLerpedOpacity,
                        onComplete: updateLerpedOpacity,
                        duration: 0.1,
                    },
                    0
                );

                this.animatedClickableNames[otherClickableObject.name] = timeline;
            }
        }

        // gsap timeline
        const timeline = gsap.timeline({
            onComplete: () => {
                delete this.animatedClickableNames[clickableObject.name];
            },
        });

        let lerpedScale = clickableObject.scale.clone();
        let toScale = new THREE.Vector3(1.4, 1.4, 1.4);
        const updateLerpedScale = () => {
            clickableObject.scale.set(lerpedScale.x, lerpedScale.y, lerpedScale.z);
        };

        let lerpedOpacity = {
            opacity: clickableObject.children[0].material.opacity,
        };
        let toOpacity = {
            opacity: 1,
        };
        const updateLerpedOpacity = () => {
            clickableObject.children[0].material.opacity = lerpedOpacity.opacity;
        };

        // animate!
        timeline.to(
            lerpedScale,
            {
                ...toScale,
                onUpdate: updateLerpedScale,
                onComplete: updateLerpedScale,
                duration: 0.2,
            },
            0
        );

        timeline.to(
            lerpedOpacity,
            {
                ...toOpacity,
                onUpdate: updateLerpedOpacity,
                onComplete: updateLerpedOpacity,
                duration: 0.2,
            },
            0
        );

        this.animatedClickableNames[clickableObject.name] = timeline;
    }

    onPointerDown(event) {
        this.updateRaycasterWithMouseEvent(event);
        this.updateClickables(true);
    }

    onPointerMove(event) {
        this.updateRaycasterWithMouseEvent(event);
        this.updateClickables();
    }

    get isLoadingModel() {
        return this.glb === undefined && !this.hasFailedLoadingModel;
    }

    /**
     * Only console logs if in debug mode
     */
    debug() {
        if (EcoDrifter2DVisualiser.IS_DEBUGGING)
            console.log("[EcoDrifter2DVisualiser]", ...arguments);
    }
}