<template>
  <div ref="container" class="relative">
    <div
      ref="sidebar"
      class="builder-sidebar sticky px-4 py-2 bg-silver-chalice text-white z-20 -mb-24"
    >
      <b>{{ currentSectionNumber + 1 }}/{{ sectionCount }}</b
      >: {{ currentSectionTitle }}
      <div class="flex -mx-1 mt-1">
        <div
          v-for="index in sectionCount"
          :class="{
            'bg-black h-2 w-full mx-1 transition duration-200': 1,
            'opacity-75': index - 1 <= currentSectionNumber,
            'opacity-25': index - 1 > currentSectionNumber,
          }"
          :key="'headerblock' + index"
        ></div>
      </div>
      <div
        :style="{
          position: 'absolute',
          top: 0,
          left: '-100000px',
          width: '100000000px',
          height: '100000000px',
          background: 'var(--cultured)',
          transform: 'translateY(-100%)',
        }"
      ></div>
    </div>

    <!-- Spacer - needed as our sticky header uses `-mb-24`, so scrolling to bottom looks nice and seamless. -->
    <div class="pt-24"></div>

    <SidebarSection
      ref="section1"
      title="Select a Van"
      description="Pick the van type that best describes your vehicle."
    >
      <SectionPicker
        title="Choose VW, Ford or Other"
        :options="$root.options.vanType.options"
        :selectedIndex="$root.selected.vanType.itemIndex"
        @onChange="
          (item, itemIndex) => {
            $root.setSelected(
              'vanType',
              '',
              item,
              itemIndex,
              `Van Type ${item.title}`
            );
          }
        "
        disableTitleTag
      />
      <div class="px-4 font-inter text-dim-gray" style="margin-top: -4rem">
        <i>{{
          $root.options.vanType.options[$root.selected.vanType.itemIndex]
            .message
        }}</i>
      </div>
    </SidebarSection>

    <SidebarSection
      ref="section2"
      title="Select a Wheelbase"
      description="Pick if your van is a short or long wheelbase."
    >
      <SectionPicker
        title="Choose SWB or LWB"
        :options="$root.options.wheelbase.options"
        :selectedIndex="$root.selected.wheelbase.itemIndex"
        @onChange="
          (item, itemIndex) => {
            $root.setSelected(
              'wheelbase',
              '',
              item,
              itemIndex,
              `Wheelbase ${item.title}`
            );
          }
        "
        disableTitleTag
      />
    </SidebarSection>

    <SidebarSection
      ref="section2a"
      title="Select a Boot"
      description="Pick if your boot has Barn Doors or Tailgate Doors"
    >
      <SectionPicker
        title="Choose Boot Type"
        :options="$root.options.boot.options"
        :selectedIndex="$root.selected.boot.itemIndex"
        @onChange="
          (item, itemIndex) => {
            $root.setSelected(
              'boot',
              '',
              item,
              itemIndex,
              `boot ${item.title}`
            );
          }
        "
      />
    </SidebarSection>

    <SidebarSection
      ref="section3"
      title="Select a Conversion"
      description="Pick a conversion to fit into your van."
    >
      <SectionPicker
        title="Conversions"
        :options="
          $root.options.conversions.filter(
            (conversion) => !conversion.key.startsWith('eco')
          )
        "
        :selectedIndex="$root.selected.conversions.itemIndex"
        @onChange="
          (item, itemIndex) => {
            $root.setSelected(
              'conversions',
              '',
              item,
              itemIndex,
              `(Conversion) ${item.title}`
            );
          }
        "
        big-cards
      />
    </SidebarSection>

    <SidebarSection
      v-for="(section, index) in sectionDictionary"
      :key="index"
      :ref="section.ref"
      :title="section.title"
      :description="section.description"
    >
      <div v-for="(category, index) in section.categories" :key="index">
        <SectionPicker
          v-if="
            category.type === 'swatch' &&
            ($root.selected.vanType.itemIndex !== 2 ||
              category.name !== 'blindsCurtains') &&
            $root.selected.conversions.value.swatchTargets.hasOwnProperty(
              category.name
            )
          "
          :title="$root.options.swatches[category.name].title"
          :options="$root.options.swatches[category.name].options"
          :selectedIndex="
            $root.selected['swatches' + category.name]
              ? $root.selected['swatches' + category.name].itemIndex
              : -1
          "
          @onChange="
            (item, itemIndex) => {
              $root.setSelected(
                'swatches',
                category.name,
                item,
                itemIndex,
                `(Swatch) ${item.title}`
              );
            }
          "
          has-pagination
        />
        <div v-else-if="category.type === 'extra'">
          <SectionExtra
            v-if="
              (category.selectType === undefined ||
                category.selectType === 'multiple') &&
              !(
                category.name === 'additionalKitchenStorage' &&
                ![
                  'ecoVoyagerSWB',
                  'ecoExpeditionSWB',
                  'ecoDrifterSWB',
                  'standardVoyagerSWB',
                  'standardExpeditionSWB',
                  'standardDrifterSWB',
                ].includes($root.selected.conversions.value.key)
              )
            "
            :title="$root.options.extras[category.name].title"
            :category-name="category.name"
            :options="$root.options.extras[category.name].options"
            :selectedIndexes="
              Object.keys($root.selected).reduce((carry, selectedKey) => {
                const reg = new RegExp('^extras' + category.name);
                const extractedIndex = selectedKey.replace(reg, '');
                if (
                  $root.options.extras[category.name].options[extractedIndex]
                ) {
                  carry.push($root.selected[selectedKey].itemIndex);
                }
                return carry;
              }, [])
            "
            @onChange="
              (item, itemIndex) => {
                $root.setToggle(
                  'extras',
                  category.name + itemIndex,
                  item,
                  itemIndex,
                  `(Extra, ${$root.options.extras[category.name].title}) ${
                    item.title
                  }`
                );
              }
            "
            has-pagination
            hide-group-titles
          />

          <SectionExtra
            v-else-if="category.selectType === 'hasRules'"
            :title="$root.options.extras[category.name].title"
            :options="$root.options.extras[category.name].options"
            :category-name="category.name"
            :selectedIndexes="
              Object.keys($root.selected).reduce((carry, selectedKey) => {
                const reg = new RegExp('^extras' + category.name);
                const extractedIndex = selectedKey.replace(reg, '');
                if (
                  $root.options.extras[category.name].options[extractedIndex]
                ) {
                  carry.push($root.selected[selectedKey].itemIndex);
                }
                return carry;
              }, [])
            "
            @onChange="
              (item, itemIndex) => {
                $root.setToggle(
                  'extras',
                  category.name + itemIndex,
                  item,
                  itemIndex,
                  `(Extra, ${$root.options.extras[category.name].title}) ${
                    item.title
                  }`,
                  category.rules
                );
              }
            "
            has-pagination
            hide-group-titles
          />

          <SectionExtra
            v-else-if="category.selectType === 'single'"
            :title="$root.options.extras[category.name].title"
            :options="$root.options.extras[category.name].options"
            :category-name="category.name"


            :selectedIndex="
              Object.keys($root.selected).reduce((carry, selectedKey) => {
                const reg = new RegExp('^extras' + category.name);
                const extractedIndex = selectedKey.replace(reg, '');
                if (
                  $root.options.extras[category.name].options[extractedIndex]
                ) {
                  carry = $root.selected[selectedKey].itemIndex
                }
                return carry;
              }, null)
            "
            @onChange="
              (item, itemIndex) => {

                // Remove any existing in this category
                Object.keys($root.selected).forEach((selectedKey) => {
                  const reg = new RegExp('^extras' + category.name);
                  const extractedIndex = selectedKey.replace(reg, '');
                  const foundItem = $root.options.extras[category.name].options[extractedIndex]
                  
                  
                  if (foundItem && extractedIndex != itemIndex) {
                    log('🚀', {extractedIndex, foundItem, item, itemIndex, cat_name: category.name})
                    $root.setToggle(
                      'extras',
                      category.name + extractedIndex,
                      foundItem,
                      extractedIndex,
                      `(Extra, ${$root.options.extras[category.name].title}) ${
                        foundItem.title
                      }`
                    )
                  }
                }, null)

                $nextTick(() => {
                  $root.setToggle(
                    'extras',
                    category.name + itemIndex,
                    item,
                    itemIndex,
                    `(Extra, ${$root.options.extras[category.name].title}) ${
                      item.title
                    }`
                  );
                })
              }
            "
            has-pagination
            hide-group-titles
          />
        </div>
        <div v-else-if="category.type === 'windows'">

        </div>
        <SectionPicker
          v-else-if="category.type === 'configured'"
          :title="$root.options[category.optionIndex].title"
          :options="$root.options[category.optionIndex].options"
          :selectedIndex="$root.selected[category.optionIndex].itemIndex"
          @onChange="
            (item, itemIndex) => {
              $root.setSelected(
                category.optionIndex,
                '',
                item,
                itemIndex,
                `${item.title}`
              );
            }
          "
          :disableTitleTag="(typeof category.showTitles === 'undefined' || category.showTitles === false)"
        />
        <div
          v-if="category.hasMessages !== undefined"
          class="px-4 font-inter text-dim-gray mb-16"
          style="margin-top: -4rem"
        >
          <i>{{
            category.optionIndex ? 
              $root.options[category.optionIndex].options[
                $root.selected[category.optionIndex].itemIndex
              ].message : 
              ($root.selected[`extras${category.extraIndex}`] ? $root.options.extras[category.extraIndex].options.extras[
                $root.selected[`extras${category.extraIndex}`].itemIndex
              ].message : '')
          }}</i>
        </div>
      </div>
    </SidebarSection>

    <SidebarSection
      ref="section15"
      title="Contact Information"
      description="We'll get in touch to talk through your van together and share a quote."
    >
      <ContactInformationForm />
    </SidebarSection>
  </div>
</template>

<script>
import SectionPicker from "./SectionPicker.vue";
import SidebarSection from "./SidebarSection.vue";
import SectionExtra from "./SectionExtra.vue";
import ContactInformationForm from "./ContactInformationForm.vue";
import {
  filterByEnabledSwatchesOnConversion,
  sectionDictionary,
} from "./generateOptions";

export default {
  name: "Sidebar",
  data() {
    return {
      currentSectionNumber: 0,
      currentSectionTitle: "Select Conversion.",
      isMounted: false,
      observer: null,
      ignoreRefs: ["container", "sidebar"],
      sectionDictionary: sectionDictionary,
    };
  },
  computed: {
    sectionRefs() {
      if (this.isMounted === false) return [];
      return Object.keys(this.$refs).filter(
        (key) => !this.ignoreRefs.includes(key)
      );
    },
    sectionCount() {
      if (this.isMounted === false) return 0;
      return this.sectionRefs.length;
    },
  },
  methods: {
    log(){
      console.log(...arguments)
    },
    scrollHandler() {
      const scrollTop =
        window.scrollY ||
        window.scrollTop ||
        document.getElementsByTagName("html")[0].scrollTop;

      const headerPosition =
        this.$refs.sidebar.getBoundingClientRect().top +
        scrollTop +
        this.$refs.sidebar.getBoundingClientRect().height;

      for (let ref in this.$refs) {
        if (this.ignoreRefs.includes(ref)) continue;

        // check if the ref is an array
        if (Array.isArray(this.$refs[ref])) {
          this.$refs[ref].forEach((componentInstance) => {
            this.calculatePosition(
              componentInstance,
              headerPosition,
              scrollTop
            );
          });
        } else if (this.$refs[ref].$el !== undefined) {
          this.calculatePosition(this.$refs[ref], headerPosition, scrollTop);
        }
      }
    },
    calculatePosition(componentInstance, headerPosition, scrollTop) {
      const element = componentInstance.$el;
      const elementPosition = element.getBoundingClientRect().top + scrollTop;

      if (headerPosition >= elementPosition) {
        this.currentSectionNumber = this.sectionRefs.indexOf(
          componentInstance.$vnode.data.ref
        );
        this.currentSectionTitle = componentInstance.title;
      }
    },

    filterByEnabledSwatchesOnConversion,
  },
  mounted() {
    this.isMounted = true;

    this.$nextTick(() => {
      window.addEventListener("scroll", this.scrollHandler, {
        passive: true,
      });

      this.observer = new MutationObserver(this.scrollHandler);
      this.observer.observe(document.body, { childList: true, subtree: true });

      this.scrollHandler();

      console.log(this.$root.options.swatches);
      console.log(this.$root.options.extras);
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandler);

    if (this.observer !== null) {
      this.observer.disconnect();
    }
  },
  components: {
    SidebarSection,
    SectionPicker,
    SectionExtra,
    ContactInformationForm,
  },
};
</script>
