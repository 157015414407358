var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "py-2", staticStyle: { "min-height": "400px" } },
    [
      _c("div", { staticClass: "px-4" }, [
        _c("h3", { staticClass: "font-raleway-semibold" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "px-4" }, [
        _c("p", { staticClass: "mb-2 font-inter font-light text-dim-gray" }, [
          _vm._v(_vm._s(_vm.description)),
        ]),
      ]),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }