<template>
    <div class="container mx-auto contain-paint pt-1 lg:max-w-full lg:mx-0">
        <div class="relative flex flex-wrap lg:flex-no-wrap px-4 lg:px-0">
            <div :class='{
                "w-full lg:w-2/3 z-40 lg:z-10 transform transition-all duration-200 sticky top-0 lg:relative": 1,
                "translate-x-full lg:translate-x-0 ml-4 lg:mx-0": isMobileOpen === false,
            }' :style='{
                marginBottom
            }'>
                <div :class="{
                    'lg:hidden absolute top-1/2 left-0 transform -translate-y-1/2 z-10 mt-16 pt-8': 1,
                    '-translate-x-full': isMobileOpen === false,
                    'translate-x-0': isMobileOpen === true,
                }">
                    <button
                        :class='{
                            "w-10 h-16 flex bg-dutch-white items-center justify-center pl-1": 1,
                            "rounded-l-lg": isMobileOpen === false,
                            "rounded-r-lg": isMobileOpen === true,
                        }'
                        @click="isMobileOpen = !isMobileOpen"
                        :title="isMobileOpen ? 'Close':'Open'"
                    >                    
                        <svg v-if="isMobileOpen === false" class="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" fill="currentColor" /></svg>
                        <svg v-if="isMobileOpen === true" class="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" fill="currentColor" /></svg>
                    </button>
                </div>
                <div :class='{
                    "sticky left-0 mt-32 pt-16 lg:mt-0 lg:pt-0": 1,
                    "opacity-0 pointer-events-none lg:opacity-100 lg:pointer-events-auto": isMobileOpen === false,
                }' :style='{top: marginBottom == 0 ? "8rem" : 0}'>
                    <Visualiser ref="visualiser" @onResize="onResizeVisualiserUpdateMarginBottom" />
                </div>
            </div>
            <div class="w-full lg:w-1/3 lg:z-20 lg:border border-ash-gray rounded" style="box-shadow: -1rem 0 1rem -1rem rgb(0 0 0 / 10%)">
                <Sidebar/>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from './Sidebar.vue';
import Visualiser from './Visualiser.vue';

    export default {
    name: "Conversion3DBuilder",
    components: { Visualiser, Sidebar },
    data() {
        return {
            isMobileOpen: false,
            marginBottom: 0,
        }
    },
    watch: {
        isMobileOpen() {
            setTimeout(() => {
                this.$refs.visualiser.resizeHandler()
            }, 1000)
        }
    },
    methods: {
        onResizeVisualiserUpdateMarginBottom({width, height}) {
            if (window.innerWidth < 1024) {
                this.marginBottom = `-${height}px`
                return
            }
            this.marginBottom = 0
        },
    },
}
</script>
