export const transparentObjects = [
  "fender_b1.001",
  "window_b.001",
  "window_f.001",
  "window_frame_b.001",
  "window_frame_f.001",
  "window_frame_s.001",
  "window_s.001",
  "window_s2.001",
  "window_s3.001",
  "window_seals.001",
  "door_s5.001",
  "door_s4.001",
  "door_detail1.001",
  "side_mirror_3.001",
  "LicPlate02.001",
  "LicPlate02001_1",
  "LicPlate02001_2",
  "LicPlate02001_3",
  "logo_b.001",
  "logo_base_b.001",
  "logo_f.001",
  "rear_light_base.001",
  "rear_light_glass_clear.001",
  "rear_light_glass_red.001",
  "rear_light_glass_relief.001",
  "rear_light_lamp.001",
  "roof_down.001",
  "Roof_up_01.001",
  "Roof_up_01.002",
  "Roof_up_01.003",
  "Roof_up_02.001",
  "TopSideRoofRims",
  "chair.001",
  "chair.002",
  "chair1.001",
  "chair1.002",
  "zipline.001",
  "Roof_up_black",
  "LicPlate01.001",
].map((str) => str.replace(/\./, "")); // remove dots, as these are not recognised by Three JS. Keeping dots for developer sanity when working with the model.
