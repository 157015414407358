export const hiddenObjects = [
    "swivel_chair",
    "window_s3.002",
    "standard_blocked_off_window_1",
    "big_blinds_2",
    "standard_blocked_off_window_1_internal",
    "Solar_Panel_v5",
    "KRS_Roof_Rack_v4",
    "zipline",
    "Cranked_Arm_Table_v3.001",
    "View_Dash_Cam_v1",
    "Thule_Wanderway_Bike_Rack_v9",
    "Vamoose_Awning_Rail_v2.001",
].map((str) => str.replace(/\./, "")); // remove dots, as these are not recognised by Three JS. Keeping dots for developer sanity when working with the model.