import fslightbox from 'fslightbox';

export default {

    create(selector = ".gallery-item") {
        return new Promise((resolve, reject) => {
            try {
                document.querySelectorAll(selector).forEach(element => {
                    const aElement = element.querySelector("a");
                    const imgElement = element.querySelector("img");

                    const src = imgElement.getAttribute("data-src");
                    aElement.href = src;

                    aElement.setAttribute("data-fslightbox", element.getAttribute("data-gallery-identifier"));
                });

                window.refreshFsLightbox();
            }
            catch (error){
                console.error(error);
                reject(error);
                return;
            }
            resolve();
        });
    },

    destroy(){
        return new Promise((resolve, reject) => {

            try {
                window.refreshFsLightbox();
            }
            catch (error){
                console.error(error);
                reject(error);
                return;
            }

            resolve();
        });
    },

    /**
     * swup()
     * Detect swup events
     * @param  {Object} swup
     * @return {Promise}
     */
    swup(swup){
        return new Promise((resolve, reject) => {
            try {
                swup.on("willReplaceContent", event => {
                    this.destroy();
                });
                swup.on("contentReplaced", event => {
                    this.create();
                });
                resolve();
            } catch (error){
                console.error(error);
                reject(error);
            }
        });
    },
}
