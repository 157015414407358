<template>
  <div class="py-2" style="min-height: 400px">
    <div class="px-4">
      <h3 class="font-raleway-semibold">{{ title }}</h3>
    </div>
    <div class="px-4">
      <p class="mb-2 font-inter font-light text-dim-gray">{{ description }}</p>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SidebarHeader",
  props: {
    title: String,
    description: String,
  },
};
</script>
