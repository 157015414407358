var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container mx-auto contain-paint pt-1 lg:max-w-full lg:mx-0",
    },
    [
      _c(
        "div",
        { staticClass: "relative flex flex-wrap lg:flex-no-wrap px-4 lg:px-0" },
        [
          _c(
            "div",
            {
              class: {
                "w-full lg:w-2/3 z-40 lg:z-10 transform transition-all duration-200 sticky top-0 lg:relative": 1,
                "translate-x-full lg:translate-x-0 ml-4 lg:mx-0":
                  _vm.isMobileOpen === false,
              },
              style: {
                marginBottom: _vm.marginBottom,
              },
            },
            [
              _c(
                "div",
                {
                  class: {
                    "lg:hidden absolute top-1/2 left-0 transform -translate-y-1/2 z-10 mt-16 pt-8": 1,
                    "-translate-x-full": _vm.isMobileOpen === false,
                    "translate-x-0": _vm.isMobileOpen === true,
                  },
                },
                [
                  _c(
                    "button",
                    {
                      class: {
                        "w-10 h-16 flex bg-dutch-white items-center justify-center pl-1": 1,
                        "rounded-l-lg": _vm.isMobileOpen === false,
                        "rounded-r-lg": _vm.isMobileOpen === true,
                      },
                      attrs: { title: _vm.isMobileOpen ? "Close" : "Open" },
                      on: {
                        click: function ($event) {
                          _vm.isMobileOpen = !_vm.isMobileOpen
                        },
                      },
                    },
                    [
                      _vm.isMobileOpen === false
                        ? _c(
                            "svg",
                            {
                              staticClass: "w-4",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 320 512",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z",
                                  fill: "currentColor",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isMobileOpen === true
                        ? _c(
                            "svg",
                            {
                              staticClass: "w-4",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 320 512",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z",
                                  fill: "currentColor",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "sticky left-0 mt-32 pt-16 lg:mt-0 lg:pt-0": 1,
                    "opacity-0 pointer-events-none lg:opacity-100 lg:pointer-events-auto":
                      _vm.isMobileOpen === false,
                  },
                  style: { top: _vm.marginBottom == 0 ? "8rem" : 0 },
                },
                [
                  _c("Visualiser", {
                    ref: "visualiser",
                    on: { onResize: _vm.onResizeVisualiserUpdateMarginBottom },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "w-full lg:w-1/3 lg:z-20 lg:border border-ash-gray rounded",
              staticStyle: {
                "box-shadow": "-1rem 0 1rem -1rem rgb(0 0 0 / 10%)",
              },
            },
            [_c("Sidebar")],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }