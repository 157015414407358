export const defaultSelectedSwatches = {
  swatcheskitchenUnits: {
    id: "swatcheskitchenUnits",
    type: "swatches",
    item: "kitchenUnits",
    title: "(Swatch) 3101 White Gloss",
    itemIndex: 20,
    value: {
      imageUrl:
        "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/899/conversions/60eedc6071aaef6d62a6dc0c_3101-White-Gloss-webp.webp?v=1656517454",
      textureUrl:
        "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/899/conversions/60eedc6071aaef6d62a6dc0c_3101-White-Gloss-webp.webp?v=1656517454",
      price: "££",
      title: "3101 White Gloss",
      category: "colours",
    },
  },
  swatchesworktopFinishes: {
    id: "swatchesworktopFinishes",
    type: "swatches",
    item: "worktopFinishes",
    title: "(Swatch) Bardolino Oak - Worktop",
    itemIndex: 11,
    value: {
      imageUrl:
        "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/2338/conversions/Bardolino-Oak-webp-2-webp.webp?v=1673874378",
      textureUrl:
        "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/2338/conversions/Bardolino-Oak-webp-2-webp.webp?v=1673874378",
      price: null,
      title: "Bardolino Oak - Worktop",
      category: "wood",
    },
  },
  swatchesfridgeColour: {
    id: "swatchesfridgeColour",
    type: "swatches",
    item: "fridgeColour",
    title: "(Swatch) Black",
    itemIndex: 0,
    value: {
      imageUrl:
        "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/2558/conversions/Black-webp.webp?v=1674224800",
      textureUrl:
        "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/2558/conversions/Black-webp.webp?v=1674224800",
      price: null,
      title: "Black",
      category: null,
    },
  },
  swatchesupholstery: {
    id: "swatchesupholstery",
    type: "swatches",
    item: "upholstery",
    title: "VW Standard",
    itemIndex: 1,
    value: {
      imageUrl:
        "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/3867/conversions/Davy's-Grey-webp-webp.webp?v=1686830177",
      textureUrl: null,
      price: null,
      title: "VW Standard",
      category: null,
    },
  },
  swatchesvanColours: {
    id: "swatchesvanColours",
    type: "swatches",
    item: "vanColours",
    title: "(Swatch) Light Ivory",
    itemIndex: 8,
    value: {
      imageUrl:
        "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/2298/conversions/Light-Ivory-webp.webp?v=1673004738",
      textureUrl:
        "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/2298/conversions/Light-Ivory-webp.webp?v=1673004738",
      price: null,
      title: "Light Ivory",
      category: null,
    },
  },
  swatchesflooringRange: {
    id: "swatchesflooringRange",
    type: "swatches",
    item: "flooringRange",
    title: "(Swatch) Autumn Maple WSA2005",
    itemIndex: 14,
    value: {
      imageUrl:
        "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/1212/conversions/60ed79e791f4d01f77addae2_WSA2005_Autumn-Maple-webp.webp?v=1656595062",
      textureUrl:
        "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/1212/conversions/60ed79e791f4d01f77addae2_WSA2005_Autumn-Maple-webp.webp?v=1656595062",
      price: "££",
      name: "Autumn Maple WSA2005",
      category: null,
    },
  },
  swatchespopTopCanvas: {
    id: "swatchespopTopCanvas",
    type: "swatches",
    item: "popTopCanvas",
    title: "(Swatch) Acmg15 Metal Grey - Westdubs",
    itemIndex: 17,
    value: {
      imageUrl:
        "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/2106/conversions/P-webp.webp?v=1669984932",
      textureUrl:
        "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/2106/conversions/P-webp.webp?v=1669984932",
      price: "££",
      title: "Acmg15 Metal Grey - Westdubs",
      category: null,
    },
  },
  swatchesblindsCurtains: {
    id: "swatchesblindsCurtains",
    type: "swatches",
    item: "blindsCurtains",
    title: "(Swatch) Cappuccino  - VanShades",
    itemIndex: 0,
    value: {
      imageUrl:
        "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/2396/conversions/01-webp.webp?v=1674040659",
      textureUrl:
        "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/2396/conversions/01-webp.webp?v=1674040659",
      price: "£££",
      title: "Cappuccino  - VanShades",
      category: null,
    },
  },
  swatchessplashbacks: {
    id: "swatchessplashbacks",
    type: "swatches",
    item: "splashbacks",
    title: "(Swatch) 4625 America Chestnut Velur",
    itemIndex: 19,
    value: {
      imageUrl:
        "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/2283/conversions/4625-America-Chestnut-Velur-webp-webp.webp?v=1672928095",
      textureUrl:
        "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/2283/conversions/4625-America-Chestnut-Velur-webp-webp.webp?v=1672928095",
      price: "££",
      title: "4625 America Chestnut Velur",
      category: "wood",
    },
  },
  swatchesvanWallLining4WayStretchCarpet: {
    id: "swatchesvanWallLining4WayStretchCarpet",
    type: "swatches",
    item: "vanWallLining4WayStretchCarpet",
    title: "(Swatch) Stone",
    itemIndex: 0,
    value: {
      textureUrl: "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/2120/conversions/Veltrim-Stone-webp.webp?v=1670322838",
      imageUrl: "https://cdn-aws.coconut.farm/media/tayloredcampervanconversions_co_uk/media/2120/conversions/Veltrim-Stone-webp.webp?v=1670322838",
      price: "££",
      title: "Stone",
      category: "Colours"
    }
  }
};
