export const swatchSizes = {
    vanColours: 1,
    kitchenUnits: 0.075,
    vanWallLining4WayStretchCarpet: 3,
    flooringRange: 2,
    popTopCanvas: 7,
    seatingRIBAltairBedFabric: 10,
    splashbacks: 0.27,
    blindsCurtains: 6,
    upholstery: 0.05,
    upholsteryOuter: 0.5,
    upholsteryInner: 0.5,
    worktopFinishes: 2,
    fridgeColour: 1,
}