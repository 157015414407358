var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "px-4 pb-4 mt-8" }, [
    _c("div", { staticClass: "mb-5" }, [
      _c(
        "label",
        {
          staticClass: "sr-only",
          attrs: { for: "contact-information-form-name" },
        },
        [_vm._v("Your full name")]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.$root.name,
            expression: "$root.name",
          },
        ],
        staticClass:
          "w-full p-2 font-inter text-dim-gray rounded-lg border-b border-silver-chalice",
        attrs: {
          placeholder: "Your full name",
          type: "text",
          id: "contact-information-form-name",
        },
        domProps: { value: _vm.$root.name },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.$root, "name", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mb-5" }, [
      _c(
        "label",
        {
          staticClass: "sr-only",
          attrs: { for: "contact-information-form-phone" },
        },
        [_vm._v("Your Phone Number")]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.$root.phone,
            expression: "$root.phone",
          },
        ],
        staticClass:
          "w-full p-2 font-inter text-dim-gray rounded-lg border-b border-silver-chalice",
        attrs: {
          placeholder: "Your Phone Number",
          type: "phone",
          id: "contact-information-form-phone",
        },
        domProps: { value: _vm.$root.phone },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.$root, "phone", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mb-5" }, [
      _c(
        "label",
        {
          staticClass: "sr-only",
          attrs: { for: "contact-information-form-email" },
        },
        [_vm._v("Your Email Address")]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.$root.email,
            expression: "$root.email",
          },
        ],
        staticClass:
          "w-full p-2 font-inter text-dim-gray rounded-lg border-b border-silver-chalice",
        attrs: {
          placeholder: "Your Email Address",
          type: "email",
          id: "contact-information-form-email",
        },
        domProps: { value: _vm.$root.email },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.$root, "email", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mb-5" }, [
      _c(
        "label",
        {
          staticClass: "sr-only",
          attrs: { for: "contact-information-form-additional-info" },
        },
        [_vm._v("Any additional information?")]
      ),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.$root.enquiry,
            expression: "$root.enquiry",
          },
        ],
        staticClass:
          "w-full p-2 font-inter text-dim-gray rounded-lg border-b border-silver-chalice",
        attrs: {
          placeholder: "Any additional information?",
          rows: "4",
          id: "contact-information-form-additional-info",
        },
        domProps: { value: _vm.$root.enquiry },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.$root, "enquiry", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm.$root.errors && _vm.$root.errors.length > 0
      ? _c(
          "div",
          { staticClass: "mb-5" },
          _vm._l(_vm.$root.errors, function (error, index) {
            return _c(
              "p",
              { key: error + index, staticClass: "pb-1 text-sm text-flame" },
              [_vm._v("\n      " + _vm._s(error) + "\n    ")]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", [
      _c(
        "button",
        {
          staticClass:
            "bg-flame text-center font-inter text-white rounded-full py-1 px-10 text-xl",
          on: { click: _vm.$root.submit },
        },
        [_vm._v("\n      Submit\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-5" }, [
      _c("p", { staticClass: "leading-4 text-dim-gray" }, [
        _c("small", [
          _vm._v(
            "\n        Disclaimer: the colours of your chosen swatches may vary slightly in\n        comparison to what they look like in real life depending on the screen\n        they're being viewed on.\n      "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }