<template>
  <div>
    <div ref="visualiserContainer" class="relative">
      <div
        v-if="loadingProgress > 0 && loadingProgress < 100"
        class="absolute top-1/2 left-1/2 flex flex-col justify-center items-center transform -translate-y-1/2 -translate-x-1/2 p-4 z-50"
      >
        <div class="loading-bars"></div>
        <small class="opacity-50 pt-4">{{ loadingProgressMessage }}</small>
      </div>

      <div ref="visualiser"></div>

      <!-- <div
        class="flex items-center absolute top-0 right-0 text-white bg-flame font-light px-5 py-3 pointer-events-none z-10"
      >
        <small> Quote (approx): £0.00 </small>
      </div> -->
      <div
        class="hidden md:flex items-center absolute bottom-0 right-0 opacity-50 text-eerie-black font-light m-5 pointer-events-none z-10"
      >
        <small>
          Left Click + Drag to <b>Spin</b> &nbsp;|&nbsp; Right Click + Drag to
          <b>Pan</b>
        </small>
        <svg
          class="inline-block h-5 ml-2"
          className="svg-icon-mouse"
          xmlns="http://www.w3.org/2000/svg"
          id="svg8"
          version="1.1"
          viewBox="0 0 384 512"
        >
          <path
            fill="var(--erie-black)"
            className="mouseLeftPad"
            d="M176 0h-16C71.63 0 0 71.63 0 160v32h176z"
          />
          <path
            fill="var(--erie-black)"
            className="mouseBody"
            d="M0 352c0 88.38 71.63 160 160 160h64c88.38 0 160-71.63 160-160V224H0Z"
          />
          <path
            fill="var(--erie-black)"
            className="mouseRightPad"
            d="M224 0h-16v192h176v-32C384 71.63 312.4 0 224 0Z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { conversionsAndSwatchTargets } from "./conversionsAndSwatchTargets";
import { ThreeVisualiser } from "./ThreeVisualiser";
import { swatchSizes } from "./swatchSizes";

export default {
  name: "Visualiser",

  emits: ["onResize"],

  data() {
    return {
      conversion3DVisualiser: null,
      loadingProgress: 0,
      loadingProgressMessage: "",
    };
  },

  computed: {
    modelUrl() {
      return conversionsAndSwatchTargets[
        this.$root.selected.conversions.value.key
      ].modelUrl;
    },
    modelSize() {
      return conversionsAndSwatchTargets[
        this.$root.selected.conversions.value.key
      ].modelSize;
    },
    swatchTargets() {
      return conversionsAndSwatchTargets[
        this.$root.selected.conversions.value.key
      ].swatchTargets;
    },
    extraMeshes() {
      return (
        conversionsAndSwatchTargets[this.$root.selected.conversions.value.key]
          .extraMeshes || {}
      );
    },
    conversionRootNodeName() {
      return conversionsAndSwatchTargets[
        this.$root.selected.conversions.value.key
      ].conversionRootNodeName;
    },
    defaultMeshVisibility() {
      return (
        conversionsAndSwatchTargets[this.$root.selected.conversions.value.key]
          .defaultMeshVisibility || null
      );
    },
  },

  watch: {
    "$root.selected": {
      deep: true,
      handler: function (newValue, oldValue) {
        console.log("$root.selected", JSON.parse(JSON.stringify(newValue)));

        const changedItemKeys = [];

        for (const key in newValue) {
          if (newValue.hasOwnProperty(key)) {
            if (!oldValue.hasOwnProperty(key) || newValue[key] !== oldValue[key]) {
              changedItemKeys.push(key);
            }
          }
        }

        for (const key in oldValue) {
          if (oldValue.hasOwnProperty(key) && !newValue.hasOwnProperty(key)) {
            changedItemKeys.push(key);
          }
        }

        this.updateExtraMeshes();

        if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return;

        const onlyConversionsAndSwatchesAndExtrasWithMeshes = (object) => {
          const allowedExtraKeys = Object.keys(this.extraMeshes);

          const allowedKeys = Object.keys(object).filter((value) => {
            if (allowedExtraKeys.includes(value)) return true;

            return !!value.match(/^(conversion|swatch)/gi);
          });

          return allowedKeys.reduce((carry, key) => {
            return {
              ...carry,
              [key]: object[key],
            };
          }, {});
        };

        // If not a conversion or swatch change, escape
        if (
          JSON.stringify(
            onlyConversionsAndSwatchesAndExtrasWithMeshes(newValue)
          ) ===
          JSON.stringify(
            onlyConversionsAndSwatchesAndExtrasWithMeshes(oldValue)
          )
        ) {
          return;
        }

        if (
          this.conversion3DVisualiser === null ||
          oldValue.conversions.value.key !== newValue.conversions.value.key
        ) {
          this.$nextTick(() => {
            this.loadThreeVisualiser();
          });

          return;
        }

        this.$nextTick(() => {
          this.updateExtraMeshes();
          this.updateSwatches(changedItemKeys);
        });
      },
    },
  },

  methods: {
    getCurrentDimensions() {
      const rect = this.$refs.visualiserContainer.getBoundingClientRect();

      return {
        width: rect.width,
        height:
          window.innerWidth < 1024
            ? Math.min(1200, window.innerHeight * 0.9) - 128
            : window.innerHeight - 128,
      };
    },
    resizeHandler() {
      if (this.conversion3DVisualiser === null) return;

      const { width, height } = this.getCurrentDimensions();

      this.conversion3DVisualiser.resize(width, height);

      this.$emit("onResize", { width, height });
    },
    loadThreeVisualiser(readyCallback = () => {}) {
      if (this.conversion3DVisualiser) {
        this.conversion3DVisualiser.destroy();
      }

      const { width, height } = this.getCurrentDimensions();

      console.log(`loading ${this.modelUrl}`);

      this.loadingProgressMessage = ""

      this.conversion3DVisualiser = new ThreeVisualiser(
        this.$refs.visualiser,
        width,
        height,
        this.modelUrl,
        this.modelSize,
        this.conversionRootNodeName,
        this.swatchTargets,
        (instance, loadingProgress, xhr) => {
          this.loadingProgress = loadingProgress;

          if (loadingProgress && loadingProgress < 100) {
            this.loadingProgressMessage =
              (Math.floor(loadingProgress * 10) / 10).toLocaleString(undefined, {
                minimumFractionDigits: 1,
              }) +
              "% (" +
              (xhr.loaded / (1024 * 1024)).toLocaleString(undefined, {
                minimumFractionDigits: 1,
              }) +
              "/" +
              (xhr.total / (1024 * 1024)).toLocaleString(undefined, {
                minimumFractionDigits: 1,
              }) +
              " MB)";
          }
        },
        () => {
          readyCallback();
          this.updateMeshesOnLoad();
          this.updateExtraMeshes();
          this.updateSwatches();
          this.resizeHandler();
        },
        true,
        false,
        true
      );
    },
    updateMeshesOnLoad() {

      console.log("updateMeshesOnLoad", this.defaultMeshVisibility)

      if (this.defaultMeshVisibility === null) return;

      this.conversion3DVisualiser.showAndHideMeshes({
        show: this.defaultMeshVisibility.showMeshes,
        hide: this.defaultMeshVisibility.hideMeshes,
      });
    },
    updateExtraMeshes() {

      if (this.conversion3DVisualiser === null) return;

      console.log("hello update exta meshes", this.extraMeshes)

      this.$nextTick(() => {
        const extraMeshesKeys = Object.keys(this.extraMeshes);

        console.log({ extraMeshesKeys, selected: this.$root.selected });

        // Order extraMeshesKeys so selected extras are processed last
        extraMeshesKeys.sort((a, b) => {
          const keySelected = (key) => (this.$root.selected[key] && (typeof this.$root.selected[key].value.value !== "boolean" || this.$root.selected[key].value.value !== false))

          const aSelected = keySelected(a)
          const bSelected = keySelected(b)

          if (aSelected && !bSelected) return 1

          if (!aSelected && bSelected) return -1

          return 0
        });

        for (const key of extraMeshesKeys) {

          // check there's a valid mapping available
          if (typeof this.extraMeshes[key] === "undefined") {
            continue;
          }
          
          // If extra is selected and it's `value.value` is not a false boolean (if exists)
          if (this.$root.selected[key] && (typeof this.$root.selected[key].value.value !== "boolean" || this.$root.selected[key].value.value !== false)) {
            // Get show + hide Mesh entries
            let showMeshes = this.extraMeshes[key].byIndex && this.extraMeshes[key].byIndex[this.$root.selected[key].itemIndex] ? this.extraMeshes[key].byIndex[this.$root.selected[key].itemIndex].showMeshes : [];
            let hideMeshes = this.extraMeshes[key].byIndex && this.extraMeshes[key].byIndex[this.$root.selected[key].itemIndex] ? this.extraMeshes[key].byIndex[this.$root.selected[key].itemIndex].hideMeshes : [];

            // hide any meshes for other byIndex entries
            if (this.extraMeshes[key].byIndex) {
              for (const [index, entry] of Object.entries(this.extraMeshes[key].byIndex)) {
                if (index == this.$root.selected[key].itemIndex) {
                  continue;
                }

                if (this.extraMeshes[key].byIndex[index]) {
                  hideMeshes = hideMeshes.concat(this.extraMeshes[key].byIndex[index].showMeshes).filter(mesh => {
                    return !showMeshes.includes(mesh)
                  })
                }
              }
            }

            // See if any conditional meshes should be factored in
            let hasUsedConditionalMeshes = false

            console.log("rerender ", {
              k: this.extraMeshes[key],
            })

            if (typeof this.extraMeshes[key].conditional !== "undefined") {
              for (const conditionalKey of Object.keys(this.extraMeshes[key].conditional)) {
                if (typeof this.$root.selected[conditionalKey] !== "undefined") {
                  hasUsedConditionalMeshes = true
                  showMeshes = showMeshes.concat(this.extraMeshes[key].conditional[conditionalKey].showMeshes)
                  hideMeshes = hideMeshes.concat(this.extraMeshes[key].conditional[conditionalKey].hideMeshes)

                  // Make sure showMeshes doesn't include any hideMeshes entries from conditional meshes.
                  const hideMeshesSet = new Set(this.extraMeshes[key].conditional[conditionalKey].hideMeshes)
                  showMeshes = showMeshes.filter(mesh => !hideMeshesSet.has(mesh))
                }
                else {
                  const showMeshesSet = new Set(this.extraMeshes[key].conditional[conditionalKey].showMeshes)
                  hideMeshes = hideMeshes.concat(this.extraMeshes[key].conditional[conditionalKey].showMeshes.filter(mesh => !showMeshesSet.has(mesh)))
                }
              }
            }

            if (hasUsedConditionalMeshes === false) {
              // Add in top-level meshes

              const hideMeshesSet = new Set(hideMeshes)

              showMeshes = showMeshes.concat(this.extraMeshes[key].showMeshes.filter(mesh => !hideMeshesSet.has(mesh)))
              hideMeshes = hideMeshes.concat(this.extraMeshes[key].hideMeshes)
            }
            else {
              // Flip around

              const hideMeshesSet = new Set(hideMeshes)

              showMeshes = showMeshes.concat(this.extraMeshes[key].hideMeshes.filter(mesh => !hideMeshesSet.has(mesh)))
              hideMeshes = hideMeshes.concat(this.extraMeshes[key].showMeshes)
            }

            this.conversion3DVisualiser.showAndHideMeshes({
              show: showMeshes,
              hide: hideMeshes,
            });
            continue;
          }

          // otherwise flip back
          this.conversion3DVisualiser.showAndHideMeshes({
            show: this.extraMeshes[key].hideMeshes,
            hide: this.extraMeshes[key].showMeshes,
          });

          if (typeof this.extraMeshes[key].conditional !== "undefined") {
            for (const conditionalKey of Object.keys(this.extraMeshes[key].conditional)) {
              this.conversion3DVisualiser.showAndHideMeshes({
                hide: this.extraMeshes[key].conditional[conditionalKey].showMeshes,
                show: this.extraMeshes[key].conditional[conditionalKey].hideMeshes,
              })
            }
          }
        }
      })
    },
    updateSwatches(animatedItemKeys = []) {
      const selectedItems = Object.entries(this.$root.selected);

      const swatchTargetKeys = Object.keys(this.swatchTargets)

      for (const [key, item] of selectedItems) {

        // if not a swatch item, skip
        if (!swatchTargetKeys.includes(key.replace(/^swatches/, ""))) {
          continue;
        }

        const targets = this.swatchTargets[item.item];

        const size = swatchSizes[item.item] ? swatchSizes[item.item] : 1.25;

        this.conversion3DVisualiser.switchSwatch(
          targets,
          item.value.textureUrl,
          size,
          animatedItemKeys.includes(key)
        ).catch(error => {
          console.warn(
            "vis - failed on ",
            key,
            " with item ",
            item,
            " other: ",
            this.swatchTargets,
            key,
            " error ",
            error,
          );
        });
      }
    },
  },

  mounted() {
    this.loadThreeVisualiser();

    window.addEventListener("resize", this.resizeHandler, {
      passive: true,
    });
  },

  beforeUnmount() {
    if (this.conversion3DVisualiser) {
      this.conversion3DVisualiser.destroy();
      this.conversion3DVisualiser = null;
    }
    window.removeEventListener("resize", this.resizeHandler);
  },
};
</script>
