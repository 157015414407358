<template>
  <div class="pb-2">
    <div class="px-4 py-2 bg-cultured sticky z-10" style="top: 11rem">
      <h4 class="text-flame font-raleway-semibold">{{ title }}</h4>
    </div>
    <div :class="{'mb-16': isSmall !== true}">
      <div>
        <div v-if="optionsGroupedByCategory !== null">
          <div
            v-for="[groupName, group] in Object.entries(
              optionsGroupedByCategory
            )"
          >
            <div
              v-if="hideGroupTitles === false"
              class="py-2 px-4 text-dim-gray uppercase text-sm font-bold font-inter"
            >
              {{ groupName }}
            </div>
            <div
              class="builder-scrollbar p-4 border-t border-ash-gray mb-5"
              :style="{
                position: 'relative',
                maxWidth: '100%',
                display: 'grid',
                gridAutoFlow: 'column',
                gridAutoColumns: '125px',
                gridGap: '5px',
                columnCount: 1,
                overflow: 'auto',
                ...(group.length < 5
                  ? {
                      gridTemplateRows: '125px',
                    }
                  : {
                      gridTemplateRows: '125px 125px',
                      backgroundImage:
                        'linear-gradient(to right, var(--cultured), var(--cultured)),\n    linear-gradient(to right, var(--cultured), var(--cultured)),\n  \n \n    linear-gradient(to right, rgba(0,0,0,.25), rgba(255,255,255,0)),\n    linear-gradient(to left, rgba(0,0,0,.25), rgba(255,255,255,0))',
                      backgroundPosition:
                        'left center, right center, left center, right center',
                      backgroundRepeat: 'no-repeat',
                      backgroundColor: 'var(--cultured)',
                      backgroundSize:
                        '20px 100%, 20px 100%, 10px 100%, 10px 100%',
                      backgroundAttachment: 'local, local, scroll, scroll',
                    }),
              }"
            >
              <div v-for="{ option, index } in group" :key="index">
                <div class="relative h-full">
                  <button
                    :class="{
                      'absolute top-0 left-0 w-full h-full border-2 focus:outline-none': 1,
                      'border-ash-gray hover:border-silver-chalice':
                        !_selectedIndexes.includes(Number.parseInt(index)),
                      'border-flame focus:border-flame bg-ash-gray':
                        _selectedIndexes.includes(Number.parseInt(index)),
                    }"
                    @click="
                      ($event) => {
                        handleClick(option, index);
                      }
                    "
                    :title="option.title"
                  >
                    <img
                      v-if="
                        typeof option.imageUrl !== 'undefined' &&
                        option.imageUrl
                      "
                      class="w-full h-full object-cover lazyload"
                      :data-src="option.imageUrl"
                    />
                    <span
                      v-else-if="option.title"
                      class="inline-flex text-xs leading-2"
                      >{{ option.title }}</span
                    >
                    <span
                      v-if="_selectedIndexes.includes(Number.parseInt(index))"
                      class="absolute top-0 right-0 bg-flame text-white px-2 w-8"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path
                          d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </button>
                  <div
                    v-if="option.title && disableTitleTag === false"
                    class="-translate-x-1/2 absolute bg-eerie-black bottom-0 lazyloading left-1/2 max-w-xs mb-2 p-1 text-center text-xs text-white transform w-full pointer-events-none"
                    style="width: calc(100% - 1rem)"
                  >
                    {{ option.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="builder-scrollbar p-4 border-b border-ash-gray"
          :style="{
            position: 'relative',
            maxWidth: '100%',
            display: 'grid',
            gridAutoFlow: 'column',
            gridAutoColumns: '125px',
            gridGap: '5px',
            columnCount: 1,
            overflow: 'auto',
            ...(options.length < 5
              ? {
                  gridTemplateRows: '125px',
                }
              : {
                  gridTemplateRows: '125px 125px',
                  backgroundImage:
                    'linear-gradient(to right, var(--cultured), var(--cultured)),\n    linear-gradient(to right, var(--cultured), var(--cultured)),\n  \n \n    linear-gradient(to right, rgba(0,0,0,.25), rgba(255,255,255,0)),\n    linear-gradient(to left, rgba(0,0,0,.25), rgba(255,255,255,0))',
                  backgroundPosition:
                    'left center, right center, left center, right center',
                  backgroundRepeat: 'no-repeat',
                  backgroundColor: 'var(--cultured)',
                  backgroundSize: '20px 100%, 20px 100%, 10px 100%, 10px 100%',
                  backgroundAttachment: 'local, local, scroll, scroll',
                }),
          }"
        >
          <div v-for="(item, index) in options" :key="index">
            <div class="relative h-full">
              <button
                :class="{
                  'relative absolute top-0 left-0 w-full h-full border-2 focus:outline-none': 1,
                  'border-ash-gray hover:border-silver-chalice':
                    !_selectedIndexes.includes(Number.parseInt(index)),
                  'border-flame focus:border-flame bg-ash-gray':
                    _selectedIndexes.includes(Number.parseInt(index)),
                }"
                @click="
                  ($event) => {
                    handleClick(item, index);
                  }
                "
                :title="item.title"
              >
                <img
                  v-if="item.imageUrl"
                  class="w-full h-full object-cover"
                  :src="item.imageUrl"
                />
                <div
                  v-else-if="item.title"
                  v-html="item.svg ? item.svg : item.title"
                  class="w-full h-full flex items-center justify-center"
                ></div>
                <span
                  v-if="_selectedIndexes.includes(Number.parseInt(index))"
                  class="absolute top-0 right-0 bg-flame text-white px-2 w-8"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </button>
              <div
                v-if="item.title && disableTitleTag === false"
                class="-translate-x-1/2 absolute bg-eerie-black bottom-0 lazyloading left-1/2 max-w-xs mb-2 p-1 text-center text-xs text-white transform w-full pointer-events-none"
                style="width: calc(100% - 1rem)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionPicker",
  props: {
    title: String,
    options: {}, // [ { imageUrl: string }, ... ]
    selectedIndex: {
      type: Number,
      default: -1,
    },
    selectedIndexes: {
      type: Array,
      default: [],
    },
    hasPagination: {
      type: Boolean,
      default: false,
    },
    disableTitleTag: {
      type: Boolean,
      default: false,
    },
    bigCards: {
      type: Boolean,
      default: false,
    },
    hideGroupTitles: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onChange"],
  computed: {
    _selectedIndexes() {
      return [
        ...this.selectedIndexes,
        typeof this.selectedIndex !== "undefined" ? this.selectedIndex : -1,
      ];
    },
    optionsGroupedByCategory() {
      const groups = {};
      const noGroup = [];

      for (const [index, option] of Object.entries(this.options)) {
        if (option.category) {
          groups[option.category] = [
            ...(groups[option.category] || []),
            {
              index,
              option,
            },
          ];
          continue;
        }
        noGroup.push({
          index,
          option,
        });
      }

      if (Object.keys(groups).length > 0) {
        return {
          ...groups,
          ...(noGroup.length ? { other: noGroup } : {}),
        };
      }

      return null;
    },
  },
  methods: {
    handleClick(item, itemIndex) {
      console.log("handleClick", item, itemIndex);
      this.$emit("onChange", item, itemIndex);
    },
  },
};
</script>
