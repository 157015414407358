import Blueprint from "$blueprint/js/blueprint";
import Reveal from "$blueprint/js/reveal/index";
import Forms from "$blueprint/js/forms/index";
import LightGallery from "./gallery/index";
import ecoDrifter3DVisualiser from "./ecoDrifter3DVisualiser/index";
import ecoDrifter2_0_3DVisualiser from "./ecoDrifter2_0_3DVisualiser/index";
import conversion3DVisualiser from "./conversion3DVisualiser/index";
import conversion3DBuilder from "./conversion3DBuilder";
import Swiper from "$blueprint/js/swiper/index";

import Axios from "axios";

window.axios = Axios;

Blueprint().then(blueprint => {
    if (blueprint.isWebsite) {
        Reveal.create().then(Reveal.swup(blueprint.swup));
        Forms.create().then(Forms.swup(blueprint.swup));
        LightGallery.create().then(LightGallery.swup(blueprint.swup));
        ecoDrifter3DVisualiser.create().then(ecoDrifter3DVisualiser.swup(blueprint.swup))
        ecoDrifter2_0_3DVisualiser.create().then(ecoDrifter2_0_3DVisualiser.swup(blueprint.swup))
        conversion3DVisualiser.create().then(conversion3DVisualiser.swup(blueprint.swup))
        conversion3DBuilder.create().then(conversion3DBuilder.swup(blueprint.swup))

        const options = {
            root: null,
            rootMargin: "-160px 0px -80px 0px", // let the web visitor see animations within viewport

            /**
             * @type {array}
             */
            threshold: (() => { // setup trigger points
                let thresholds = [];
                const steps = 20;

                for (let index = 1.0; index < steps; index++){
                    thresholds.push(index / steps);
                }

                thresholds.push(0);

                return thresholds;
            })()
        };

        // show swiper on CMS and website. Only setup SWUP on website.
        Swiper.create().then(() => {
            if (blueprint.isWebsite){
                Swiper.swup(blueprint.swup);
            }
        });

        /**
         * Init our page observer. This looks for elements coming in and out of view.
         * @type {IntersectionObserver}
         */
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0.05){
                    entry.target.classList.add("--is-visible");
                }
                else if (!entry.target.classList.contains("animate-once")) {
                    entry.target.classList.remove("--is-visible");
                }
            })
        }, options);

        // Tag all cloaked elements on first page load.
        document.body.querySelectorAll('[x-cloak]').forEach((node) => {
            node.setAttribute('data-alpine-was-cloaked', '');
        })

        const initAlpine = () => {
            window.Alpine.discoverUninitializedComponents((el) => {
                window.Alpine.initializeComponent(el);
            });

            requestAnimationFrame(() => { window.Alpine.pauseMutationObserver = false });

            /**
             * Lets add our `.animation-*` classes to the observer.
             */
            document.querySelectorAll("[class*=animation-]").forEach(element => {
                observer.observe(element);
            });
        };

        const beforeRenderAlpine = (event) => {
            const newBody = document.body;
            newBody.querySelectorAll('[data-alpine-generated-me],[x-cloak]').forEach((el) => {
                if (el.hasAttribute('x-cloak')) {
                    // When we get a new document body tag any cloaked elements so we can cloak
                    // them again before caching.
                    el.setAttribute('data-alpine-was-cloaked', '')
                }

                if (el.hasAttribute('data-alpine-generated-me')) {
                    el.removeAttribute('data-alpine-generated-me')
                    if (typeof el.__x_for_key === 'undefined' && typeof el.__x_inserted_me === 'undefined') {
                        el.remove()
                    }
                }
            })
        };

        const beforeCacheAlpine = () => {
            window.Alpine.pauseMutationObserver = true;

            document.body.querySelectorAll('[x-for],[x-if],[data-alpine-was-cloaked]').forEach((el) => {
                // Cloak any elements again that were tagged when the page was loaded
                if (el.hasAttribute('data-alpine-was-cloaked')) {
                    el.setAttribute('x-cloak', '')
                    el.removeAttribute('data-alpine-was-cloaked')
                }

                if (el.hasAttribute('x-for')) {
                    let nextEl = el.nextElementSibling
                    while (nextEl && typeof nextEl.__x_for_key !== 'undefined') {
                        const currEl = nextEl
                        nextEl = nextEl.nextElementSibling
                        currEl.setAttribute('data-alpine-generated-me', true)
                    }
                } else if (el.hasAttribute('x-if')) {
                    const ifEl = el.nextElementSibling
                    if (ifEl && typeof ifEl.__x_inserted_me !== 'undefined') {
                        ifEl.setAttribute('data-alpine-generated-me', true)
                    }
                }
            })
        };

        initAlpine();

        blueprint.swup.on("contentReplaced", () => {
            beforeRenderAlpine();
            beforeCacheAlpine();
            initAlpine();
        });

        blueprint.swup.on("popState", event => {
            initAlpine();
        });
    }
});
