import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { BasisTextureLoader } from "three/examples/jsm/loaders/BasisTextureLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Stats from "stats.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { gsap } from "gsap/all";
import CustomEase from "gsap/CustomEase";

window.THREE = THREE

gsap.registerPlugin(CustomEase);

/**
 * Important notes:
 * We update mesh matrixes manually on objects that change position / orientation, all other objects won't update their matrixes automatically.
 * This can lead to weird behaviour if you forget! We do this for performance benefits.
 */
export class Conversion3DVisualiser {
    static IS_DEBUGGING = false;

    static Clear_Color = new THREE.Color("#ccc"); // background color

    static Camera_Controls_Min_Distance = 1; // min zoom distance
    static Camera_Controls_Max_Distance = 4; // max zoom distance
    static Camera_Controls_Enable_Damping = true; // smoothing of camera when moving
    static Camera_Controls_Damping_Factor = 0.5; // how *much* smoothing...

    /**
     * @param canvasElement {Element} why not use THREE fiber? Because this project is being used on a website that does not include React.
     * @param initCanvasWidth {number}
     * @param initCanvasHeight {number}
     * @param loadingProgressCallback {function} `(Conversion3DVisualiser, progress) => { ... }`
     * @param readyCallback {function} `(Conversion3DVisualiser) => { ... }`
     * @param isOrbitControlsEnabledOnModelLoad {boolean}
     * @param hasLoadingAnimation {boolean}
     */
    constructor(
        canvasElement,
        initCanvasWidth = window.innerWidth,
        initCanvasHeight = window.innerHeight,
        modelGlb,
        modelGlbSizeInBytes,
        loadingProgressCallback = () => {},
        readyCallback = () => {},
        isOrbitControlsEnabledOnModelLoad = false,
        hasLoadingAnimation = true
    ) {
        this.canvasElement = canvasElement;
        this.modelGlb = modelGlb;
        this.modelGlbSizeInBytes = modelGlbSizeInBytes;
        this.isRendering = false;
        this.isDestoyed = false;
        this.hasFailedLoadingModel = false;
        this.selectedClickableNameKey = null;
        this.stats = null;
        this.loadingProgressCallback = loadingProgressCallback;
        this.readyCallback = readyCallback;
        this.cameraDistanceToWorldCenter = 0;

        this.hasLogged = null;

        this.renderer = new THREE.WebGLRenderer({
            powerPreference: "high-performance",
            antialias: true || window.pixelRatio > 1, // only do antialias on devices that don't have a high resolution display
            stencil: false,
        });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(initCanvasWidth, initCanvasHeight);
        this.renderer.sortObjects = true;
        this.renderer.autoClear = false;
        this.renderer.shadowMap.enabled = true;

        canvasElement.innerHTML = "";
        canvasElement.appendChild(this.renderer.domElement);

        this.camera = new THREE.PerspectiveCamera(
            75,
            initCanvasWidth / initCanvasHeight,
            0.05,
            10
        );

        this.scene = new THREE.Scene();
        this.scene.background = Conversion3DVisualiser.Clear_Color;

        if (Conversion3DVisualiser.IS_DEBUGGING) {
            this.stats = new Stats();
            document.body.appendChild(this.stats.dom);

            const axesHelper = new THREE.AxesHelper(5);
            this.scene.add(axesHelper);
        }

        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.minDistance =
            Conversion3DVisualiser.Camera_Controls_Min_Distance;
        this.controls.maxDistance =
            Conversion3DVisualiser.Camera_Controls_Max_Distance;
        this.controls.enableDamping =
            Conversion3DVisualiser.Camera_Controls_Enable_Damping;
        this.controls.dampingFactor =
            Conversion3DVisualiser.Camera_Controls_Damping_Factor;
        this.controls.enabled = false;

        this.pointer = new THREE.Vector2();

        this.setupLighting();

        this.resetCameraPositionHeadOnTowardsModel();

        this.startupRendering();

        this.loadModel().then(() => {
            this.controls.enabled = isOrbitControlsEnabledOnModelLoad;

            if (this.hasLoadingAnimation) {
                this.animateCameraPositionSidewaysTowardsVan();
            }

            this.readyCallback(this);
        });
    }

    startupRendering() {
        if (this.isRendering) return;
        this.isRendering = true;
        this.renderFrame();
    }

    stopRendering() {
        this.isRendering = false;
    }

    renderFrame() {
        if (this.isRendering === false) return;
        if (this.isDestoyed)
            throw new Error(
                "This instance of Conversion3DVisualiser has been destroyed, create a new one."
            );

        requestAnimationFrame(this.renderFrame.bind(this));

        if (this.stats) this.stats.begin();

        this.controls.update();

        const currentCameraDistanceToWorldCenter = this.camera.position.distanceTo(
            new THREE.Vector3(0, 3, 0)
        );
        this.cameraDistanceToWorldCenter = currentCameraDistanceToWorldCenter;

        this.renderer.render(this.scene, this.camera);
        this.renderer.clearDepth();

        if (this.stats) this.stats.end();
    }

    resize(width, height) {
        if (this.isDestoyed) return;

        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(width, height);
    }

    destroy() {
        if (this.isDestoyed) return;

        this.stopRendering();
        this.isDestoyed = true;
        this.scene.remove(this.glb.scene);
        this.glb = null;
    }

    /**
     * Fetch + Load model into scene
     */
    loadModel() {
        return new Promise((resolve) => {
            this.fetchModel().then(() => {
                this.debug("model loaded", this);

                this.glb.scene.traverse((node) => {
                    if (node.material) {

                        node.material.metalness = 0.6;
                        node.material.roughness = 0.9;
                    }
                })

                this.scene.add(this.glb.scene);

                resolve();
            });
        });
    }

    /**
     * Fetch model from window instance if previously fetched, or fetch anew.
     * Saves model to local instance `this.glb`
     */
    fetchModel() {
        return new Promise((resolve, reject) => {
            const loader = new GLTFLoader();
            const dracoLoader = new DRACOLoader();
            dracoLoader.setDecoderConfig({ type: "wasm" });
            dracoLoader.setDecoderPath("/draco/");
            dracoLoader.setWorkerLimit(10);
            loader.setDRACOLoader(dracoLoader);

            loader.load(
                this.modelGlb,
                (glb) => {
                    glb = this.prepareModel(glb);

                    this.glb = glb;
                    this.hasFailedLoadingModel = false;
                    this.loadingProgressCallback(this, 100);
                    resolve();
                },
                (xhr) => {
                    this.loadingProgressCallback(
                        this,
                        Math.min(
                            99,
                            (xhr.loaded /
                                this.modelGlbSizeInBytes) *
                            100
                        )
                    );
                },
                (error) => {
                    this.hasFailedLoadingModel = true;
                    reject("Could not load model");
                }
            );
        });
    }

    removeObject3D(object3D) {
        if (!(object3D instanceof THREE.Object3D)) return false;

        // for better memory management and performance
        if (object3D.geometry) object3D.geometry.dispose();

        if (object3D.material) {
            if (object3D.material instanceof Array) {
                // for better memory management and performance
                object3D.material.forEach((material) => material.dispose());
            } else {
                // for better memory management and performance
                object3D.material.dispose();
            }
        }
        object3D.removeFromParent(); // the parent might be the scene or another Object3D, but it is sure to be removed this way
        return true;
    }

    /**
     * Metal materials don't transpose well from Blender to Three JS. This method prepares the GLB model accordingly.
     * (prepare GBL for better rendering of metal materials on THREE JS)
     * @param glb
     * @returns {*}
     */
    prepareModel(glb) {

        const model = glb.scene;
        model.traverse((node) => {
            node.castShadow = true;
            node.receiveShadow = true;
            if (node.material) {
                node.material.side = THREE.FrontSide;
            }
        });

        return glb;
    }

    setupLighting() {
        const light = new THREE.AmbientLight(0x404040, 8); // soft white light
        this.scene.add(light);

        const directionalLight = new THREE.SpotLight(0xffffff, 2.5, 15, 0.6);
        directionalLight.position.set(6, 7, 2);
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.width = 1600;
        directionalLight.shadow.mapSize.height = 1600;
        directionalLight.shadow.camera.top = 5;
        directionalLight.shadow.camera.bottom = -5;
        directionalLight.shadow.camera.left = -5;
        directionalLight.shadow.camera.right = 5;
        directionalLight.shadow.camera.near = 0.1;
        directionalLight.shadow.camera.far = 20;
        this.scene.add(directionalLight);

        const directionalLight2 = new THREE.SpotLight(0xffffff, 1.5, 15, 0.6);
        directionalLight2.position.set(-6, -6, 3);
        this.scene.add(directionalLight2);
    }

    resetCameraPositionHeadOnTowardsModel() {
        this.camera.position.x = 0;
        this.camera.position.y = 2;
        this.camera.position.z = 5;
        this.controls.target = new THREE.Vector3(0, 1, 0);
    }

    /**
     * @param state {boolean}
     */
    setOrbitControlsEnabled(state) {
        this.controls.enabled = state;
    }

    animateCameraPositionSidewaysTowardsVan() {
        const durationOfAnimationSeconds = 3;
        const delayAnimationSeconds = 1;
        const easing = CustomEase.create("custom", "M0,0 C0.626,0 0.504,1 1,1");

        return new Promise((resolve) => {
            const shouldReEnableControlsAfterAnimation = this.controls.enabled; // true only if orbit controls not already disabled
            this.controls.enabled = false; // disable orbit controls

            // calculate from --> to vectors for position/rotation of camera
            const fromPosition = this.camera.position;
            const toPosition = new THREE.Vector3(1.75, 1.25, 1.25);
            const fromTarget = this.controls.target;
            const toTarget = new THREE.Vector3(
                this.glb.scene.position.x,
                this.glb.scene.position.y + 1,
                this.glb.scene.position.z - 0.75
            );

            // setup lerp variables and callbacks
            let lerpedPosition = fromPosition.clone();
            const updateCameraPositionFromLerpedPosition = () => {
                this.camera.position.set(
                    lerpedPosition.x,
                    lerpedPosition.y,
                    lerpedPosition.z
                );
            };

            let lerpedTarget = fromTarget.clone();
            const updateOrbitControlsTargetFromLerpedTarget = () => {
                this.controls.target = lerpedTarget;
            };

            // gsap timeline
            const timeline = gsap.timeline({
                onComplete: () => {
                    if (shouldReEnableControlsAfterAnimation)
                        this.controls.enabled = true;
                    resolve();
                },
            });

            // animate!
            timeline.to(
                lerpedPosition,
                {
                    ...toPosition,
                    onUpdate: updateCameraPositionFromLerpedPosition,
                    onComplete: updateCameraPositionFromLerpedPosition,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds
            );

            timeline.to(
                lerpedTarget,
                {
                    ...toTarget,
                    onUpdate: updateOrbitControlsTargetFromLerpedTarget,
                    onComplete: updateOrbitControlsTargetFromLerpedTarget,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds
            );
        });
    }

    animateCameraPositionSidewaysTowardsModelQuick(lookAtY = 1.425584240781334) {
        const durationOfAnimationSeconds = 0.8;
        const delayAnimationSeconds = 0.1;
        const easing = CustomEase.create("custom", "M0,0 C0.626,0 0.504,1 1,1");

        return new Promise((resolve) => {
            const shouldReEnableControlsAfterAnimation = this.controls.enabled; // true only if orbit controls not already disabled
            this.controls.enabled = false; // disable orbit controls

            // calculate from --> to vectors for position/rotation of camera
            const fromPosition = this.camera.position;
            const toPosition = new THREE.Vector3(
                2.842303012388909,
                Math.max(lookAtY * 0.95, 1.7222271501547604),
                -0.226135607836432
            );
            const fromTarget = this.controls.target;
            const toTarget = new THREE.Vector3(
                -0.2942381565258469,
                lookAtY,
                -0.27438631379240835
            );

            // setup lerp variables and callbacks
            let lerpedPosition = fromPosition.clone();
            const updateCameraPositionFromLerpedPosition = () => {
                this.camera.position.set(
                    lerpedPosition.x,
                    lerpedPosition.y,
                    lerpedPosition.z
                );
            };

            let lerpedTarget = fromTarget.clone();
            const updateOrbitControlsTargetFromLerpedTarget = () => {
                this.controls.target = lerpedTarget;
            };

            // gsap timeline
            const timeline = gsap.timeline({
                onComplete: () => {
                    if (shouldReEnableControlsAfterAnimation)
                        this.controls.enabled = true;
                    resolve();
                },
            });

            // animate!
            timeline.to(
                lerpedPosition,
                {
                    ...toPosition,
                    onUpdate: updateCameraPositionFromLerpedPosition,
                    onComplete: updateCameraPositionFromLerpedPosition,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds
            );

            timeline.to(
                lerpedTarget,
                {
                    ...toTarget,
                    onUpdate: updateOrbitControlsTargetFromLerpedTarget,
                    onComplete: updateOrbitControlsTargetFromLerpedTarget,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds
            );
        });
    }

    /**
     * Animate camera towards vector. Disables orbit controls only while animating.
     * Note: this method is weighted, to encourage the camera to view vectors from 1 side of the van.
     * Returns promise which resolves once animation is complete and orbit controls re-enabled (only if not disabled before).
     * @param vector3 {THREE.Vector3}
     * @param preferredDistance {number} default = 3
     * @returns {Promise<void>}
     */
    cameraLookAt(vector3, preferredDistance = 3) {
        let durationOfAnimationSeconds = 0.8;
        const delayAnimationSeconds = 0;
        const easing = CustomEase.create("custom", "M0,0 C0.5,0 0.5,1 1,1");
        const cameraYOffset = 1; // always position camera slightly above target
        const cameraXWeight = 3;
        const cameraZWeight = 3;

        return new Promise((resolve) => {
            const shouldReEnableControlsAfterAnimation = this.controls.enabled; // true only if orbit controls not already disabled
            this.controls.enabled = false; // disable orbit controls

            // calculate from --> to vectors for position/rotation of camera
            const fromPosition = this.camera.position;
            const toPosition = vector3
                .clone()
                .add(this.camera.position.clone())
                .setLength(3);
            const fromTarget = this.controls.target;
            const toTarget = vector3.clone();

            // apply weights on camera position...
            toPosition.setY(vector3.y + 1); // always slightly above target vector3
            toPosition.setX(Math.max(1, toPosition.x * 1.25)); // encourage camera to be min 1 away from target
            toPosition.setZ(Math.min(0, toPosition.z * 0.5)); // encourage camera to tend towards the center of the Z axis

            const distanceBetweenFromAndToPosition =
                fromPosition.distanceTo(toPosition);
            const distanceBetweenCameraAndTarget =
                this.camera.position.distanceTo(vector3);

            // may need to increase duration of animation if changing position is quite far...
            if (distanceBetweenFromAndToPosition > 2) {
                durationOfAnimationSeconds = Math.max(
                    durationOfAnimationSeconds,
                    Math.min(3, 0.5 * distanceBetweenFromAndToPosition)
                ); // no less than default duration or more than 3s.
            }

            // setup lerp variables and callbacks
            let lerpedPosition = fromPosition.clone();
            const updateCameraPositionFromLerpedPosition = () => {
                this.camera.position.set(
                    lerpedPosition.x,
                    lerpedPosition.y,
                    lerpedPosition.z
                );
            };

            let lerpedTarget = fromTarget.clone();
            const updateOrbitControlsTargetFromLerpedTarget = () => {
                this.controls.target = lerpedTarget;
            };

            // gsap timeline
            const timeline = gsap.timeline({
                onComplete: () => {
                    if (shouldReEnableControlsAfterAnimation)
                        this.controls.enabled = true;
                    resolve();
                },
            });

            // animate!
            timeline.to(
                lerpedPosition,
                {
                    ...toPosition,
                    onUpdate: updateCameraPositionFromLerpedPosition,
                    onComplete: updateCameraPositionFromLerpedPosition,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds +
                (distanceBetweenCameraAndTarget < distanceBetweenFromAndToPosition
                    ? 0
                    : 0.35)
            );

            timeline.to(
                lerpedTarget,
                {
                    ...toTarget,
                    onUpdate: updateOrbitControlsTargetFromLerpedTarget,
                    onComplete: updateOrbitControlsTargetFromLerpedTarget,
                    duration: durationOfAnimationSeconds,
                    ease: easing,
                },
                delayAnimationSeconds +
                (distanceBetweenCameraAndTarget > distanceBetweenFromAndToPosition
                    ? 0
                    : 0.35)
            );
        });
    }

    get isLoadingModel() {
        return this.glb === undefined && !this.hasFailedLoadingModel;
    }

    /**
     * Only console logs if in debug mode
     */
    debug() {
        if (Conversion3DVisualiser.IS_DEBUGGING)
            console.log("[Conversion3DVisualiser]", ...arguments);
    }
}
