import { EcoDrifter2DVisualiser } from "./EcoDrifter3DVisualiser"

window.EcoDrifter2DVisualiser = EcoDrifter2DVisualiser

export default {

    create() {
        return new Promise((resolve, reject) => {
            resolve();
        });
    },

    destroy() {
        return new Promise((resolve, reject) => {
            window.dispatchEvent(new CustomEvent("destroy-eco-drifter-2d-visualiser", {}))
            resolve();
        });
    },

    /**
     * swup()
     * Detect swup events
     * @param  {Object} swup
     * @return {Promise}
     */
    swup(swup) {
        return new Promise((resolve, reject) => {
            try {
                swup.on("willReplaceContent", event => {
                    this.destroy();
                });
                swup.on("contentReplaced", event => {
                    this.create();
                });
                resolve();
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    },
}
