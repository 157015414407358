export const swatchTargets = {
  fridge: ["Fridge.001"].map((str) => str.replace(/\./gi, "")),
  Flooring: ["dashboard.003"].map((str) => str.replace(/\./gi, "")),
  SeatingFabric: [
    "Body1.009",
    "Body1.008",
    "Body1.005",
    "Body1.007",
    "Body1.006",
    "Body1.015",
    "Body1.012",
    "Body1.010",
    "Body1.011",
    "Body1.013",
    "Body1.014",
    "Head Rest",
    "Body1.020",
    "Body1.016",
    "Body1.018",
    "Body1.019",
    "Body1.017",
    "chair.002",
    "chair.001",
    "chair1.001",
    "chair1.002",
  ].map((str) => str.replace(/\./gi, "").replace(/\s/gi, "_")),
  PopTopCanvas: ["Roof_up_02.001"].map((str) => str.replace(/\./gi, "")),
  podsAndUnits: [
    "Drawer Top.001",
    "Left Top Cupboard.001",
    "Mid Top Cupboard.001",
    "Right Top Cupboard.001",
    "Cubby Side.001",
    "UnderChairsFace.003",
    "R Door.002",
    "L Door.002",
    "R Door.001",
    "L Door.001",
    "MainUnitFace.002",
    "Drawer Face.001",
    "UnitBody1.064",
    "Table Rail.001",
    "L Side.001",
    "R Side.001",
    "Back.001",
    "Face.001",
    "Big Back Door.001",
    "Low Door.001",
    "Big Low Door.001",
    "Big Door",
    "R Fridge.001",
    "Table .001",
    "Leg.001",
    "LED Back.001",
    "Base (1)",
    "Holder R.001",
    "Holder L.001",
  ].map((str) => str.replace(/\./gi, "").replace(/\s/gi, "_")),
  seatingOuter: [
    "chair.001",
    "chair1.001",
    "Body1.048",
    "Body1.050",
    "Body1.056",
    "Body1.051",
    "Head Rest.001",
    "Body1.053",
    "Body1.055",
    "Body1.063",
  ].map((str) => str.replace(/\./gi, "").replace(/\s/gi, "_")),
  seatingInner: [
    "Body1.052",
    "Body1.047",
    "Body1.054",
    "Body1.049",
    "OuterSeat.001",
    "OuterSeat.002",
    "chair1.002",
    "chair.002",
  ].map((str) => str.replace(/\./gi, "").replace(/\s/gi, "_")),
  vanColours: [
    "fender_b1.001",
    "hood1.001",
    "front_car.001",
    "Object004.001",
    "back_car.001",
    "back_car1.001",
    "Roof_up_04.001",
    "Roof_down_01.001",
    "Object002.001",
  ].map((str) => str.replace(/\./gi, "").replace(/\s/gi, "_")),
  splashback: [
    "Backsplash.001",
    "Upper Back.001",
    "Mid.001",
    "Taylored Back Logo.001",
  ].map((str) => str.replace(/\./gi, "").replace(/\s/gi, "_")),
  blind: ["Blind"].map((str) => str.replace(/\./gi, "").replace(/\s/gi, "_")),
  worktop: ["Worktop.001", "Flap.001"].map((str) =>
    str.replace(/\./gi, "").replace(/\s/gi, "_")
  ),
};
