import { colors } from "./colors";

export const clickableMeshes = {
  IncreasedTopAreaStorage: {
    title: "Increased Top Area Storage",
    number: 1,
    text: "By removing the Tambour roller door this has allowed a much larger top cupboard, We have also increased the height here to give a more fitted look to the unit.",
    color: colors.orange,
    distance: 3,
  },
  CubbyCorner: {
    title: "More Versatile Cubby Corner",
    number: 2,
    text: "Our cubby hole were always a popular feature, so we have made them bigger, and by doing this we have increased the rear storage which can be access by the back doors.",
    color: colors.green,
    distance: 3,
  },
  RIBKickboardDesign: {
    title: "Improved Lower RIB Kickboard Design",
    number: 3,
    text: "Increased door size and a magnet door hold to ensure a secure close.",
    color: colors.orange,
    distance: 3,
  },
  HobDesign: {
    title: "Contemporary Hob & Sink Design",
    number: 3,
    text: "We have created a stylish add on package with a raised hob and inset sink unit. This is an extra debuting on our new Drifter 2.0 but is available on most of our other conversions.",
    color: colors.orange,
    distance: 3,
  },
  InternalChanges: {
    title: "Internal Changes",
    number: 3,
    text: "As well as making cosmetic practical changes, we are always improving the cutting, building and assembly of our conversion process.",
    color: colors.orange,
    distance: 3,
  },
};
