<template>
  <div class="px-4 pb-4 mt-8">
    <div class="mb-5">
      <label class="sr-only" for="contact-information-form-name"
        >Your full name</label
      >
      <input
        v-model="$root.name"
        placeholder="Your full name"
        type="text"
        class="w-full p-2 font-inter text-dim-gray rounded-lg border-b border-silver-chalice"
        id="contact-information-form-name"
      />
    </div>
    <div class="mb-5">
      <label class="sr-only" for="contact-information-form-phone"
        >Your Phone Number</label
      >
      <input
        v-model="$root.phone"
        placeholder="Your Phone Number"
        type="phone"
        class="w-full p-2 font-inter text-dim-gray rounded-lg border-b border-silver-chalice"
        id="contact-information-form-phone"
      />
    </div>
    <div class="mb-5">
      <label class="sr-only" for="contact-information-form-email"
        >Your Email Address</label
      >
      <input
        v-model="$root.email"
        placeholder="Your Email Address"
        type="email"
        class="w-full p-2 font-inter text-dim-gray rounded-lg border-b border-silver-chalice"
        id="contact-information-form-email"
      />
    </div>
    <div class="mb-5">
      <label class="sr-only" for="contact-information-form-additional-info"
        >Any additional information?</label
      >
      <textarea
        v-model="$root.enquiry"
        placeholder="Any additional information?"
        rows="4"
        class="w-full p-2 font-inter text-dim-gray rounded-lg border-b border-silver-chalice"
        id="contact-information-form-additional-info"
      />
    </div>
    <div class="mb-5">
      <p class="leading-4 text-dim-gray">
        <small>
          Disclaimer: the colours of your chosen swatches may vary slightly in
          comparison to what they look like in real life depending on the screen
          they're being viewed on.
        </small>
      </p>
    </div>
    <div v-if="$root.errors && $root.errors.length > 0" class="mb-5">
      <p
        class="pb-1 text-sm text-flame"
        v-for="(error, index) in $root.errors"
        :key="error + index"
      >
        {{ error }}
      </p>
    </div>
    <div>
      <button
        class="bg-flame text-center font-inter text-white rounded-full py-1 px-10 text-xl"
        @click="$root.submit"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactInformationForm",
};
</script>
