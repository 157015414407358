import {colors} from "./colors"

export const clickableMeshes = {
    Bamboo: {
      title: "Bamboo",
      number: 1,
      text: "We now use Bamboo as it is is one of the fastest growing plants and takes just five years to grow to full maturity. Despite its growth speed it is incredibly hard-wearing and naturally water resistant. As it’s a grass and not a tree and it grows incredibly quickly meaning this is a much more sustainable option for us.",
      color: colors.orange,
      distance: 3,
    },
    BareEdge: {
      title: "Bare Edge Birch",
      number: 2,
      text: "Our Eco units are cut from Birch Ply which is much higher quality than regular plywood, meaning the edges can be left as bare wood and finished with an oil to give it a bit more protection and shine, eliminating the need for plastic edging.",
      color: colors.green,
      distance: 3,
    },
    CarpetLining: {
      title: "Carpet Lining",
      number: 3,
      text: "The carpet lining we use is made from PET recycled plastic bottles.",
      color: colors.orange,
      distance: 3,
    },
    EcoHandles: {
      title: "Eco Handles",
      number: 4,
      text: "Our all new Eco Handles are CNC machine cut into the doors to replace the previously used plastic handles.",
      color: colors.green,
      distance: 3,
    },
    Glue: {
      title: "Glue",
      number: 5,
      text: "The glue that is used for sticking the lining to the van contains no solvents that can harm the enviroment or human health. It is also non-chlorinated.",
      color: colors.orange,
      distance: 3,
    },
    Insulation: {
      title: "Insulation",
      number: 6,
      text: "We use insulation made from recycled plastic bottles.",
      color: colors.turquoise,
      distance: 3,
    },
    LithiumBattery: {
      title: "Lithium Battery",
      number: 7,
      text: "Compared to lead acid, lithium batteries have a much longer life span. This is due to being able to cope with larger and more frequent discharges without jeopardising the batteries health. Lithium also benefits from quicker charging times, is much lighter weight and performs in hotter and colder temperatures.",
      color: colors.yellow,
      distance: 3,
    },
    PopTop: {
      title: "Pop Top",
      number: 8,
      text: "The Skyline Roof uses a PET foam made out of 100% recycled bottles.",
      color: colors.yellow,
      distance: 1,
    },
    ReduceBlocks: {
      title: "Units",
      number: 9,
      text: "Our units are cut in house and designed using classic joining methods to reduce the use of plastic assembly blocks.",
      color: colors.yellow,
      distance: 3,
    },
    TableRunnerAndLeg: {
      title: "Table Runner and Table Leg",
      number: 10,
      text: "We use a wooden table runner to replace the originally used plastic runner this, coupled with the table leg is also made from CNC cut wood to replace the aluminium leg, creates a more sustainable option.",
      color: colors.green,
      distance: 3,
    },
    WoodenStorageClip: {
      title: "Wooden Storage Clip",
      number: 11,
      text: "We invest a lot of time coming up with different ways to utilise our Birch offcuts to reduce waste. One of these is a table storage bracket which holds the table neatly down the left hand side of the fridge unit or on the back of the RIB bed.",
      color: colors.turquoise,
      distance: 3,
    },
  }
