import Vue from "$vue";
import Conversion3DBuilder from "./Conversion3DBuilder.vue";
import { conversionsAndSwatchTargets } from "./conversionsAndSwatchTargets";
import { generateOptions } from "./generateOptions";
import Sidebar from "./Sidebar.vue";
import Visualiser from "./Visualiser.vue";
import { defaultSelectedSwatches } from "./defaultSelectedSwatches";

export default {
  instances: [],

  destroy() {
    this.instances.forEach((instance) => {
      instance.$destroy();
    });

    this.instances = [];
  },

  create() {
    return new Promise((resolve) => {
      resolve();

      const rootElement = document.querySelector("#vue--conversion-3d-builder");

      if (rootElement) {
        try {
          const swatches = JSON.parse(
            rootElement.getAttribute("data-swatches")
          );
          const extras = JSON.parse(rootElement.getAttribute("data-extras"));

          const options = generateOptions(swatches, extras);

          const vue = new Vue({
            components: {
              Conversion3DBuilder,
              Sidebar,
              Visualiser,
            },
            el: rootElement,
            template: "<Conversion3DBuilder/>",
            data: {
              options,
              selected: {
                ...defaultSelectedSwatches,

                vanType: {
                  id: "vanType",
                  item: "",
                  itemIndex: 0,
                  title: options.vanType.options[0].title,
                  type: "vanType",
                  value: options.vanType.options[0],
                },

                wheelbase: {
                  id: "wheelbase",
                  item: "",
                  itemIndex: 0,
                  title: options.wheelbase.options[0].title,
                  type: "wheelbase",
                  value: options.wheelbase.options[0],
                },

                boot: {
                  id: "boot",
                  item: "",
                  itemIndex: 0,
                  title: options.boot.options[0].title,
                  type: "boot",
                  value: options.boot.options[0],
                },

                trim: {
                  id: "trim",
                  item: "",
                  itemIndex: 0,
                  title: options.trim.options[0].title,
                  type: "trim",
                  value: options.trim.options[0],
                },

                "extrashasWindowUpgradesDriverSideFrontWindow2": {
                  "id": "extrashasWindowUpgradesDriverSideFrontWindow2",
                  "type": "extras",
                  "item": "hasWindowUpgradesDriverSideFrontWindow2",
                  "title": "(Extra, Driver-side front window) Opener",
                  "itemIndex": 2,
                  "value": {
                    "title": "Opener",
                    "imageUrl": "/media/images/windows/driver-side-front-window.png",
                    "value": "opener",
                    "message": "Selected: Opener Window - normal window with opener for air"
                  }
                },
                "extrashasWindowUpgradesDriverSideRearWindow3": {
                  "id": "extrashasWindowUpgradesDriverSideRearWindow3",
                  "type": "extras",
                  "item": "hasWindowUpgradesDriverSideRearWindow3",
                  "title": "(Extra, Driver-side rear window) Panel",
                  "itemIndex": 3,
                  "value": {
                    "title": "Panel",
                    "imageUrl": "/media/images/windows/driver-side-rear-window.png",
                    "value": "panel",
                    "message": "Selected: Panel - no window"
                  }
                },
                "extrashasWindowUpgradesBootWindow0": {
                  "id": "extrashasWindowUpgradesBootWindow0",
                  "type": "extras",
                  "item": "hasWindowUpgradesBootWindow0",
                  "title": "(Extra, Boot window) Fake",
                  "itemIndex": 0,
                  "value": {
                    "title": "Fake",
                    "imageUrl": "/media/images/windows/boot-window.png",
                    "value": "fake",
                    "message": "Selected: Fake Window - looks like a window from the outside"
                  }
                },
                "extrashasWindowUpgradesPassengerSideRearWindow1": {
                  "id": "extrashasWindowUpgradesPassengerSideRearWindow1",
                  "type": "extras",
                  "item": "hasWindowUpgradesPassengerSideRearWindow1",
                  "title": "(Extra, Passenger-side rear window) Fixed",
                  "itemIndex": 1,
                  "value": {
                    "title": "Fixed",
                    "imageUrl": "/media/images/windows/passenger-side-rear-window.png",
                    "value": "fixed",
                    "message": "Selected: Fixed Window - normal window, cannot be opened"
                  }
                },
                "extrashasWindowUpgradesPassengerSideFrontWindow1": {
                  "id": "extrashasWindowUpgradesPassengerSideFrontWindow1",
                  "type": "extras",
                  "item": "hasWindowUpgradesPassengerSideFrontWindow1",
                  "title": "(Extra, Passenger-side front window) Fixed",
                  "itemIndex": 1,
                  "value": {
                    "title": "Fixed",
                    "imageUrl": "/media/images/windows/passenger-side-front-window.png",
                    "value": "fixed",
                    "message": "Selected: Fixed Window - normal window, cannot be opened"
                  }
                },

                // hasWindowUpgradesPassengerSideRearWindow: {
                //   id: "hasWindowUpgradesPassengerSideRearWindow",
                //   item: "",
                //   itemIndex: 1,
                //   title: options.hasWindowUpgradesPassengerSideRearWindow.options[1].title,
                //   type: "hasWindowUpgradesPassengerSideRearWindow",
                //   value: options.hasWindowUpgradesPassengerSideRearWindow.options[1],
                // },

                // hasWindowUpgradesDriverSideRearWindow: {
                //   id: "hasWindowUpgradesDriverSideRearWindow",
                //   item: "",
                //   itemIndex: 3,
                //   title: options.hasWindowUpgradesDriverSideRearWindow.options[3].title,
                //   type: "hasWindowUpgradesDriverSideRearWindow",
                //   value: options.hasWindowUpgradesDriverSideRearWindow.options[3],
                // },

                // hasWindowUpgradesDriverSideFrontWindow: {
                //   id: "hasWindowUpgradesDriverSideFrontWindow",
                //   item: "",
                //   itemIndex: 2,
                //   title: options.hasWindowUpgradesDriverSideFrontWindow.options[2].title,
                //   type: "hasWindowUpgradesDriverSideFrontWindow",
                //   value: options.hasWindowUpgradesDriverSideFrontWindow.options[2],
                // },

                // hasWindowUpgradesPassengerSideFrontWindow: {
                //   id: "hasWindowUpgradesPassengerSideFrontWindow",
                //   item: "",
                //   itemIndex: 1,
                //   title: options.hasWindowUpgradesPassengerSideFrontWindow.options[1].title,
                //   type: "hasWindowUpgradesPassengerSideFrontWindow",
                //   value: options.hasWindowUpgradesPassengerSideFrontWindow.options[1],
                // },

                // hasWindowUpgradesBootWindow: {
                //   id: "hasWindowUpgradesBootWindow",
                //   item: "",
                //   itemIndex: 0,
                //   title: options.hasWindowUpgradesBootWindow.options[0].title,
                //   type: "hasWindowUpgradesBootWindow",
                //   value: options.hasWindowUpgradesBootWindow.options[0],
                // },

                conversions: {
                  id: "conversions",
                  item: "",
                  itemIndex: 0,
                  type: "conversions",
                  title: `(Conversion) ${options.conversions[5].title}`,
                  value: {
                    title: options.conversions[5].name,
                    ...options.conversions[5],
                  },
                },
              },
              name: "",
              email: "",
              phone: "",
              enquiry: "",
              hasSubmitted: false,
              errors: [],
            },
            methods: {
              setSelected(type, key, value, itemIndex, title) {

                const currentTrim = this.selected["trim"].value.value

                if (type === "conversions") {
                  const item = {
                    id: "trim",
                    type: "",
                    item: "",
                    title: `${this.options.trim.options[0].title}`,
                    itemIndex: 0,
                    value: this.options.trim.options[0],
                  };

                  this.selected = {
                    ...this.selected,
                    ["trim"]: item,
                  };
                }

                if (type === "trim") {
                  const currentConversionKey =
                    this.selected.conversions.value.key;

                  let newConversion;
                  let newConversionIndex;

                  const newConversionKey =
                    value.value + currentConversionKey.slice(currentTrim.length);

                  newConversionIndex = this.options.conversions
                    .filter((conversion) => !conversion.key.startsWith(value.value))
                    .findIndex(
                      (object) => object.key === currentConversionKey
                    );

                  newConversion = this.options.conversions.find(
                    (object) => object.key === newConversionKey
                  );

                  // If no eco version of conversion, don't change the model.
                  if (newConversion !== undefined) {
                    const item = {
                      id: "conversions",
                      type: "",
                      item: "",
                      title: `(Conversion) ${newConversion.title}`,
                      itemIndex: newConversionIndex,
                      value: newConversion,
                    };

                    this.selected = {
                      ...this.selected,
                      ["conversions"]: item,
                    };
                  }
                }

                const id = type + key;

                const item = {
                  id,
                  type,
                  item: key,
                  title,
                  itemIndex: Number.parseInt(itemIndex),
                  value,
                };

                this.selected = {
                  ...this.selected,
                  [id]: item,
                };
              },
              /**
               * Behaves the same as `this.setSelected(...)` but toggles existence (if exist then remove, otherwise add)
               * @param type
               * @param key
               * @param value
               * @param itemIndex
               * @returns
               */
              setToggle(type, key, value, itemIndex, title, rules = false) {
                const id = type + key;

                const item = {
                  id,
                  type,
                  item: key,
                  title,
                  itemIndex: Number.parseInt(itemIndex),
                  value,
                };

                // If the item is already selected, unselect it and return
                if (
                  this.selected[id] &&
                  JSON.stringify(this.selected[id]) == JSON.stringify(item)
                ) {
                  const { [id]: selectedId, ...selected } = this.selected;
                  this.selected = selected;
                  return;
                }

                // If rules are present...
                if (rules !== false) {
                  // Handle the incompatibleIndexes if there are some...
                  if (rules.incompatibleIndexes !== undefined) {
                    const { incompatibleIndexes } = rules;
                    const incompatibleSet = new Set(incompatibleIndexes);

                    // If the new item is in incompatibleIndexes, unselect all other items in incompatibleIndexes
                    if (incompatibleSet.has(key)) {
                      for (const selectedId in this.selected) {
                        if (
                          incompatibleSet.has(this.selected[selectedId].item)
                        ) {
                          const { [selectedId]: _, ...selected } =
                            this.selected;
                          this.selected = selected;
                        }
                      }
                    }
                  }
                }

                // If no incompatibilities were found or rules weren't present, select the new item
                this.selected = {
                  ...this.selected,
                  [id]: item,
                };
              },

              submit() {
                if (this.hasSubmitted) return;

                const build = Object.values(this.selected)
                  .map((item) => item.title)
                  .join("\n");

                const formData = new FormData();

                formData.append("name", this.name);
                formData.append("email", this.email);
                formData.append("phone", this.phone);
                formData.append("enquiry", this.enquiry);
                formData.append("build", build);

                this.errors = [];

                window.axios
                  .post("/api/form/3d-builder", formData)
                  .then((response) => {
                    console.log("submitted", formData);
                    this.hasSubmitted = true;
                  })
                  .catch((error) => {
                    console.error(error);
                    if (error.response && error.response.status == 422) {
                      this.errors = Object.values(error.response.data);
                    }
                  })
                  .finally(() => {});
              },
            },
          });

          this.instances.push(vue);
        } catch (error) {
          console.error(error);
        }
      }
    });
  },

  /**
   * swup()
   * Detect swup events and correctly restart animation detection
   * @param  {Object} swup
   * @return {Promise}
   */
  swup(swup) {
    return new Promise((resolve, reject) => {
      try {
        swup.on("willReplaceContent", (event) => {
          this.destroy();
        });
        swup.on("contentReplaced", (event) => {
          this.create();
        });
        resolve();
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  },
};
