// TODO - split into seperate files.
// WANT - move to TS

const cleanTargetNameForThreeJS = (str) =>
  str.replace(/\./gi, "").replace(/\s/gi, "_").replace(/[\/\:]/gi, "");

export const conversionsAndSwatchTargets = {
  ecoWayfarerSWB: {
    name: "ECO Wayfarer",
    imageUrl: "/media/webgl/conversions/thumbnails/wayfarer-swb.webp",
    modelUrl:
      "/media/webgl/eco-wayfarer-swb-in-a-volkswagen-transporter.glb?v=5",
    modelSizeBytes: 5007000,
    conversionRootNodeName: cleanTargetNameForThreeJS(
      "SWB  Wayfarer All In v2"
    ),

    defaultMeshVisibility: {
      showMeshes: [].map(cleanTargetNameForThreeJS),
      hideMeshes: ["Roof_up_04.001", "zipline", "window_s3.001", "window_frame_s.002", "big_blinds_2", "big_blinds_1", "swivel_chair", "KRS Roof Rack v4", "Solar Panel v5", ].map(cleanTargetNameForThreeJS),
    },
    
    // Meshes to toggle in response to enabled extras
    extraMeshes: {
      extrastableUpgrades1: {
        showMeshes: [
          "Cranked Arm Table v3.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments2: {
        showMeshes: [
          "Vamoose Awning Rail v2.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments1: {
        showMeshes: [
          "Thule Wanderway Bike Rack v9",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      "extrastracking&Safety0": {
        showMeshes: [
          "View Dash Cam v1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras7: {
        showMeshes: ["KRS Roof Rack v4"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraschargingUpgrades3: {
        showMeshes: ["Solar Panel v5"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      hasWindowUpgrades: {
        showMeshes: ["window_s3.001", "window_s3.002", "window_frame_s.002", "big_blinds_2", "big_blinds_1"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["standard_blocked_off_window_1", "standard_blocked_off_window_1_internal", "standard_blocked_off_window_2", "standard_blocked_off_window_2_internal",].map(cleanTargetNameForThreeJS),
      },
      extrasseatingUpgrades3: {
        showMeshes: ["swivel_chair"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["chair1.001", "chair1.002"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras5: {
        showMeshes: ["Roof_up_04.001"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["Roof_up_black"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras8: {
        showMeshes: ["zipline"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
    },

    swatchTargets: {
      kitchenUnits: [
        "Low Door.001",
        "Mid Drawer.001",
        "Top Drawer.001",
        "Face Top.001",
        "Back Door.001",
        "Back End.001",
        "L Fridge.001",
        "R Fridge.001",
        "R Door.001",
        "L Door.001",
        "Face.001",
        "Front Side.001",
        "Back Side.001",
        "Front.001",
        "Trim (1)",
        "Smev Shelf",
        "R Side (1)",
        "L Side (1)",
        "Back.001",
        "Fridge Door.001",
        "L Side.001",
        "Fridge Shelf.002",
        "Front (1).001",
        "R Side.001",
        "Back (1)",
        "Trim.001",
        "Body58",
        "Body59",
        "R LED Back.001",
        "L LED Back.001",
        "LED Back.001",
        "Sides.001",
        "Front.002",
        "back (1)",
        "Sides",
        "Sides.003",
        "Front.003",
        "Sides.002",
        "back (1).001",
        "Back Shelf.001",
        "Back Shelf.002",
        "Mid.002",
        "Mid.001",
      ].map(cleanTargetNameForThreeJS),

      worktopFinishes: [
        "Table.001",
        "Fridge Worktop",
        "Smev Side Worktop.001",
        "Fridge Side Worktop.001",
      ].map(cleanTargetNameForThreeJS),

      fridgeColour: ["Fridge.001"].map(cleanTargetNameForThreeJS),
      
      upholstery: [
        "swivel_chair",
        "chair.001",
        "chair1.001",
        "Body1.048",
        "Body1.050",
        "Body1.056",
        "Body1.051",
        "Head Rest.001",
        "Body1.053",
        "Body1.055",
        "Body1.063",
        "Body1.042",
        "Body2",
        "Body2.001",
        "Body1.044",
        "Body1.040",
        "Body1.043",
        "R Seat.001",
        "L Seat.001",
        "Body1.013",
        "R Seat.003",
        "R Back.001",
        "Body1.017",
        "Body1.016",
        "Head Rest.003",
        "R Back",
        "Body1.016",
        "Body1.017",
        "Body1.018",
        "Body1.015",
        "Body1.019",
        "L Back",
        "R Back Seat.001",
        "Mid Back Seat.001",
        "L Back Seat.001",
        "R Back Seat.003",
        "Mid Back Seat.003",
        "L Back Seat.003",
        "Body1.052",
        "Body1.047",
        "Body1.054",
        "Body1.049",
        "OuterSeat.001",
        "OuterSeat.002",
        "chair1.002",
        "chair.002",
        "Body4",
        "Mid Seat.001",
        "Back Mid.001",
        "Mid Seat.003",
        "Back Mid.003",
      ].map(cleanTargetNameForThreeJS),

      vanColours: [
        "TopSideRoofRims",
        "Object013.001",
        "Roof_down_02.001",
        "doorhandle.001",
        "doorhandle_key.001",
        "doorhandle_key1.001",
        "side_mirror_1.001",
        "side_mirror_2.001",
        "fender_b1.001",
        "hood1.001",
        "front_car.001",
        "Object004.001",
        "back_car.001",
        "back_car1.001",
        "Roof_up_04.001",
        "Roof_down_01.001",
        "Object002.001",
        "standard_blocked_off_window_1",
        "standard_blocked_off_window_2",
      ].map(cleanTargetNameForThreeJS),

      vanWallLining4WayStretchCarpet: [
        "wheelcap_carpet",
        "Roof00.001",
        "frame_s.001",
        "frame_s1.001",
        "fender_s.001",
        "door_s4.001",
        "standard_blocked_off_window_1_internal",
        "standard_blocked_off_window_2_internal",
      ].map(cleanTargetNameForThreeJS),

      flooringRange: ["dashboard.003"].map(cleanTargetNameForThreeJS),

      popTopCanvas: ["Roof_up_02.001"].map(cleanTargetNameForThreeJS),

      blindsCurtains: [
        "big_blinds",
        "big_blinds_1",
        "big_blinds_2",
      ].map(cleanTargetNameForThreeJS),

      // seatingRIBAltairBedFabric: [].map(cleanTargetNameForThreeJS),

      // woodEdgeFinish: [].map(cleanTargetNameForThreeJS),
    },
  },

  ecoVoyagerSWB: {
    name: "ECO Voyager",
    imageUrl: "/media/webgl/conversions/thumbnails/voyager-swb.png",
    modelUrl: "/media/webgl/eco-voyager-swb-in-a-volkswagen-transporter.glb",
    modelSizeBytes: 5007000,
    conversionRootNodeName: cleanTargetNameForThreeJS("Front Loading v2"),

    defaultMeshVisibility: {
      showMeshes: [].map(cleanTargetNameForThreeJS),
      hideMeshes: ["Roof_up_04.001", "zipline", "window_s3.001", "window_frame_s.002", "big_blinds_2", "big_blinds_1", "swivel_chair", "KRS Roof Rack v4", "Solar Panel v5", ].map(cleanTargetNameForThreeJS),
    },
    
    // Meshes to toggle in response to enabled extras
    extraMeshes: {
      extrastableUpgrades1: {
        showMeshes: [
          "Cranked Arm Table v3.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments2: {
        showMeshes: [
          "Vamoose Awning Rail v2.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments1: {
        showMeshes: [
          "Thule Wanderway Bike Rack v9",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      "extrastracking&Safety0": {
        showMeshes: [
          "View Dash Cam v1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras7: {
        showMeshes: ["KRS Roof Rack v4"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraschargingUpgrades3: {
        showMeshes: ["Solar Panel v5"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      hasWindowUpgrades: {
        showMeshes: ["window_s3.001", "window_s3.002", "window_frame_s.002", "big_blinds_2", "big_blinds_1"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["standard_blocked_off_window_1", "standard_blocked_off_window_1_internal", "standard_blocked_off_window_2", "standard_blocked_off_window_2_internal",].map(cleanTargetNameForThreeJS),
      },
      extrasseatingUpgrades3: {
        showMeshes: ["swivel_chair"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["chair1.001", "chair1.002"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras5: {
        showMeshes: ["Roof_up_04.001"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["Roof_up_black"].map(cleanTargetNameForThreeJS),
      },

      extraspoptopOptionalExtras8: {
        showMeshes: ["zipline"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },

      extrasadditionalKitchenStorage1: {
        // Top cupboards
        showMeshes: ["ECO Expedition All In v2"].map(cleanTargetNameForThreeJS),
        hideMeshes: [
          "Shelf Side.001",
          "Shelf Sides.001",
          "Body3.004",
          "Body2.006",
          "Body2.005",
          "Body3.005",
          "Body4.001",
        ].map(cleanTargetNameForThreeJS),
      },
    },

    swatchTargets: {
      // splashbacks: [
      //   "Surround.001",
      // ].map(cleanTargetNameForThreeJS),

      kitchenUnits: [
        "Body1",
        "Left drawer",
        "Right (1)",
        "Door.001",
        "Left.001",
        "Front Cover",
        "Body1.020",
        "Body1.019",
        "Shelf Side.001",
        "Body3.004",
        "Body4.001",
        "Body2.006",
        "Body2.005",
        "Shelf Sides.001",
        "Table Leg.001",
        "Body3.005",
        "Table.001",
        "Right Top Cupboard.001",
        "R Side.001",
        "Mid Top Cupboard.001",
        "Mid R.001",
        "Mid L.001",
        "Left Top Cupboard.001",
        "L Side.001",
        "Base (1)",
      ].map(cleanTargetNameForThreeJS),

      worktopFinishes: [
        "Table.001",
        "Top.001",
      ].map(cleanTargetNameForThreeJS),

      fridgeColour: ["Fridge.001"].map(cleanTargetNameForThreeJS),
      
      upholstery: [
        "swivel_chair",
        "chair.001",
        "chair1.001",
        "Body1.048",
        "Body1.050",
        "Body1.056",
        "Body1.051",
        "Head Rest.001",
        "Body1.053",
        "Body1.055",
        "Body1.063",
        "Body1.042",
        "Body2",
        "Body2.001",
        "Body1.044",
        "Body1.040",
        "Body1.043",

        "Body1.016",
        "Body1.011",
        "Body2.003",
        "Body2.002",

        "Body4",
        "Body1.017",
        "Head Rest.001",
        "Body1.009",
        "Body1.013",
        "Body1.018",
        "Body1.012",

        "Body1.025",
        "Body1.021",
        "Body1.023",
        "Body1.024",
        "Body2.004",
        "Body3.003",
        "Body1.022",
        
        "chair1.002",
        "chair.002",
        "Body3.002",
        "Body3.001",
        "Body1.010",
        "Body1.014",
        "Body1.008",
        "Body1.015",
      ].map(cleanTargetNameForThreeJS),

      vanColours: [
        "TopSideRoofRims",
        "Object013.001",
        "Roof_down_02.001",
        "doorhandle.001",
        "doorhandle_key.001",
        "doorhandle_key1.001",
        "side_mirror_1.001",
        "side_mirror_2.001",
        "fender_b1.001",
        "hood1.001",
        "front_car.001",
        "Object004.001",
        "back_car.001",
        "back_car1.001",
        "Roof_up_04.001",
        "Roof_down_01.001",
        "Object002.001",
        "standard_blocked_off_window_1",
        "standard_blocked_off_window_2",
      ].map(cleanTargetNameForThreeJS),

      vanWallLining4WayStretchCarpet: [
        "wheelcap_carpet",
        "Roof00.001",
        "frame_s.001",
        "frame_s1.001",
        "fender_s.001",
        "door_s4.001",
        "standard_blocked_off_window_1_internal",
        "standard_blocked_off_window_2_internal",
      ].map(cleanTargetNameForThreeJS),

      flooringRange: ["dashboard.003"].map(cleanTargetNameForThreeJS),

      popTopCanvas: ["Roof_up_02.001"].map(cleanTargetNameForThreeJS),

      blindsCurtains: [
        "big_blinds",
        "big_blinds_1",
        "big_blinds_2",
      ].map(cleanTargetNameForThreeJS),

      // seatingRIBAltairBedFabric: [].map(cleanTargetNameForThreeJS),

      // woodEdgeFinish: [].map(cleanTargetNameForThreeJS),
    },
  },

  ecoRoamerSWB: {
    name: "ECO Roamer",
    imageUrl: "/media/webgl/conversions/thumbnails/roamer-swb.webp",
    modelUrl: "/media/webgl/eco-roamer-swb-in-a-volkswagen-transporter.glb",
    modelSizeBytes: 5007000,
    conversionRootNodeName: cleanTargetNameForThreeJS("ECO Roamer All In v1"),

    defaultMeshVisibility: {
      showMeshes: [].map(cleanTargetNameForThreeJS),
      hideMeshes: ["Roof_up_04.001", "zipline", "window_s3.001", "window_frame_s.002", "big_blinds_2", "big_blinds_1", "swivel_chair", "KRS Roof Rack v4", "Solar Panel v5", ].map(cleanTargetNameForThreeJS),
    },
    
    // Meshes to toggle in response to enabled extras
    extraMeshes: {
      extrastableUpgrades1: {
        showMeshes: [
          "Cranked Arm Table v3.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments2: {
        showMeshes: [
          "Vamoose Awning Rail v2.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments1: {
        showMeshes: [
          "Thule Wanderway Bike Rack v9",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      "extrastracking&Safety0": {
        showMeshes: [
          "View Dash Cam v1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras7: {
        showMeshes: ["KRS Roof Rack v4"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraschargingUpgrades3: {
        showMeshes: ["Solar Panel v5"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      hasWindowUpgrades: {
        showMeshes: ["window_s3.001", "window_s3.002", "window_frame_s.002", "big_blinds_2", "big_blinds_1"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["standard_blocked_off_window_1", "standard_blocked_off_window_1_internal", "standard_blocked_off_window_2", "standard_blocked_off_window_2_internal",].map(cleanTargetNameForThreeJS),
      },
      extrasseatingUpgrades3: {
        showMeshes: ["swivel_chair"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["chair1.001", "chair1.002"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras5: {
        showMeshes: ["Roof_up_04.001"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["Roof_up_black"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras8: {
        showMeshes: ["zipline"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
    },

    swatchTargets: {
      // splashbacks: [
      //   "Surround.001",
      // ].map(cleanTargetNameForThreeJS),

      kitchenUnits: [
        "Face.002",
        "Face.001",
        "High Door",
        "Low Door.001",
        "L Door.001",
        "Drawer Face",
        "Fridge Left.001",
        "Fridge Right.001",
        "Drawer Top.001",
        "Face.001",
        "Face.002",
        "Cubby Side.001",
        "High Door",
        "Low Door.001",
        "Back .001",
        "Body1.053",
        "Body1.051",
        "Body1.052",
        "Left Top Cupboard.001",
        "Mid Top Cupboard.001",
        "Right Top Cupboard.001",
        "UnderChairsFace.003",
        "R Door.002",
        "L Door.002",
        "MainUnitFace.002",
        "Drawer Face.001",
        "UnitBody1.064",
        "Table Rail.001",
        "L Side.001",
        "R Side.001",
        "Back.001",
        "Big Back Door.001",
        "Big Low Door.001",
        "Big Door",
        "R Fridge.001",
        "Table .001",
        "Leg.001",
        "LED Back.001",
        "Base (1)",
        "Holder R.001",
        "Holder L.001",

        "Body1",
        "Mid Door.001",
        "Base.001",
        "Back Base.001",
        "Back Face.001",
        "Back Door.002",
        "Back Face.001",
        "Face.002",
        "L Side.001",
        "Face.003",
        "R Door.003",
        "L Door.003",
        "Face",
        "R Door",
        "Back Back.001",
        "Back Face.001",
        "R Mid.001",
        "L MId.001",

        "Front Door.001",
        "Back (1).002",
        "Back Door",
      ].map(cleanTargetNameForThreeJS),

      worktopFinishes: [
        "Table.001",
        "Worktop.001",
        "Flap.001",
        "Small Flap.001",
        "Big Flap.001",
      ].map(cleanTargetNameForThreeJS),

      fridgeColour: ["Fridge.001"].map(cleanTargetNameForThreeJS),
      
      upholstery: [
        "swivel_chair",
        "L Back",
        "chair.001",
        "chair1.001",
        "Body1.048",
        "Body1.050",
        "Body1.056",
        "Body1.051",
        "Head Rest.001",
        "Body1.053",
        "Body1.055",
        "Body1.063",
        "Body1.042",
        "Body2",
        "Body2.001",
        "Body1.044",
        "Body1.040",
        "Body1.043",

        "Body1.008",
        "Body1.013",
        "Body1.009",
        "Body1.006",
        "Body1.014",
        "Body1.021",
        "Head Rest.001",
        "Body1.011",

        "Body1.019",
        "Body1.015",
        "Body1.016",
        "Body1.017",
        "Body1.018",

        "Body1.012",
        "Body1.007",
        "Body1.010",
        "Body1.005",
        "chair1.002",
        "chair.002",
        "chair1.003",
      ].map(cleanTargetNameForThreeJS),

      vanColours: [
        "TopSideRoofRims",
        "Object013.001",
        "Roof_down_02.001",
        "doorhandle.001",
        "doorhandle_key.001",
        "doorhandle_key1.001",
        "side_mirror_1.001",
        "side_mirror_2.001",
        "fender_b1.001",
        "hood1.001",
        "front_car.001",
        "Object004.001",
        "back_car.001",
        "back_car1.001",
        "Roof_up_04.001",
        "Roof_down_01.001",
        "Object002.001",
        "standard_blocked_off_window_1",
        "standard_blocked_off_window_2",
      ].map(cleanTargetNameForThreeJS),

      vanWallLining4WayStretchCarpet: [
        "wheelcap_carpet",
        "Roof00.001",
        "frame_s.001",
        "frame_s1.001",
        "fender_s.001",
        "door_s4.001",
        "standard_blocked_off_window_1_internal",
        "standard_blocked_off_window_2_internal",
      ].map(cleanTargetNameForThreeJS),

      flooringRange: ["dashboard.003"].map(cleanTargetNameForThreeJS),

      popTopCanvas: ["Roof_up_02.001"].map(cleanTargetNameForThreeJS),

      blindsCurtains: [
        "big_blinds",
        "big_blinds_1",
        "big_blinds_2",
      ].map(cleanTargetNameForThreeJS),

      // seatingRIBAltairBedFabric: [].map(cleanTargetNameForThreeJS),

      // woodEdgeFinish: [].map(cleanTargetNameForThreeJS),
    },
  },

  ecoExpeditionSWB: {
    name: "ECO Expedition",
    imageUrl: "/media/webgl/conversions/thumbnails/expedition-swb.webp",
    modelUrl: "/media/webgl/eco-expidition-swb-in-a-volkswagen-transporter.glb",
    modelSizeBytes: 5007000,
    conversionRootNodeName: cleanTargetNameForThreeJS(
      "ECO Expedition All In v2"
    ),
    
    defaultMeshVisibility: {
      showMeshes: ["Drifter SWB All In v15"].map(cleanTargetNameForThreeJS),
      hideMeshes: ["Roof_up_04.001", "zipline", "window_s3.001", "window_frame_s.002", "big_blinds_2", "big_blinds_1", "swivel_chair", "KRS Roof Rack v4", "Solar Panel v5", ].map(cleanTargetNameForThreeJS),
    },
    
    // Meshes to toggle in response to enabled extras
    extraMeshes: {
      extrastableUpgrades1: {
        showMeshes: [
          "Cranked Arm Table v3.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments2: {
        showMeshes: [
          "Vamoose Awning Rail v2.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments1: {
        showMeshes: [
          "Thule Wanderway Bike Rack v9",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      "extrastracking&Safety0": {
        showMeshes: [
          "View Dash Cam v1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras7: {
        showMeshes: ["KRS Roof Rack v4"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraschargingUpgrades3: {
        showMeshes: ["Solar Panel v5"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      hasWindowUpgrades: {
        showMeshes: ["window_s3.001", "window_s3.002", "window_frame_s.002", "big_blinds_2", "big_blinds_1"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["standard_blocked_off_window_1", "standard_blocked_off_window_1_internal", "standard_blocked_off_window_2", "standard_blocked_off_window_2_internal",].map(cleanTargetNameForThreeJS),
      },
      extrasseatingUpgrades3: {
        showMeshes: ["swivel_chair"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["chair1.001", "chair1.002"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras5: {
        showMeshes: ["Roof_up_04.001"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["Roof_up_black"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras8: {
        showMeshes: ["zipline"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasadditionalKitchenStorage1: {
        // Top cupboards
        showMeshes: [
          "Right Top Cupboard.001",
          "Mid Top Cupboard.001",
          "Left Top Cupboard.001",
          "L Side.001",
          "R Side.001",
          "Base (1)",
          "Body1.029",
          "Body1.027",
          "Body1.028",
          "Top.002",
          "Mid L.001",
          "Mid R.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: ["Drifter SWB All In v15"].map(cleanTargetNameForThreeJS),
      },
    },

    swatchTargets: {
      splashbacks: ["Surround.001",].map(cleanTargetNameForThreeJS),

      kitchenUnits: [
        'Right Top Cupboard.001',
        'Mid Top Cupboard.001',
        'Left Top Cupboard.001',
        'L Side.001',
        'R Side.001',
        'Base (1)',
        'Body1.029',
        'Body1.027',
        'Body1.028',
        'Top.002',
        'Mid L.001',
        'Mid R.001',
        'Face.002',
        'Face.001',
        'High Door',
        'R Door.001',
        'L Door.001',
        'Low Door.001',
        'Drawer Face',
        'Fridge Left.001',
        'Fridge Right.001',
        'Drawer Top.001',
        'Cubby Side.001',
        'Back .001',
        'Body1.053',
        'Body1.051',
        'Body1.052',
        'UnderChairsFace.003',
        'R Door.002',
        'L Door.002',
        'MainUnitFace.002',
        'Drawer Face.001',
        'UnitBody1.064',
        'Table Rail.001',
        'Back.001',
        'Big Back Door.001',
        'Big Low Door.001',
        'Big Door',
        'R Fridge.001',
        'Table .001',
        'Leg.001',
        'Holder R.001',
        'Holder L.001',
        'LED Back',
        "Body1.061",
        "Body1.060",
        "Body1.059",
        "Body2.003",
        "Body1.062",
        "Cubby L.001",
      ].map(cleanTargetNameForThreeJS),

      worktopFinishes: [
        "Table.001",
        "Worktop.001",
        "Flap.001",
        "Body1.028",
        "Body1.027",
        "Body1.029",
      ].map(cleanTargetNameForThreeJS),

      fridgeColour: ["Fridge.001"].map(cleanTargetNameForThreeJS),
      
      upholstery: [
        "swivel_chair",
        "chair.001",
        "chair1.001",
        "Body1.042",
        "Body1.047",
        "Body4",
        "Body2.001",
        "Body2",
        "Body1.048",
        "Body1.044",
        "Body1.040",
        "Body1.049",
        "Body1.043",
        "Head Rest.001",
        "Body1.056",
        "Body1.057",
        "Body2.002",
        "Body3.002",
        "Body1.055",
        "Body1.058",
        "Body1.054",
        "chair1.002",
        "chair.002",
        "Body3.001",
        "Body3",
        "Body1.045",
        "Body1.041",
        "Body1.046",
        "Body1.039",
      ].map(cleanTargetNameForThreeJS),

      vanColours: [
        "TopSideRoofRims",
        "Object013.001",
        "Roof_down_02.001",
        "doorhandle.001",
        "doorhandle_key.001",
        "doorhandle_key1.001",
        "side_mirror_1.001",
        "side_mirror_2.001",
        "fender_b1.001",
        "hood1.001",
        "front_car.001",
        "Object004.001",
        "back_car.001",
        "back_car1.001",
        "Roof_up_04.001",
        "Roof_down_01.001",
        "Object002.001",
        "standard_blocked_off_window_1",
        "standard_blocked_off_window_2",
      ].map(cleanTargetNameForThreeJS),

      vanWallLining4WayStretchCarpet: [
        "wheelcap_carpet",
        "Roof00.001",
        "frame_s.001",
        "frame_s1.001",
        "fender_s.001",
        "door_s4.001",
        "standard_blocked_off_window_1_internal",
        "standard_blocked_off_window_2_internal",
      ].map(cleanTargetNameForThreeJS),

      flooringRange: ["dashboard.003"].map(cleanTargetNameForThreeJS),

      popTopCanvas: ["Roof_up_02.001"].map(cleanTargetNameForThreeJS),

      blindsCurtains: [
        "big_blinds",
        "big_blinds_1",
        "big_blinds_2",
      ].map(cleanTargetNameForThreeJS),

      // seatingRIBAltairBedFabric: [].map(cleanTargetNameForThreeJS),

      // woodEdgeFinish: [].map(cleanTargetNameForThreeJS),
    },
  },

  ecoDrifterSWB: {
    name: "ECO Drifter",
    imageUrl: "/media/webgl/conversions/thumbnails/drifter-swb.webp",
    modelUrl: "/media/webgl/eco-drifter-2.0-swb-in-a-volkswagen-transporter-NEW.glb",
    modelSizeBytes: 5098000,
    conversionRootNodeName: cleanTargetNameForThreeJS("1. ECO 2.0 Drifter v2"),
    
    defaultMeshVisibility: {
      showMeshes: ["Drifter SWB All In v15"].map(cleanTargetNameForThreeJS),
      hideMeshes: [
      "Roof_up_04.001", 
      "zipline",
      "window_frame_s.002", 
      "big_blinds_2", 
      "big_blinds_1", 
      "swivel_chair", 
      "KRS Roof Rack v4", 
      "Solar Panel v5", 

      "Drawer Handle.005",
      "Drawer Handle (4) (1)",
      "Drawer Handle (4) (1):1",
      "Drawer Face.005",
      "Drawer Face (6)",
      "Drawer Face (6):1",
      "Flat Base Plate.022",
      "Flat Base Plate (1) (1) (1) (2) (2) (1) (1)",
      "Flat Base Plate (1) (1) (1) (2) (2) (1) (1):1",
      "Flat Countersink.022",
      "Flat Countersink (1) (1) (1) (2) (2) (1) (1)",
      "Flat Countersink (1) (1) (1) (2) (2) (1) (1):1",
      "R Door Hinge (2) (1) (1)",
      "R Door Hinge (2) (1) (1):1",
      "Flat Base Plate.023",
      "Flat Base Plate (1) (1) (1) (2) (1) (1) (1) (1)",
      "Flat Base Plate (1) (1) (1) (2) (1) (1) (1) (1):1",
      "Flat Countersink.023",
      "Flat Countersink (1) (1) (1) (2) (1) (1) (1) (1)",
      "Flat Countersink (1) (1) (1) (2) (1) (1) (1) (1):1",
      "R Door Hinge (1) (1) (1) (1)",
      "R Door Hinge (1) (1) (1) (1):1",
      "R Door.008",
      "R Door (3) (1) (1)",
      "R Door (3) (1) (1):1",
      "R Door (1) (1) (1)",
      "R Door (1) (1) (1):1",
      "90° Base Plate.014",
      "90° Base Plate (1) (1) (1) (1)",
      "90° Base Plate (1) (1) (1) (1):1",
      "90° Countersink.014",
      "90° Countersink (1) (1) (1) (1)",
      "90° Countersink (1) (1) (1) (1):1",
      "90° Hinge.014",
      "90° Hinge (1) (1) (1) (1) (1)",
      "90° Hinge (1) (1) (1) (1) (1):1",
      "L Door Hinge (2) (1) (1)",
      "L Door Hinge (2) (1) (1):1",
      "90° Base Plate.015",
      "90° Base Plate (2) (1) (1) (1)",
      "90° Base Plate (2) (1) (1) (1):1",
      "90° Countersink.015",
      "90° Countersink (2) (1) (1) (1)",
      "90° Countersink (2) (1) (1) (1):1",
      "90° Hinge.015",
      "90° Hinge (1) (2) (1) (1) (1)",
      "90° Hinge (1) (2) (1) (1) (1):1",
      "L Door Hinge (1) (1) (1) (1)",
      "L Door Hinge (1) (1) (1) (1):1",
      "L Door.008",
      "L Door (3) (1) (1)",
      "L Door (3) (1) (1):1",
      "L Door (1) (1) (1)",
      "L Door (1) (1) (1):1",
      "L Handle.003",
      "L Handle (1)",
      "L Handle (1):1",
      "R Handle.003",
      "R Handle (1)",
      "R Handle (1):1",
      "Doors  (1) (1)",
      "Doors  (1) (1):1",
      "Oven/Grill Unit Doors (1)",
      "Oven/Grill Unit Doors (1):1",
    ].map(cleanTargetNameForThreeJS),
    },
    
    // Meshes to toggle in response to enabled extras
    extraMeshes: {
      // PassengerSideRear: Fake Window
      extrashasWindowUpgradesPassengerSideRearWindow0: {
        showMeshes: [
          "window_s3.002",
          "Passenger-side rear window blocked",
          "Passenger-side rear window blocked Internal",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [
          "big_blinds_2",
        ].map(cleanTargetNameForThreeJS),
      },

      // PassengerSideRear: Fixed
      extrashasWindowUpgradesPassengerSideRearWindow1: {
        showMeshes: [
          "window_s3.002",
          "big_blinds_2",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [
        ].map(cleanTargetNameForThreeJS),
      },

      // PassengerSideRear: Opener
      extrashasWindowUpgradesPassengerSideRearWindow2: {
        showMeshes: [
          "window_s3.002",
          "big_blinds_2",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [
        ].map(cleanTargetNameForThreeJS),
      },

      // PassengerSideRear: Panel
      extrashasWindowUpgradesPassengerSideRearWindow3: {
        showMeshes: [
          "Passenger-side rear window blocked",
          "Passenger-side rear window blocked Internal",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [
          "big_blinds_2",
        ].map(cleanTargetNameForThreeJS),
      },

      // DriverSideRear: Fake Window
      extrashasWindowUpgradesDriverSideRearWindow0: {
        showMeshes: [
          "window_s3.001",
          "Driver-side rear window blocked",
          "Driver-side rear window blocked Internal",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [
          "big_blinds_1",
        ].map(cleanTargetNameForThreeJS),
      },

      // DriverSideRear: Fixed
      extrashasWindowUpgradesDriverSideRearWindow1: {
        showMeshes: [
          "window_s3.001",
          "window_frame_s.002",
          "big_blinds_1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [
        ].map(cleanTargetNameForThreeJS),
      },

      // DriverSideRear: Opener
      extrashasWindowUpgradesDriverSideRearWindow2: {
        showMeshes: [
          "window_s3.001",
          "window_frame_s.002",
          "big_blinds_1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [
        ].map(cleanTargetNameForThreeJS),
      },

      // DriverSideRear: Panel
      extrashasWindowUpgradesDriverSideRearWindow3: {
        showMeshes: [
          "Driver-side rear window blocked",
          "Driver-side rear window blocked Internal",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [
          "big_blinds_1",
        ].map(cleanTargetNameForThreeJS),
      },

      extrastableUpgrades1: {
        showMeshes: [
          "Cranked Arm Table v3.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments1: {
        showMeshes: [
          "Vamoose Awning Rail v2.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments2: {
        showMeshes: [
          "Thule Wanderway Bike Rack v9",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      "extrastracking&Safety0": {
        showMeshes: [
          "View Dash Cam v1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras7: {
        showMeshes: ["KRS Roof Rack v4"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraschargingUpgrades3: {
        showMeshes: ["Solar Panel v5"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasseatingUpgrades3: {
        showMeshes: ["swivel_chair"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["chair1.001", "chair1.002"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras5: {
        showMeshes: ["Roof_up_04.001"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["Roof_up_black"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras8: {
        showMeshes: ["zipline"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },

      extraschargingUpgrades3: {
        showMeshes: [
          "Main Panel.001",
          "Main Panel",
          "Main Panel:1",
          "Right Switch.001",
          "Right Switch",
          "Right Switch:1",
          "Left Switch.001",
          "Left Switch",
          "Left Switch:1",
          "Left Socket Top.001",
          "Left Socket Top",
          "Left Socket Top:1",
          "Left Socket Bottom.001",
          "Left Socket Bottom",
          "Left Socket Bottom:1",
          "Right Socket Bottom.001",
          "Right Socket Bottom",
          "Right Socket Bottom:1",
          "Right Socket Top.001",
          "Right Socket Top",
          "Right Socket Top:1",
          "Plug Face.001",
          "Plug Face",
          "Plug Face:1",
          "Plug Sockets",
          "Plug Sockets:1",
          "Savvy Screen.001",
          "Savvy Screen",
          "Savvy Screen:1",
          "Savvy Setup - Standard",
          "Savvy Setup - Standard:1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [
          "Body1.003",
          "Plugs Inner ",
          "Plugs Inner :1",
          "Plugs Surround.001",
          "Plugs Surround",
          "Plugs Surround:1",
          "Plug sockets",
          "Plug sockets:1",
          "Body1.004",
          "Light Surround",
          "Light Surround:1",
          "Body1.005",
          "Light Inner ",
          "Light Inner :1",
          "Body1.006",
          "Light Switch ",
          "Light Switch :1",
          "Light Switch",
          "Light Switch:1",
          "Plugs - Standard",
          "Plugs - Standard:1",
          "Body1.007",
          "Plugs Inner  (1)",
          "Plugs Inner  (1):1",
          "Plugs Surround.002",
          "Plugs Surround (1)",
          "Plugs Surround (1):1",
          "Plug sockets (1)",
          "Plug sockets (1):1",
          "Body1.008",
          "Light Surround (1)",
          "Light Surround (1):1",
          "Body1.009",
          "Light Inner  (1)",
          "Light Inner  (1):1",
          "Body1.010",
          "Light Switch  (1)",
          "Light Switch  (1):1",
          "Light Switch (1)",
          "Light Switch (1):1",
          "Propex Control.001",
          "Propex Face (1) (1)",
          "Propex Face (1) (1):1",
          "Screen.003",
          "Screen (1) (2)",
          "Screen (1) (2):1",
          "Red Light.001",
          "Red Light (1) (1)",
          "Red Light (1) (1):1",
          "Green Light.001",
          "Green Light (1) (1)",
          "Green Light (1) (1):1",
          "Up Button.001",
          "Up Button (1) (1)",
          "Up Button (1) (1):1",
          "Left Button.001",
          "Left Button (1) (1)",
          "Left Button (1) (1):1",
          "R Light.002",
          "R Light (1) (2)",
          "R Light (1) (2):1",
          "Right Button.001",
          "Right Button (1) (1)",
          "Right Button (1) (1):1",
          "Down Button.001",
          "Down Button (1) (1)",
          "Down Button (1) (1):1",
          "Propex Control (1) (1)",
          "Propex Control (1) (1):1",
          "Tank.002",
          "Tank (2)",
          "Tank (2):1",
          "L Holder.002",
          "L Holder (2)",
          "L Holder (2):1",
          "R Holder.002",
          "R Holder (2)",
          "R Holder (2):1",
          "1st Washer.002",
          "1st Washer (2)",
          "1st Washer (2):1",
          "2nd Washer.002",
          "2nd Washer (2)",
          "2nd Washer (2):1",
          "2nd Bolt.002",
          "2nd Bolt (2)",
          "2nd Bolt (2):1",
          "Switch Fix.002",
          "Switch Fix (2)",
          "Switch Fix (2):1",
          "3rd Washer.002",
          "3rd Washer (2)",
          "3rd Washer (2):1",
          "3rd Bolt.002",
          "3rd Bolt (2)",
          "3rd Bolt (2):1",
          "1st Bolt.002",
          "1st Bolt (2)",
          "1st Bolt (2):1",
          "4th Washer.002",
          "4th Washer (2)",
          "4th Washer (2):1",
          "Switch.005",
          "Switch (3)",
          "Switch (3):1",
          "LPG Fill.001",
          "BBQ Point (2)",
          "BBQ Point (2):1",
          "Black Cap.002",
          "Black Cap (2)",
          "Black Cap (2):1",
          "Metal Fixing Plate.002",
          "Metal Fixing Plate (2)",
          "Metal Fixing Plate (2):1",
          "BBQ & Filling Point (2)",
          "BBQ & Filling Point (2):1",
          "Rubber Pipe.002",
          "Rubber Pipe (2)",
          "Rubber Pipe (2):1",
          "Hose Pipe.002",
          "Hose Pipe (2)",
          "Hose Pipe (2):1",
          "Connection.002",
          "Connection (2)",
          "Connection (2):1",
          "Underslung Tank (1)",
          "Underslung Tank (1):1",
          "Out Pipe.003",
          "Out Pipe (2)",
          "Out Pipe (2):1",
          "In Pipe.002",
          "In Pipe (1)",
          "In Pipe (1):1",
          "Propex Heater.002",
          "Propex Heater (1)",
          "Propex Heater (1):1",
          "Heater Side.003",
          "Heater Side (2)",
          "Heater Side (2):1",
          "Heater Top.003",
          "Heater Top (2)",
          "Heater Top (2):1",
          "Heater Face.003",
          "Heater Face (2)",
          "Heater Face (2):1",
          "Seat Back.002",
          "Seat Back (1) (2)",
          "Seat Back (1) (2):1",
          "Front Cap.006",
          "Front Cap (3)",
          "Front Cap (3):1",
          "Heater Box (2)",
          "Heater Box (2):1",
          "Plugs - Propex",
          "Plugs - Propex:1",
          "Body1.011",
          "Plugs Inner",
          "Plugs Inner:1",
          "Plugs Surround.003",
          "Plugs Surround (2)",
          "Plugs Surround (2):1",
          "Body1.012",
          "Light Switch  (2)",
          "Light Switch  (2):1",
          "Body1.013",
          "Light Inner  (2)",
          "Light Inner  (2):1",
          "Body1.014",
          "Light Surround (2)",
          "Light Surround (2):1",
          "Light Switch (2)",
          "Light Switch (2):1",
          "Plug sockets ",
          "Plug sockets :1",
          "Outer.005",
          "Outer (2)",
          "Outer (2):1",
          "Turn Control.002",
          "Turn Control (1)",
          "Turn Control (1):1",
          "L Button.002",
          "L Button (1)",
          "L Button (1):1",
          "R Button.002",
          "R Button (1)",
          "R Button (1):1",
          "L Light.002",
          "L Light (1)",
          "L Light (1):1",
          "R Light.003",
          "R Light (1) (1) (1)",
          "R Light (1) (1) (1):1",
          "Screen.004",
          "Screen (1) (1) (1)",
          "Screen (1) (1) (1):1",
          "Main.002",
          "Main (1) (1)",
          "Main (1) (1):1",
          "Planal Control (1)",
          "Planal Control (1):1",
          "Out Pipe.004",
          "Out Pipe (1) (1)",
          "Out Pipe (1) (1):1",
          "Planar Heater.002",
          "Main Body (1)",
          "Main Body (1):1",
          "Small Pipe.002",
          "Small Pipe (1)",
          "Small Pipe (1):1",
          "Screw (3).002",
          "Screw (3) (1)",
          "Screw (3) (1):1",
          "Big Pipe 1.002",
          "Big Pipe 1 (1)",
          "Big Pipe 1 (1):1",
          "Screw.002",
          "Screw (4)",
          "Screw (4):1",
          "Screw (2).002",
          "Screw (2) (1)",
          "Screw (2) (1):1",
          "Big Pipe 2.002",
          "Big Pipe 2 (1)",
          "Big Pipe 2 (1):1",
          "Screw (1).002",
          "Screw (1) (1)",
          "Screw (1) (1):1",
          "Fixing Plate .002",
          "Base Plate (1).002",
          "Base Plate (1):1.002",
          "Fixing Plate  (1)",
          "Fixing Plate  (1):1",
          "Back Cap.005",
          "Back Cap (1).001",
          "Back Cap (1):1.001",
          "Planar Heater (1)",
          "Planar Heater (1):1",
          "Heater Face.004",
          "Heater Face (1) (1)",
          "Heater Face (1) (1):1",
          "Heater Side.004",
          "Heater Side (1) (1)",
          "Heater Side (1) (1):1",
          "Heater Top.004",
          "Heater Top (1) (1)",
          "Heater Top (1) (1):1",
          "Seat Back.003",
          "Seat Back (1) (1) (1)",
          "Seat Back (1) (1) (1):1",
          "Front Cap.007",
          "Front Cap (1) (1)",
          "Front Cap (1) (1):1",
          "Heater Box (1) (1)",
          "Heater Box (1) (1):1",
          "Plugs - Planar",
          "Plugs - Planar:1",
          "Plug Setups",
        ].map(cleanTargetNameForThreeJS),
        conditional: {
          // Propex
          extrasheatingUpgrades1: {
            showMeshes: [
              "Screen.001",
              "Screen (1)",
              "Screen (1):1",
              "Red Light",
              "Red Light (1)",
              "Red Light (1):1",
              "Green Light",
              "Green Light (1)",
              "Green Light (1):1",
              "Up Button",
              "Up Button (1)",
              "Up Button (1):1",
              "Left Button",
              "Left Button (1)",
              "Left Button (1):1",
              "R Light",
              "R Light (1)",
              "R Light (1):1",
              "Right Button",
              "Right Button (1)",
              "Right Button (1):1",
              "Down Button",
              "Down Button (1)",
              "Down Button (1):1",
              "Propex Control",
              "Propex Face (1)",
              "Propex Face (1):1",
              "Propex Control (1)",
              "Propex Control (1):1",
              "Right Switch.002",
              "Right Switch (1)",
              "Right Switch (1):1",
              "Left Switch.002",
              "Left Switch (1)",
              "Left Switch (1):1",
              "Left Socket Top.002",
              "Left Socket Top (1)",
              "Left Socket Top (1):1",
              "Left Socket Bottom.002",
              "Left Socket Bottom (1)",
              "Left Socket Bottom (1):1",
              "Right Socket Bottom.002",
              "Right Socket Bottom (1)",
              "Right Socket Bottom (1):1",
              "Right Socket Top.002",
              "Right Socket Top (1)",
              "Right Socket Top (1):1",
              "Plug Face.002",
              "Plug Face (1)",
              "Plug Face (1):1",
              "Plug Sockets (1)",
              "Plug Sockets (1):1",
              "Savvy Screen.002",
              "Savvy Screen (1)",
              "Savvy Screen (1):1",
              "Main Panel.002",
              "Main Panel (1)",
              "Main Panel (1):1",
              "Tank.001",
              "Tank",
              "Tank:1",
              "L Holder.001",
              "L Holder",
              "L Holder:1",
              "R Holder.001",
              "R Holder",
              "R Holder:1",
              "1st Washer.001",
              "1st Washer",
              "1st Washer:1",
              "2nd Washer.001",
              "2nd Washer",
              "2nd Washer:1",
              "2nd Bolt.001",
              "2nd Bolt",
              "2nd Bolt:1",
              "Switch Fix.001",
              "Switch Fix",
              "Switch Fix:1",
              "3rd Washer.001",
              "3rd Washer",
              "3rd Washer:1",
              "3rd Bolt.001",
              "3rd Bolt",
              "3rd Bolt:1",
              "1st Bolt.001",
              "1st Bolt",
              "1st Bolt:1",
              "4th Washer.001",
              "4th Washer",
              "4th Washer:1",
              "Switch.004",
              "Switch.003",
              "Switch:1",
              "LPG Fill",
              "BBQ Point",
              "BBQ Point:1",
              "Black Cap.001",
              "Black Cap",
              "Black Cap:1",
              "Metal Fixing Plate.001",
              "Metal Fixing Plate",
              "Metal Fixing Plate:1",
              "BBQ & Filling Point",
              "BBQ & Filling Point:1",
              "Rubber Pipe.001",
              "Rubber Pipe",
              "Rubber Pipe:1",
              "Connection.001",
              "Connection",
              "Connection:1",
              "Hose Pipe.001",
              "Hose Pipe",
              "Hose Pipe:1",
              "Underslung Tank",
              "Underslung Tank:1",
              "Out Pipe.001",
              "Out Pipe",
              "Out Pipe:1",
              "In Pipe.001",
              "In Pipe",
              "In Pipe:1",
              "Propex Heater.001",
              "Propex Heater",
              "Propex Heater:1",
              "Heater Face.001",
              "Heater Face",
              "Heater Face:1",
              "Heater Side.001",
              "Heater Side",
              "Heater Side:1",
              "Heater Top.001",
              "Heater Top",
              "Heater Top:1",
              "Seat Back",
              "Seat Back (1)",
              "Seat Back (1):1",
              "Front Cap.004",
              "Front Cap.003",
              "Front Cap:1.001",
              "Heater Box",
              "Heater Box:1",
              "Savvy Setup - Propex",
              "Savvy Setup - Propex:1",
            ].map(cleanTargetNameForThreeJS),
            hideMeshes: [
              "Body1.007",
              "Plugs Inner  (1)",
              "Plugs Inner  (1):1",
              "Plugs Surround.002",
              "Plugs Surround (1)",
              "Plugs Surround (1):1",
              "Plug sockets (1)",
              "Plug sockets (1):1",
              "Body1.008",
              "Light Surround (1)",
              "Light Surround (1):1",
              "Body1.009",
              "Light Inner  (1)",
              "Light Inner  (1):1",
              "Body1.010",
              "Light Switch  (1)",
              "Light Switch  (1):1",
              "Light Switch (1)",
              "Light Switch (1):1",
              "Propex Control.001",
              "Propex Face (1) (1)",
              "Propex Face (1) (1):1",
              "Screen.003",
              "Screen (1) (2)",
              "Screen (1) (2):1",
              "Red Light.001",
              "Red Light (1) (1)",
              "Red Light (1) (1):1",
              "Green Light.001",
              "Green Light (1) (1)",
              "Green Light (1) (1):1",
              "Up Button.001",
              "Up Button (1) (1)",
              "Up Button (1) (1):1",
              "Left Button.001",
              "Left Button (1) (1)",
              "Left Button (1) (1):1",
              "R Light.002",
              "R Light (1) (2)",
              "R Light (1) (2):1",
              "Right Button.001",
              "Right Button (1) (1)",
              "Right Button (1) (1):1",
              "Down Button.001",
              "Down Button (1) (1)",
              "Down Button (1) (1):1",
              "Propex Control (1) (1)",
              "Propex Control (1) (1):1",
              "Tank.002",
              "Tank (2)",
              "Tank (2):1",
              "L Holder.002",
              "L Holder (2)",
              "L Holder (2):1",
              "R Holder.002",
              "R Holder (2)",
              "R Holder (2):1",
              "1st Washer.002",
              "1st Washer (2)",
              "1st Washer (2):1",
              "2nd Washer.002",
              "2nd Washer (2)",
              "2nd Washer (2):1",
              "2nd Bolt.002",
              "2nd Bolt (2)",
              "2nd Bolt (2):1",
              "Switch Fix.002",
              "Switch Fix (2)",
              "Switch Fix (2):1",
              "3rd Washer.002",
              "3rd Washer (2)",
              "3rd Washer (2):1",
              "3rd Bolt.002",
              "3rd Bolt (2)",
              "3rd Bolt (2):1",
              "1st Bolt.002",
              "1st Bolt (2)",
              "1st Bolt (2):1",
              "4th Washer.002",
              "4th Washer (2)",
              "4th Washer (2):1",
              "Switch.005",
              "Switch (3)",
              "Switch (3):1",
              "LPG Fill.001",
              "BBQ Point (2)",
              "BBQ Point (2):1",
              "Black Cap.002",
              "Black Cap (2)",
              "Black Cap (2):1",
              "Metal Fixing Plate.002",
              "Metal Fixing Plate (2)",
              "Metal Fixing Plate (2):1",
              "BBQ & Filling Point (2)",
              "BBQ & Filling Point (2):1",
              "Rubber Pipe.002",
              "Rubber Pipe (2)",
              "Rubber Pipe (2):1",
              "Hose Pipe.002",
              "Hose Pipe (2)",
              "Hose Pipe (2):1",
              "Connection.002",
              "Connection (2)",
              "Connection (2):1",
              "Underslung Tank (1)",
              "Underslung Tank (1):1",
              "Out Pipe.003",
              "Out Pipe (2)",
              "Out Pipe (2):1",
              "In Pipe.002",
              "In Pipe (1)",
              "In Pipe (1):1",
              "Propex Heater.002",
              "Propex Heater (1)",
              "Propex Heater (1):1",
              "Heater Side.003",
              "Heater Side (2)",
              "Heater Side (2):1",
              "Heater Top.003",
              "Heater Top (2)",
              "Heater Top (2):1",
              "Heater Face.003",
              "Heater Face (2)",
              "Heater Face (2):1",
              "Seat Back.002",
              "Seat Back (1) (2)",
              "Seat Back (1) (2):1",
              "Front Cap.006",
              "Front Cap (3)",
              "Front Cap (3):1",
              "Heater Box (2)",
              "Heater Box (2):1",
              "Plugs - Propex",
              "Plugs - Propex:1",
              "Body1.011",
              "Plugs Inner",
              "Plugs Inner:1",
              "Plugs Surround.003",
              "Plugs Surround (2)",
              "Plugs Surround (2):1",
              "Body1.012",
              "Light Switch  (2)",
              "Light Switch  (2):1",
              "Body1.013",
              "Light Inner  (2)",
              "Light Inner  (2):1",
              "Body1.014",
              "Light Surround (2)",
              "Light Surround (2):1",
              "Light Switch (2)",
              "Light Switch (2):1",
              "Plug sockets ",
              "Plug sockets :1",
              "Outer.005",
              "Outer (2)",
              "Outer (2):1",
              "Turn Control.002",
              "Turn Control (1)",
              "Turn Control (1):1",
              "L Button.002",
              "L Button (1)",
              "L Button (1):1",
              "R Button.002",
              "R Button (1)",
              "R Button (1):1",
              "L Light.002",
              "L Light (1)",
              "L Light (1):1",
              "R Light.003",
              "R Light (1) (1) (1)",
              "R Light (1) (1) (1):1",
              "Screen.004",
              "Screen (1) (1) (1)",
              "Screen (1) (1) (1):1",
              "Main.002",
              "Main (1) (1)",
              "Main (1) (1):1",
              "Planal Control (1)",
              "Planal Control (1):1",
              "Out Pipe.004",
              "Out Pipe (1) (1)",
              "Out Pipe (1) (1):1",
              "Planar Heater.002",
              "Main Body (1)",
              "Main Body (1):1",
              "Small Pipe.002",
              "Small Pipe (1)",
              "Small Pipe (1):1",
              "Screw (3).002",
              "Screw (3) (1)",
              "Screw (3) (1):1",
              "Big Pipe 1.002",
              "Big Pipe 1 (1)",
              "Big Pipe 1 (1):1",
              "Screw.002",
              "Screw (4)",
              "Screw (4):1",
              "Screw (2).002",
              "Screw (2) (1)",
              "Screw (2) (1):1",
              "Big Pipe 2.002",
              "Big Pipe 2 (1)",
              "Big Pipe 2 (1):1",
              "Screw (1).002",
              "Screw (1) (1)",
              "Screw (1) (1):1",
              "Fixing Plate .002",
              "Base Plate (1).002",
              "Base Plate (1):1.002",
              "Fixing Plate  (1)",
              "Fixing Plate  (1):1",
              "Back Cap.005",
              "Back Cap (1).001",
              "Back Cap (1):1.001",
              "Planar Heater (1)",
              "Planar Heater (1):1",
              "Heater Face.004",
              "Heater Face (1) (1)",
              "Heater Face (1) (1):1",
              "Heater Side.004",
              "Heater Side (1) (1)",
              "Heater Side (1) (1):1",
              "Heater Top.004",
              "Heater Top (1) (1)",
              "Heater Top (1) (1):1",
              "Seat Back.003",
              "Seat Back (1) (1) (1)",
              "Seat Back (1) (1) (1):1",
              "Front Cap.007",
              "Front Cap (1) (1)",
              "Front Cap (1) (1):1",
              "Heater Box (1) (1)",
              "Heater Box (1) (1):1",
              "Plugs - Planar",
              "Plugs - Planar:1",
              "Plug Setups",

              "Body1.003",
              "Plugs Inner ",
              "Plugs Inner :1",
              "Plugs Surround.001",
              "Plugs Surround",
              "Plugs Surround:1",
              "Plug sockets",
              "Plug sockets:1",
              "Body1.004",
              "Light Surround",
              "Light Surround:1",
              "Body1.005",
              "Light Inner ",
              "Light Inner :1",
              "Body1.006",
              "Light Switch ",
              "Light Switch :1",
              "Light Switch",
              "Light Switch:1",
              "Plugs - Standard",
              "Plugs - Standard:1",
              "Body1.007",
              "Plugs Inner  (1)",
              "Plugs Inner  (1):1",
              "Plugs Surround.002",
              "Plugs Surround (1)",
              "Plugs Surround (1):1",
              "Plug sockets (1)",
              "Plug sockets (1):1",
              "Body1.008",
              "Light Surround (1)",
              "Light Surround (1):1",
              "Body1.009",
              "Light Inner  (1)",
              "Light Inner  (1):1",
              "Body1.010",
              "Light Switch  (1)",
              "Light Switch  (1):1",
              "Light Switch (1)",
              "Light Switch (1):1",
              "Propex Control.001",
              "Propex Face (1) (1)",
              "Propex Face (1) (1):1",
              "Screen.003",
              "Screen (1) (2)",
              "Screen (1) (2):1",
              "Red Light.001",
              "Red Light (1) (1)",
              "Red Light (1) (1):1",
              "Green Light.001",
              "Green Light (1) (1)",
              "Green Light (1) (1):1",
              "Up Button.001",
              "Up Button (1) (1)",
              "Up Button (1) (1):1",
              "Left Button.001",
              "Left Button (1) (1)",
              "Left Button (1) (1):1",
              "R Light.002",
              "R Light (1) (2)",
              "R Light (1) (2):1",
              "Right Button.001",
              "Right Button (1) (1)",
              "Right Button (1) (1):1",
              "Down Button.001",
              "Down Button (1) (1)",
              "Down Button (1) (1):1",
              "Propex Control (1) (1)",
              "Propex Control (1) (1):1",
              "Tank.002",
              "Tank (2)",
              "Tank (2):1",
              "L Holder.002",
              "L Holder (2)",
              "L Holder (2):1",
              "R Holder.002",
              "R Holder (2)",
              "R Holder (2):1",
              "1st Washer.002",
              "1st Washer (2)",
              "1st Washer (2):1",
              "2nd Washer.002",
              "2nd Washer (2)",
              "2nd Washer (2):1",
              "2nd Bolt.002",
              "2nd Bolt (2)",
              "2nd Bolt (2):1",
              "Switch Fix.002",
              "Switch Fix (2)",
              "Switch Fix (2):1",
              "3rd Washer.002",
              "3rd Washer (2)",
              "3rd Washer (2):1",
              "3rd Bolt.002",
              "3rd Bolt (2)",
              "3rd Bolt (2):1",
              "1st Bolt.002",
              "1st Bolt (2)",
              "1st Bolt (2):1",
              "4th Washer.002",
              "4th Washer (2)",
              "4th Washer (2):1",
              "Switch.005",
              "Switch (3)",
              "Switch (3):1",
              "LPG Fill.001",
              "BBQ Point (2)",
              "BBQ Point (2):1",
              "Black Cap.002",
              "Black Cap (2)",
              "Black Cap (2):1",
              "Metal Fixing Plate.002",
              "Metal Fixing Plate (2)",
              "Metal Fixing Plate (2):1",
              "BBQ & Filling Point (2)",
              "BBQ & Filling Point (2):1",
              "Rubber Pipe.002",
              "Rubber Pipe (2)",
              "Rubber Pipe (2):1",
              "Hose Pipe.002",
              "Hose Pipe (2)",
              "Hose Pipe (2):1",
              "Connection.002",
              "Connection (2)",
              "Connection (2):1",
              "Underslung Tank (1)",
              "Underslung Tank (1):1",
              "Out Pipe.003",
              "Out Pipe (2)",
              "Out Pipe (2):1",
              "In Pipe.002",
              "In Pipe (1)",
              "In Pipe (1):1",
              "Propex Heater.002",
              "Propex Heater (1)",
              "Propex Heater (1):1",
              "Heater Side.003",
              "Heater Side (2)",
              "Heater Side (2):1",
              "Heater Top.003",
              "Heater Top (2)",
              "Heater Top (2):1",
              "Heater Face.003",
              "Heater Face (2)",
              "Heater Face (2):1",
              "Seat Back.002",
              "Seat Back (1) (2)",
              "Seat Back (1) (2):1",
              "Front Cap.006",
              "Front Cap (3)",
              "Front Cap (3):1",
              "Heater Box (2)",
              "Heater Box (2):1",
              "Plugs - Propex",
              "Plugs - Propex:1",
              "Body1.011",
              "Plugs Inner",
              "Plugs Inner:1",
              "Plugs Surround.003",
              "Plugs Surround (2)",
              "Plugs Surround (2):1",
              "Body1.012",
              "Light Switch  (2)",
              "Light Switch  (2):1",
              "Body1.013",
              "Light Inner  (2)",
              "Light Inner  (2):1",
              "Body1.014",
              "Light Surround (2)",
              "Light Surround (2):1",
              "Light Switch (2)",
              "Light Switch (2):1",
              "Plug sockets ",
              "Plug sockets :1",
              "Outer.005",
              "Outer (2)",
              "Outer (2):1",
              "Turn Control.002",
              "Turn Control (1)",
              "Turn Control (1):1",
              "L Button.002",
              "L Button (1)",
              "L Button (1):1",
              "R Button.002",
              "R Button (1)",
              "R Button (1):1",
              "L Light.002",
              "L Light (1)",
              "L Light (1):1",
              "R Light.003",
              "R Light (1) (1) (1)",
              "R Light (1) (1) (1):1",
              "Screen.004",
              "Screen (1) (1) (1)",
              "Screen (1) (1) (1):1",
              "Main.002",
              "Main (1) (1)",
              "Main (1) (1):1",
              "Planal Control (1)",
              "Planal Control (1):1",
              "Out Pipe.004",
              "Out Pipe (1) (1)",
              "Out Pipe (1) (1):1",
              "Planar Heater.002",
              "Main Body (1)",
              "Main Body (1):1",
              "Small Pipe.002",
              "Small Pipe (1)",
              "Small Pipe (1):1",
              "Screw (3).002",
              "Screw (3) (1)",
              "Screw (3) (1):1",
              "Big Pipe 1.002",
              "Big Pipe 1 (1)",
              "Big Pipe 1 (1):1",
              "Screw.002",
              "Screw (4)",
              "Screw (4):1",
              "Screw (2).002",
              "Screw (2) (1)",
              "Screw (2) (1):1",
              "Big Pipe 2.002",
              "Big Pipe 2 (1)",
              "Big Pipe 2 (1):1",
              "Screw (1).002",
              "Screw (1) (1)",
              "Screw (1) (1):1",
              "Fixing Plate .002",
              "Base Plate (1).002",
              "Base Plate (1):1.002",
              "Fixing Plate  (1)",
              "Fixing Plate  (1):1",
              "Back Cap.005",
              "Back Cap (1).001",
              "Back Cap (1):1.001",
              "Planar Heater (1)",
              "Planar Heater (1):1",
              "Heater Face.004",
              "Heater Face (1) (1)",
              "Heater Face (1) (1):1",
              "Heater Side.004",
              "Heater Side (1) (1)",
              "Heater Side (1) (1):1",
              "Heater Top.004",
              "Heater Top (1) (1)",
              "Heater Top (1) (1):1",
              "Seat Back.003",
              "Seat Back (1) (1) (1)",
              "Seat Back (1) (1) (1):1",
              "Front Cap.007",
              "Front Cap (1) (1)",
              "Front Cap (1) (1):1",
              "Heater Box (1) (1)",
              "Heater Box (1) (1):1",
              "Plugs - Planar",
              "Plugs - Planar:1",
              "Plug Setups",
            ].map(cleanTargetNameForThreeJS),
          },

          // Planar
          extrasheatingUpgrades2: {
            showMeshes: [
              "Main Panel.003",
              "Main Panel (3)",
              "Main Panel (3):1",
              "Right Switch.003",
              "Right Switch (2)",
              "Right Switch (2):1",
              "Left Switch.003",
              "Left Switch (2)",
              "Left Switch (2):1",
              "Left Socket Top.003",
              "Left Socket Top (2)",
              "Left Socket Top (2):1",
              "Left Socket Bottom.003",
              "Left Socket Bottom (2)",
              "Left Socket Bottom (2):1",
              "Right Socket Bottom.003",
              "Right Socket Bottom (2)",
              "Right Socket Bottom (2):1",
              "Right Socket Top.003",
              "Right Socket Top (2)",
              "Right Socket Top (2):1",
              "Plug Face.003",
              "Plug Face (2)",
              "Plug Face (2):1",
              "Plug Sockets (2)",
              "Plug Sockets (2):1",
              "Savvy Screen.003",
              "Savvy Screen (2)",
              "Savvy Screen (2):1",
              "Outer.004",
              "Outer.003",
              "Outer:1",
              "Turn Control.001",
              "Turn Control",
              "Turn Control:1",
              "L Button.001",
              "L Button",
              "L Button:1",
              "R Button.001",
              "R Button",
              "R Button:1",
              "L Light.001",
              "L Light",
              "L Light:1",
              "R Light.001",
              "R Light (1) (1)",
              "R Light (1) (1):1",
              "Screen.002",
              "Screen (1) (1)",
              "Screen (1) (1):1",
              "Main.001",
              "Main (1)",
              "Main (1):1",
              "Planal Control",
              "Planal Control:1",
              "Small Pipe.001",
              "Small Pipe",
              "Small Pipe:1",
              "Screw (3).001",
              "Screw (3)",
              "Screw (3):1",
              "Big Pipe 1.001",
              "Big Pipe 1",
              "Big Pipe 1:1",
              "Screw.001",
              "Screw",
              "Screw:1",
              "Screw (2).001",
              "Screw (2)",
              "Screw (2):1",
              "Big Pipe 2.001",
              "Big Pipe 2",
              "Big Pipe 2:1",
              "Screw (1).001",
              "Screw (1)",
              "Screw (1):1",
              "Fixing Plate .001",
              "Base Plate.004",
              "Base Plate:1.002",
              "Fixing Plate ",
              "Fixing Plate :1",
              "Back Cap.004",
              "Back Cap.003",
              "Back Cap:1.001",
              "Planar Heater.001",
              "Main Body",
              "Main Body:1",
              "Out Pipe.002",
              "Out Pipe (1)",
              "Out Pipe (1):1",
              "Planar Heater",
              "Planar Heater:1",
              "Heater Side.002",
              "Heater Side (1)",
              "Heater Side (1):1",
              "Heater Top.002",
              "Heater Top (1)",
              "Heater Top (1):1",
              "Heater Face.002",
              "Heater Face (1)",
              "Heater Face (1):1",
              "Seat Back.001",
              "Seat Back (1) (1)",
              "Seat Back (1) (1):1",
              "Front Cap.005",
              "Front Cap (1).001",
              "Front Cap (1):1.001",
              "Heater Box (1)",
              "Heater Box (1):1",
              "Savvy Setup - Planar",
              "Savvy Setup - Planar:1",
            ].map(cleanTargetNameForThreeJS),
            hideMeshes: [
              "Body1.007",
              "Plugs Inner  (1)",
              "Plugs Inner  (1):1",
              "Plugs Surround.002",
              "Plugs Surround (1)",
              "Plugs Surround (1):1",
              "Plug sockets (1)",
              "Plug sockets (1):1",
              "Body1.008",
              "Light Surround (1)",
              "Light Surround (1):1",
              "Body1.009",
              "Light Inner  (1)",
              "Light Inner  (1):1",
              "Body1.010",
              "Light Switch  (1)",
              "Light Switch  (1):1",
              "Light Switch (1)",
              "Light Switch (1):1",
              "Propex Control.001",
              "Propex Face (1) (1)",
              "Propex Face (1) (1):1",
              "Screen.003",
              "Screen (1) (2)",
              "Screen (1) (2):1",
              "Red Light.001",
              "Red Light (1) (1)",
              "Red Light (1) (1):1",
              "Green Light.001",
              "Green Light (1) (1)",
              "Green Light (1) (1):1",
              "Up Button.001",
              "Up Button (1) (1)",
              "Up Button (1) (1):1",
              "Left Button.001",
              "Left Button (1) (1)",
              "Left Button (1) (1):1",
              "R Light.002",
              "R Light (1) (2)",
              "R Light (1) (2):1",
              "Right Button.001",
              "Right Button (1) (1)",
              "Right Button (1) (1):1",
              "Down Button.001",
              "Down Button (1) (1)",
              "Down Button (1) (1):1",
              "Propex Control (1) (1)",
              "Propex Control (1) (1):1",
              "Tank.002",
              "Tank (2)",
              "Tank (2):1",
              "L Holder.002",
              "L Holder (2)",
              "L Holder (2):1",
              "R Holder.002",
              "R Holder (2)",
              "R Holder (2):1",
              "1st Washer.002",
              "1st Washer (2)",
              "1st Washer (2):1",
              "2nd Washer.002",
              "2nd Washer (2)",
              "2nd Washer (2):1",
              "2nd Bolt.002",
              "2nd Bolt (2)",
              "2nd Bolt (2):1",
              "Switch Fix.002",
              "Switch Fix (2)",
              "Switch Fix (2):1",
              "3rd Washer.002",
              "3rd Washer (2)",
              "3rd Washer (2):1",
              "3rd Bolt.002",
              "3rd Bolt (2)",
              "3rd Bolt (2):1",
              "1st Bolt.002",
              "1st Bolt (2)",
              "1st Bolt (2):1",
              "4th Washer.002",
              "4th Washer (2)",
              "4th Washer (2):1",
              "Switch.005",
              "Switch (3)",
              "Switch (3):1",
              "LPG Fill.001",
              "BBQ Point (2)",
              "BBQ Point (2):1",
              "Black Cap.002",
              "Black Cap (2)",
              "Black Cap (2):1",
              "Metal Fixing Plate.002",
              "Metal Fixing Plate (2)",
              "Metal Fixing Plate (2):1",
              "BBQ & Filling Point (2)",
              "BBQ & Filling Point (2):1",
              "Rubber Pipe.002",
              "Rubber Pipe (2)",
              "Rubber Pipe (2):1",
              "Hose Pipe.002",
              "Hose Pipe (2)",
              "Hose Pipe (2):1",
              "Connection.002",
              "Connection (2)",
              "Connection (2):1",
              "Underslung Tank (1)",
              "Underslung Tank (1):1",
              "Out Pipe.003",
              "Out Pipe (2)",
              "Out Pipe (2):1",
              "In Pipe.002",
              "In Pipe (1)",
              "In Pipe (1):1",
              "Propex Heater.002",
              "Propex Heater (1)",
              "Propex Heater (1):1",
              "Heater Side.003",
              "Heater Side (2)",
              "Heater Side (2):1",
              "Heater Top.003",
              "Heater Top (2)",
              "Heater Top (2):1",
              "Heater Face.003",
              "Heater Face (2)",
              "Heater Face (2):1",
              "Seat Back.002",
              "Seat Back (1) (2)",
              "Seat Back (1) (2):1",
              "Front Cap.006",
              "Front Cap (3)",
              "Front Cap (3):1",
              "Heater Box (2)",
              "Heater Box (2):1",
              "Plugs - Propex",
              "Plugs - Propex:1",
              "Body1.011",
              "Plugs Inner",
              "Plugs Inner:1",
              "Plugs Surround.003",
              "Plugs Surround (2)",
              "Plugs Surround (2):1",
              "Body1.012",
              "Light Switch  (2)",
              "Light Switch  (2):1",
              "Body1.013",
              "Light Inner  (2)",
              "Light Inner  (2):1",
              "Body1.014",
              "Light Surround (2)",
              "Light Surround (2):1",
              "Light Switch (2)",
              "Light Switch (2):1",
              "Plug sockets ",
              "Plug sockets :1",
              "Outer.005",
              "Outer (2)",
              "Outer (2):1",
              "Turn Control.002",
              "Turn Control (1)",
              "Turn Control (1):1",
              "L Button.002",
              "L Button (1)",
              "L Button (1):1",
              "R Button.002",
              "R Button (1)",
              "R Button (1):1",
              "L Light.002",
              "L Light (1)",
              "L Light (1):1",
              "R Light.003",
              "R Light (1) (1) (1)",
              "R Light (1) (1) (1):1",
              "Screen.004",
              "Screen (1) (1) (1)",
              "Screen (1) (1) (1):1",
              "Main.002",
              "Main (1) (1)",
              "Main (1) (1):1",
              "Planal Control (1)",
              "Planal Control (1):1",
              "Out Pipe.004",
              "Out Pipe (1) (1)",
              "Out Pipe (1) (1):1",
              "Planar Heater.002",
              "Main Body (1)",
              "Main Body (1):1",
              "Small Pipe.002",
              "Small Pipe (1)",
              "Small Pipe (1):1",
              "Screw (3).002",
              "Screw (3) (1)",
              "Screw (3) (1):1",
              "Big Pipe 1.002",
              "Big Pipe 1 (1)",
              "Big Pipe 1 (1):1",
              "Screw.002",
              "Screw (4)",
              "Screw (4):1",
              "Screw (2).002",
              "Screw (2) (1)",
              "Screw (2) (1):1",
              "Big Pipe 2.002",
              "Big Pipe 2 (1)",
              "Big Pipe 2 (1):1",
              "Screw (1).002",
              "Screw (1) (1)",
              "Screw (1) (1):1",
              "Fixing Plate .002",
              "Base Plate (1).002",
              "Base Plate (1):1.002",
              "Fixing Plate  (1)",
              "Fixing Plate  (1):1",
              "Back Cap.005",
              "Back Cap (1).001",
              "Back Cap (1):1.001",
              "Planar Heater (1)",
              "Planar Heater (1):1",
              "Heater Face.004",
              "Heater Face (1) (1)",
              "Heater Face (1) (1):1",
              "Heater Side.004",
              "Heater Side (1) (1)",
              "Heater Side (1) (1):1",
              "Heater Top.004",
              "Heater Top (1) (1)",
              "Heater Top (1) (1):1",
              "Seat Back.003",
              "Seat Back (1) (1) (1)",
              "Seat Back (1) (1) (1):1",
              "Front Cap.007",
              "Front Cap (1) (1)",
              "Front Cap (1) (1):1",
              "Heater Box (1) (1)",
              "Heater Box (1) (1):1",
              "Plugs - Planar",
              "Plugs - Planar:1",
              "Plug Setups",

              "Body1.003",
              "Plugs Inner ",
              "Plugs Inner :1",
              "Plugs Surround.001",
              "Plugs Surround",
              "Plugs Surround:1",
              "Plug sockets",
              "Plug sockets:1",
              "Body1.004",
              "Light Surround",
              "Light Surround:1",
              "Body1.005",
              "Light Inner ",
              "Light Inner :1",
              "Body1.006",
              "Light Switch ",
              "Light Switch :1",
              "Light Switch",
              "Light Switch:1",
              "Plugs - Standard",
              "Plugs - Standard:1",
              "Body1.007",
              "Plugs Inner  (1)",
              "Plugs Inner  (1):1",
              "Plugs Surround.002",
              "Plugs Surround (1)",
              "Plugs Surround (1):1",
              "Plug sockets (1)",
              "Plug sockets (1):1",
              "Body1.008",
              "Light Surround (1)",
              "Light Surround (1):1",
              "Body1.009",
              "Light Inner  (1)",
              "Light Inner  (1):1",
              "Body1.010",
              "Light Switch  (1)",
              "Light Switch  (1):1",
              "Light Switch (1)",
              "Light Switch (1):1",
              "Propex Control.001",
              "Propex Face (1) (1)",
              "Propex Face (1) (1):1",
              "Screen.003",
              "Screen (1) (2)",
              "Screen (1) (2):1",
              "Red Light.001",
              "Red Light (1) (1)",
              "Red Light (1) (1):1",
              "Green Light.001",
              "Green Light (1) (1)",
              "Green Light (1) (1):1",
              "Up Button.001",
              "Up Button (1) (1)",
              "Up Button (1) (1):1",
              "Left Button.001",
              "Left Button (1) (1)",
              "Left Button (1) (1):1",
              "R Light.002",
              "R Light (1) (2)",
              "R Light (1) (2):1",
              "Right Button.001",
              "Right Button (1) (1)",
              "Right Button (1) (1):1",
              "Down Button.001",
              "Down Button (1) (1)",
              "Down Button (1) (1):1",
              "Propex Control (1) (1)",
              "Propex Control (1) (1):1",
              "Tank.002",
              "Tank (2)",
              "Tank (2):1",
              "L Holder.002",
              "L Holder (2)",
              "L Holder (2):1",
              "R Holder.002",
              "R Holder (2)",
              "R Holder (2):1",
              "1st Washer.002",
              "1st Washer (2)",
              "1st Washer (2):1",
              "2nd Washer.002",
              "2nd Washer (2)",
              "2nd Washer (2):1",
              "2nd Bolt.002",
              "2nd Bolt (2)",
              "2nd Bolt (2):1",
              "Switch Fix.002",
              "Switch Fix (2)",
              "Switch Fix (2):1",
              "3rd Washer.002",
              "3rd Washer (2)",
              "3rd Washer (2):1",
              "3rd Bolt.002",
              "3rd Bolt (2)",
              "3rd Bolt (2):1",
              "1st Bolt.002",
              "1st Bolt (2)",
              "1st Bolt (2):1",
              "4th Washer.002",
              "4th Washer (2)",
              "4th Washer (2):1",
              "Switch.005",
              "Switch (3)",
              "Switch (3):1",
              "LPG Fill.001",
              "BBQ Point (2)",
              "BBQ Point (2):1",
              "Black Cap.002",
              "Black Cap (2)",
              "Black Cap (2):1",
              "Metal Fixing Plate.002",
              "Metal Fixing Plate (2)",
              "Metal Fixing Plate (2):1",
              "BBQ & Filling Point (2)",
              "BBQ & Filling Point (2):1",
              "Rubber Pipe.002",
              "Rubber Pipe (2)",
              "Rubber Pipe (2):1",
              "Hose Pipe.002",
              "Hose Pipe (2)",
              "Hose Pipe (2):1",
              "Connection.002",
              "Connection (2)",
              "Connection (2):1",
              "Underslung Tank (1)",
              "Underslung Tank (1):1",
              "Out Pipe.003",
              "Out Pipe (2)",
              "Out Pipe (2):1",
              "In Pipe.002",
              "In Pipe (1)",
              "In Pipe (1):1",
              "Propex Heater.002",
              "Propex Heater (1)",
              "Propex Heater (1):1",
              "Heater Side.003",
              "Heater Side (2)",
              "Heater Side (2):1",
              "Heater Top.003",
              "Heater Top (2)",
              "Heater Top (2):1",
              "Heater Face.003",
              "Heater Face (2)",
              "Heater Face (2):1",
              "Seat Back.002",
              "Seat Back (1) (2)",
              "Seat Back (1) (2):1",
              "Front Cap.006",
              "Front Cap (3)",
              "Front Cap (3):1",
              "Heater Box (2)",
              "Heater Box (2):1",
              "Plugs - Propex",
              "Plugs - Propex:1",
              "Body1.011",
              "Plugs Inner",
              "Plugs Inner:1",
              "Plugs Surround.003",
              "Plugs Surround (2)",
              "Plugs Surround (2):1",
              "Body1.012",
              "Light Switch  (2)",
              "Light Switch  (2):1",
              "Body1.013",
              "Light Inner  (2)",
              "Light Inner  (2):1",
              "Body1.014",
              "Light Surround (2)",
              "Light Surround (2):1",
              "Light Switch (2)",
              "Light Switch (2):1",
              "Plug sockets ",
              "Plug sockets :1",
              "Outer.005",
              "Outer (2)",
              "Outer (2):1",
              "Turn Control.002",
              "Turn Control (1)",
              "Turn Control (1):1",
              "L Button.002",
              "L Button (1)",
              "L Button (1):1",
              "R Button.002",
              "R Button (1)",
              "R Button (1):1",
              "L Light.002",
              "L Light (1)",
              "L Light (1):1",
              "R Light.003",
              "R Light (1) (1) (1)",
              "R Light (1) (1) (1):1",
              "Screen.004",
              "Screen (1) (1) (1)",
              "Screen (1) (1) (1):1",
              "Main.002",
              "Main (1) (1)",
              "Main (1) (1):1",
              "Planal Control (1)",
              "Planal Control (1):1",
              "Out Pipe.004",
              "Out Pipe (1) (1)",
              "Out Pipe (1) (1):1",
              "Planar Heater.002",
              "Main Body (1)",
              "Main Body (1):1",
              "Small Pipe.002",
              "Small Pipe (1)",
              "Small Pipe (1):1",
              "Screw (3).002",
              "Screw (3) (1)",
              "Screw (3) (1):1",
              "Big Pipe 1.002",
              "Big Pipe 1 (1)",
              "Big Pipe 1 (1):1",
              "Screw.002",
              "Screw (4)",
              "Screw (4):1",
              "Screw (2).002",
              "Screw (2) (1)",
              "Screw (2) (1):1",
              "Big Pipe 2.002",
              "Big Pipe 2 (1)",
              "Big Pipe 2 (1):1",
              "Screw (1).002",
              "Screw (1) (1)",
              "Screw (1) (1):1",
              "Fixing Plate .002",
              "Base Plate (1).002",
              "Base Plate (1):1.002",
              "Fixing Plate  (1)",
              "Fixing Plate  (1):1",
              "Back Cap.005",
              "Back Cap (1).001",
              "Back Cap (1):1.001",
              "Planar Heater (1)",
              "Planar Heater (1):1",
              "Heater Face.004",
              "Heater Face (1) (1)",
              "Heater Face (1) (1):1",
              "Heater Side.004",
              "Heater Side (1) (1)",
              "Heater Side (1) (1):1",
              "Heater Top.004",
              "Heater Top (1) (1)",
              "Heater Top (1) (1):1",
              "Seat Back.003",
              "Seat Back (1) (1) (1)",
              "Seat Back (1) (1) (1):1",
              "Front Cap.007",
              "Front Cap (1) (1)",
              "Front Cap (1) (1):1",
              "Heater Box (1) (1)",
              "Heater Box (1) (1):1",
              "Plugs - Planar",
              "Plugs - Planar:1",
              "Plug Setups",
            ].map(cleanTargetNameForThreeJS),
          }
        }
      },
      // Propex
      extrasheatingUpgrades1: {
        showMeshes: [
          "Tank.003",
          "Tank (1)",
          "Tank (1):1",
          "L Holder.003",
          "L Holder (1)",
          "L Holder (1):1",
          "R Holder.003",
          "R Holder (1)",
          "R Holder (1):1",
          "1st Washer.003",
          "1st Washer (1)",
          "1st Washer (1):1",
          "2nd Washer.003",
          "2nd Washer (1)",
          "2nd Washer (1):1",
          "2nd Bolt.003",
          "2nd Bolt (1)",
          "2nd Bolt (1):1",
          "Switch Fix.003",
          "Switch Fix (1)",
          "Switch Fix (1):1",
          "3rd Washer.003",
          "3rd Washer (1)",
          "3rd Washer (1):1",
          "3rd Bolt.003",
          "3rd Bolt (1)",
          "3rd Bolt (1):1",
          "1st Bolt.003",
          "1st Bolt (1)",
          "1st Bolt (1):1",
          "4th Washer.003",
          "4th Washer (1)",
          "4th Washer (1):1",
          "Switch.006",
          "Switch (2)",
          "Switch (2):1",
          "LPG Fill.002",
          "BBQ Point (1)",
          "BBQ Point (1):1",
          "Black Cap.003",
          "Black Cap (1)",
          "Black Cap (1):1",
          "Metal Fixing Plate.003",
          "Metal Fixing Plate (1)",
          "Metal Fixing Plate (1):1",
          "BBQ & Filling Point (1)",
          "BBQ & Filling Point (1):1",
          "Rubber Pipe.003",
          "Rubber Pipe (1)",
          "Rubber Pipe (1):1",
          "Hose Pipe.003",
          "Hose Pipe (1)",
          "Hose Pipe (1):1",
          "Connection.003",
          "Connection (1)",
          "Connection (1):1",
          "Underslung Gas",
          "Underslung Gas:1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [],
      },

      extrasadditionalKitchenStorage1: {
        // Top cupboards
        showMeshes: [
          "Right Top Cupboard.001",
          "Mid Top Cupboard.001",
          "Left Top Cupboard.001",
          "L Side.001",
          "R Side.001",
          "Base (1)",
          "Body1.035",
          "Body1.036",
          "Top.002",
          "Mid L.001",
          "Mid R.001",
          "Body1.035",
          "Body1.036",

          "L Door Handle.004",
          "L Door Handle (1)",
          "L Door Handle (1):1",
          "Inset Base Plate",
          "Inset Base Plate (2)",
          "Inset Base Plate (2):1",
          "Inset Hinge",
          "Inset Hinge (2) (1)",
          "Inset Hinge (2) (1):1",
          "Inset Countersink",
          "Inset Countersink ",
          "Inset Countersink :1",
          "Inset Hinge (2).001",
          "Inset Hinge (2) (2)",
          "Inset Hinge (2) (2):1",
          "Inset Base Plate (2).001",
          "Inset Base Plate (2) (1)",
          "Inset Base Plate (2) (1):1",
          "Inset Countersink (2)",
          "Inset Countersink (2) (1)",
          "Inset Countersink (2) (1):1",
          "Inset Hinge (2)",
          "Inset Hinge (2):1",
          "L Door.004",
          "L Door (4)",
          "L Door (4):1",
          "L Door ",
          "L Door :1",
          "R Door Handle.004",
          "R Door Handle (1)",
          "R Door Handle (1):1",
          "Inset Base Plate.001",
          "Inset Base Plate (2).002",
          "Inset Base Plate (2):1.001",
          "Inset Hinge.001",
          "Inset Hinge (2) (1).001",
          "Inset Hinge (2) (1):1.001",
          "Inset Countersink.001",
          "Inset Countersink .001",
          "Inset Countersink :1.001",
          "Inset Hinge (2).003",
          "Inset Hinge (2) (2).001",
          "Inset Hinge (2) (2):1.001",
          "Inset Base Plate (2).003",
          "Inset Base Plate (2) (1).001",
          "Inset Base Plate (2) (1):1.001",
          "Inset Countersink (2).001",
          "Inset Countersink (2) (1).001",
          "Inset Countersink (2) (1):1.001",
          "Inset Hinge (2).002",
          "Inset Hinge (2):3",
          "R Door.004",
          "R Door (4)",
          "R Door (4):1",
          "R Door ",
          "R Door :1",
          "Mid Door Handle.001",
          "Mid Door Handle",
          "Mid Door Handle:1",
          "Inset Base Plate.002",
          "Inset Base Plate (2).004",
          "Inset Base Plate (2):1.002",
          "Inset Hinge.002",
          "Inset Hinge (2) (1).002",
          "Inset Hinge (2) (1):1.002",
          "Inset Countersink.002",
          "Inset Countersink .002",
          "Inset Countersink :1.002",
          "Inset Hinge (2).005",
          "Inset Hinge (2) (2).002",
          "Inset Hinge (2) (2):1.002",
          "Inset Base Plate (2).005",
          "Inset Base Plate (2) (1).002",
          "Inset Base Plate (2) (1):1.002",
          "Inset Countersink (2).002",
          "Inset Countersink (2) (1).002",
          "Inset Countersink (2) (1):1.002",
          "Inset Hinge (2).004",
          "Inset Hinge (2):2",
          "Mid Door.001",
          "Mid Door (1)",
          "Mid Door (1):1",
          "Mid Door",
          "Mid Door:1",
          "Top Cupboard Doors",
          "Top Cupboard Doors:1",

          "L Top Handle.001",
          "L Top Handle",
          "L Top Handle:1",
          "L Door.009",
          "L Door (8)",
          "L Door (8):1",
          "Inset Base Plate (2).006",
          "Inset Base Plate (2) (1) (2) (1) (1) (1)",
          "Inset Base Plate (2) (1) (2) (1) (1) (1):1",
          "Inset Countersink (2).004",
          "Inset Countersink (2) (1) (2) (1) (1) (1)",
          "Inset Countersink (2) (1) (2) (1) (1) (1):1",
          "Inset Hinge (2).006",
          "Inset Hinge (2) (4) (1) (1) (1)",
          "Inset Hinge (2) (4) (1) (1) (1):1",
          "Inset Hinge L L",
          "Inset Hinge L L:1",
          "Inset Base Plate (2).007",
          "Inset Base Plate (2) (1) (2) (2) (1)",
          "Inset Base Plate (2) (1) (2) (2) (1):1",
          "Inset Countersink (2).005",
          "Inset Countersink (2) (1) (2) (2) (1)",
          "Inset Countersink (2) (1) (2) (2) (1):1",
          "Inset Hinge (2).007",
          "Inset Hinge (2) (4) (2) (1)",
          "Inset Hinge (2) (4) (2) (1):1",
          "Inset Hinge L R",
          "Inset Hinge L R:1",
          "L Door (4) (1)",
          "L Door (4) (1):1",
          "R Top Handle.001",
          "R Top Handle",
          "R Top Handle:1",
          "Inset Hinge (2).008",
          "Inset Hinge (2) (4)",
          "Inset Hinge (2) (4):1",
          "Inset Base Plate (2).008",
          "Inset Base Plate (2) (1) (2)",
          "Inset Base Plate (2) (1) (2):1",
          "Inset Countersink (2).006",
          "Inset Countersink (2) (1) (2)",
          "Inset Countersink (2) (1) (2):1",
          "Inset Hinge R R",
          "Inset Hinge R R:1",
          "Inset Base Plate (2).009",
          "Inset Base Plate (2) (1) (2) (1)",
          "Inset Base Plate (2) (1) (2) (1):1",
          "Inset Countersink (2).007",
          "Inset Countersink (2) (1) (2) (1)",
          "Inset Countersink (2) (1) (2) (1):1",
          "Inset Hinge (2).009",
          "Inset Hinge (2) (4) (1)",
          "Inset Hinge (2) (4) (1):1",
          "Inset Hinge R L",
          "Inset Hinge R L:1",
          "R Door.009",
          "R Door (7)",
          "R Door (7):1",
          "R Door (4) (1)",
          "R Door (4) (1):1",
          "Mid Top Handle.001",
          "Mid Top Handle",
          "Mid Top Handle:1",
          "Mid Door.002",
          "Mid Door (2)",
          "Mid Door (2):1",
          "Inset Base Plate (2).010",
          "Inset Base Plate (2) (1) (2) (1) (1)",
          "Inset Base Plate (2) (1) (2) (1) (1):1",
          "Inset Countersink (2).008",
          "Inset Countersink (2) (1) (2) (1) (1)",
          "Inset Countersink (2) (1) (2) (1) (1):1",
          "Inset Hinge (2).010",
          "Inset Hinge (2) (4) (1) (1)",
          "Inset Hinge (2) (4) (1) (1):1",
          "Inset Hinge M L",
          "Inset Hinge M L:1",
          "Inset Base Plate (2).011",
          "Inset Base Plate (2) (1) (2) (2)",
          "Inset Base Plate (2) (1) (2) (2):1",
          "Inset Countersink (2).009",
          "Inset Countersink (2) (1) (2) (2)",
          "Inset Countersink (2) (1) (2) (2):1",
          "Inset Hinge (2).011",
          "Inset Hinge (2) (4) (2)",
          "Inset Hinge (2) (4) (2):1",
          "Inset Hinge M R",
          "Inset Hinge M R:1",
          "Mid Door (2) (1)",
          "Mid Door (2) (1):1",
          "Top Cupboard Doors (1)",
          "Top Cupboard Doors (1):1",

          "Base.001",
          "Base (2)",
          "Base (2):1",
          "Top.001",
          "Top",
          "Top:1",
          "L Side.003",
          "L Side.001",
          "L Side:1",
          "R Side.002",
          "R Side.001",
          "R Side:1",
          "L Mid.001",
          "L Mid",
          "L Mid:1",
          "R Mid.001",
          "R Mid",
          "R Mid:1",
          "Main (2)",
          "Main (2):1",
          "Top Cupboards",
          "Top Cupboards:1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: ["Drifter SWB All In v15"].map(cleanTargetNameForThreeJS),
      },

      extrasseatingUpgrades4: {
        showMeshes: [
          "Front Cap.008",
          "Front Cap  (2)",
          "Front Cap  (2):1",
          "Rail.005",
          "Rail R  (2)",
          "Rail R  (2):1",
          "Front Cap.009",
          "Back Cap  (2)",
          "Back Cap  (2):1",
          "Rail R  (1)",
          "Rail R  (1):1",
          "Rail.006",
          "Rail ",
          "Rail :1",
          "Front Cap.010",
          "Front Cap  (1) (1)",
          "Front Cap  (1) (1):1",
          "Front Cap.011",
          "Back Cap  (1) (1)",
          "Back Cap  (1) (1):1",
          "Rail L.001",
          "Rail L:1.001",
          "Front Kick.001",
          "Front Kick",
          "Front Kick:1",
          "Back Kick.001",
          "Back Kick",
          "Back Kick:1",
          "Slide Bed Kicks (1)",
          "Slide Bed Kicks (1):1",
          "Smart Evo Rail Bed (1)",
          "Smart Evo Rail Bed (1):1",
          "Smart Bed Rails v2",
        ].map(cleanTargetNameForThreeJS),

        hideMeshes: [],
      },
      
      extrasseatingUpgrades1: {
        showMeshes: [
          "Rail.007",
          "Rail (3)",
          "Rail (3):1",
          "Front Cap.012",
          "Front Cap (5)",
          "Front Cap (5):1",
          "Front Cap.013",
          "Back Cap (4)",
          "Back Cap (4):1",
          "L Rail",
          "L Rail:1",
          "Rail.008",
          "Rail (3) (1)",
          "Rail (3) (1):1",
          "Front Cap.014",
          "Front Cap (5) (1)",
          "Front Cap (5) (1):1",
          "Front Cap.015",
          "Back Cap (4) (1)",
          "Back Cap (4) (1):1",
          "R Rail",
          "R Rail:1",
          "Glide Motion Bed",
          "Glide Motion Bed:1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: []
      },

      extrasadditionalCookingOptions0: {
        showMeshes: [
          "Back.003",
          "Back (2) (1)",
          "Back (2) (1):1",
          "Drawer Top.003",
          "Drawer Top (1) (1)",
          "Drawer Top (1) (1):1",
          "LED Side.003",
          "LED Side (1) (1)",
          "LED Side (1) (1):1",
          "LED Top.003",
          "LED Top (1) (1)",
          "LED Top (1) (1):1",
          "Drawer Shelf.003",
          "Drawer Shelf (1) (1)",
          "Drawer Shelf (1) (1):1",
          "Main Shelf.004",
          "Main Shelf (1) (1) (1)",
          "Main Shelf (1) (1) (1):1",
          "Shelf Mid.003",
          "Shelf Mid (1) (1)",
          "Shelf Mid (1) (1):1",
          "LED Back.003",
          "LED Back (1) (1)",
          "LED Back (1) (1):1",
          "Fridge R.003",
          "Fridge R (1) (1)",
          "Fridge R (1) (1):1",
          "Fridge L.003",
          "Fridge L (1) (1)",
          "Fridge L (1) (1):1",
          "Oven/Grill Face.001",
          "Oven/Grill Face",
          "Oven/Grill Face:1",
          "Outer.002",
          "Outer (1) (1) (1)",
          "Outer (1) (1) (1):1",
          "Switch.002",
          "Switch (1) (1) (1)",
          "Switch (1) (1) (1):1",
          "Fridge Switch (1) (1)",
          "Fridge Switch (1) (1):1",
          "Main  (1) (1)",
          "Main  (1) (1):1",
          "Body1.002",
          "Component387",
          "Component387:1",
          "Smev.003",
          "Smev (1) (1)",
          "Smev (1) (1):1",
          "Handle.008",
          "Handle (1) (1) (1)",
          "Handle (1) (1) (1):1",
          "Body.004",
          "Body (1) (1) (1)",
          "Body (1) (1) (1):1",
          "Top Cap.002",
          "Top Cap (1) (1) (1)",
          "Top Cap (1) (1) (1):1",
          "Top Cap Fix.002",
          "Top Cap Fix (1) (1) (1)",
          "Top Cap Fix (1) (1) (1):1",
          "Rubber Seal.002",
          "Rubber Seal (1) (1) (1)",
          "Rubber Seal (1) (1) (1):1",
          "Door.004",
          "Door (1) (1) (1)",
          "Door (1) (1) (1):1",
          "Sanjo Fridge 50L - Black (1) (1)",
          "Sanjo Fridge 50L - Black (1) (1):1",
          "Glass.002",
          "Glass (3)",
          "Glass (3):1",
          "Handle.009",
          "Handle (3)",
          "Handle (3):1",
          "Knob.001",
          "Knob",
          "Knob:1",
          "Clicker.001",
          "Clicker",
          "Clicker:1",
          "Plate.001",
          "Plate",
          "Plate:1",
          "Base",
          "Base (3)",
          "Base (3):1",
          "Oven/Grill.001",
          "Oven/Grill Face (1)",
          "Oven/Grill Face (1):1",
          "Body.005",
          "Body (3)",
          "Body (3):1",
          "Oven/Grill",
          "Oven/Grill:1",
          "Oven/Grill Unit ",
          "Oven/Grill Unit :1",
        ].map(cleanTargetNameForThreeJS),
        
        hideMeshes: [

          "L_Door001",

          "Drawer Face.003",
          "Drawer Face (5)",
          "Drawer Face (5):1",
          "Drawer Handle.003",
          "Drawer Handle (4) (1) (1)",
          "Drawer Handle (4) (1) (1):1",
          "Drawer Face (4)",
          "Drawer Face (4):1",
          "Flat Base Plate.018",
          "Flat Base Plate (1) (1) (1) (2) (3)",
          "Flat Base Plate (1) (1) (1) (2) (3):1",
          "Flat Countersink.018",
          "Flat Countersink (1) (1) (1) (2) (3)",
          "Flat Countersink (1) (1) (1) (2) (3):1",
          "R Door Hinge (3)",
          "R Door Hinge (3):1",
          "Flat Base Plate.019",
          "Flat Base Plate (1) (1) (1) (2) (1) (2)",
          "Flat Base Plate (1) (1) (1) (2) (1) (2):1",
          "Flat Countersink.019",
          "Flat Countersink (1) (1) (1) (2) (1) (2)",
          "Flat Countersink (1) (1) (1) (2) (1) (2):1",
          "R Door Hinge (1) (2)",
          "R Door Hinge (1) (2):1",
          "R Handle",
          "R Handle (2)",
          "R Handle (2):1",
          "R Door.006",
          "R Door (2)",
          "R Door (2):1",
          "90° Base Plate.010",
          "90° Base Plate (1) (2)",
          "90° Base Plate (1) (2):1",
          "90° Countersink.010",
          "90° Countersink (1) (2)",
          "90° Countersink (1) (2):1",
          "90° Hinge.010",
          "90° Hinge (1) (1) (2)",
          "90° Hinge (1) (1) (2):1",
          "L Door Hinge (3)",
          "L Door Hinge (3):1",
          "90° Base Plate.011",
          "90° Base Plate (2) (2)",
          "90° Base Plate (2) (2):1",
          "90° Countersink.011",
          "90° Countersink (2) (2)",
          "90° Countersink (2) (2):1",
          "90° Hinge.011",
          "90° Hinge (1) (2) (2)",
          "90° Hinge (1) (2) (2):1",
          "L Door Hinge (1) (2)",
          "L Door Hinge (1) (2):1",
          "L Handle",
          "L Handle (2)",
          "L Handle (2):1",
          "L Door.006",
          "L Door (6)",
          "L Door (6):1",
          "Standard Unit Doors (1)",
          "Standard Unit Doors (1):1",

          "Drawer Face :1",
          "R Door Handle:1",
          "R Door Hinge:1",
          "R Door Hinge (1):1",
          "R Door.001",
          "R Door",
          "R Door:1",
          "L Door Handle:1",
          "L Door Hinge:1",
          "L Door Hinge (1):1",
          "L Door.001",
          "L Door",
          "L Door:1",
          "Main Unit Doors",
          "Main Unit Doors:1",
          "Standard Unit Doors",
          "Standard Unit Doors:1",

          "Drawer Face.003",
          "Drawer Face (5)",
          "Drawer Face (5):1",
          "Drawer Handle.003",
          "Drawer Handle (4) (1) (1)",
          "Drawer Handle (4) (1) (1):1",
          "Drawer Face (4)",
          "Drawer Face (4):1",
          "Flat Base Plate.018",
          "Flat Base Plate (1) (1) (1) (2) (3)",
          "Flat Base Plate (1) (1) (1) (2) (3):1",
          "Flat Countersink.018",
          "Flat Countersink (1) (1) (1) (2) (3)",
          "Flat Countersink (1) (1) (1) (2) (3):1",
          "R Door Hinge (3)",
          "R Door Hinge (3):1",
          "Flat Base Plate.019",
          "Flat Base Plate (1) (1) (1) (2) (1) (2)",
          "Flat Base Plate (1) (1) (1) (2) (1) (2):1",
          "Flat Countersink.019",
          "Flat Countersink (1) (1) (1) (2) (1) (2)",
          "Flat Countersink (1) (1) (1) (2) (1) (2):1",
          "R Door Hinge (1) (2)",
          "R Door Hinge (1) (2):1",
          "R Handle",
          "R Handle (2)",
          "R Handle (2):1",
          "R Door.006",
          "R Door (2)",
          "R Door (2):1",
          "90° Base Plate.010",
          "90° Base Plate (1) (2)",
          "90° Base Plate (1) (2):1",
          "90° Countersink.010",
          "90° Countersink (1) (2)",
          "90° Countersink (1) (2):1",
          "90° Hinge.010",
          "90° Hinge (1) (1) (2)",
          "90° Hinge (1) (1) (2):1",
          "L Door Hinge (3)",
          "L Door Hinge (3):1",
          "90° Base Plate.011",
          "90° Base Plate (2) (2)",
          "90° Base Plate (2) (2):1",
          "90° Countersink.011",
          "90° Countersink (2) (2)",
          "90° Countersink (2) (2):1",
          "90° Hinge.011",
          "90° Hinge (1) (2) (2)",
          "90° Hinge (1) (2) (2):1",
          "L Door Hinge (1) (2)",
          "L Door Hinge (1) (2):1",
          "L Handle",
          "L Handle (2)",
          "L Handle (2):1",
          "L Door.006",
          "L Door (6)",
          "L Door (6):1",
          "Standard Unit Doors (1)",
          "Standard Unit Doors (1):1",

          "L Side",
          "L Side (3)",
          "L Side (3):1",
          "R Side",
          "R Side (2)",
          "R Side (2):1",
          "Rail.004",
          "Rail.003",
          "Rail:1.001",
          "Standard Table Rail ",
          "Standard Table Rail :1",

          "Back",
          "Back (3)",
          "Back (3):1",
          "Fridge R.001",
          "Fridge R",
          "Fridge R:1",
          "Main Shelf.002",
          "Main Shelf (1)",
          "Main Shelf (1):1",
          "Face.002",
          "Face (1)",
          "Face (1):1",
          "LED Back.001",
          "LED Back",
          "LED Back:1",
          "LED Top.001",
          "LED Top",
          "LED Top:1",
          "LED Side.001",
          "LED Side",
          "LED Side:1",
          "Drawer Top.001",
          "Drawer Top",
          "Drawer Top:1",
          "Drawer Shelf.001",
          "Drawer Shelf",
          "Drawer Shelf:1",
          "Gas Shelf.001",
          "Gas Shelf",
          "Gas Shelf:1",
          "Fridge L.001",
          "Fridge L",
          "Fridge L:1",
          "Mid.002",
          "Mid (1)",
          "Mid (1):1",
          "Shelf Mid.001",
          "Shelf Mid",
          "Shelf Mid:1",
          "Switch",
          "Switch (1)",
          "Switch (1):1",
          "Outer",
          "Outer (1)",
          "Outer (1):1",
          "Fridge Switch",
          "Fridge Switch:1",
          "Main. (1)",
          "Main. (1):1",
          "Body1",
          "Component103",
          "Component103:1",
          "Smev.001",
          "Smev",
          "Smev:1",
          "Handle",
          "Handle (1)",
          "Handle (1):1",
          "Body",
          "Body (1)",
          "Body (1):1",
          "Top Cap",
          "Top Cap (1)",
          "Top Cap (1):1",
          "Top Cap Fix",
          "Top Cap Fix (1)",
          "Top Cap Fix (1):1",
          "Rubber Seal",
          "Rubber Seal (1)",
          "Rubber Seal (1):1",
          "Door",
          "Door (1)",
          "Door (1):1",
          "Sanjo Fridge 50L - Black",
          "Sanjo Fridge 50L - Black:1",
          "Standard Unit",
          "Standard Unit:1",

          "Back.001",
          "Back (2)",
          "Back (2):1",
          "Drawer Top.002",
          "Drawer Top (1)",
          "Drawer Top (1):1",
          "LED Side.002",
          "LED Side (1)",
          "LED Side (1):1",
          "LED Top.002",
          "LED Top (1)",
          "LED Top (1):1",
          "Drawer Shelf.002",
          "Drawer Shelf (1)",
          "Drawer Shelf (1):1",
          "Main Shelf.003",
          "Main Shelf (1) (1)",
          "Main Shelf (1) (1):1",
          "Shelf Mid.002",
          "Shelf Mid (1)",
          "Shelf Mid (1):1",
          "LED Back.002",
          "LED Back (1)",
          "LED Back (1):1",
          "Fridge R.002",
          "Fridge R (1)",
          "Fridge R (1):1",
          "Fridge L.002",
          "Fridge L (1)",
          "Fridge L (1):1",
          "Microwave Face.001",
          "Microwave Face",
          "Microwave Face:1",
          "Outer.001",
          "Outer (1) (1)",
          "Outer (1) (1):1",
          "Switch.001",
          "Switch (1) (1)",
          "Switch (1) (1):1",
          "Fridge Switch (1)",
          "Fridge Switch (1):1",
          "Main  (1)",
          "Main  (1):1",
          "Body1.001",
          "Component322",
          "Component322:1",
          "Smev.002",
          "Smev (1)",
          "Smev (1):1",
          "Handle.005",
          "Handle (1) (1)",
          "Handle (1) (1):1",
          "Body.001",
          "Body (1) (1)",
          "Body (1) (1):1",
          "Top Cap.001",
          "Top Cap (1) (1)",
          "Top Cap (1) (1):1",
          "Top Cap Fix.001",
          "Top Cap Fix (1) (1)",
          "Top Cap Fix (1) (1):1",
          "Rubber Seal.001",
          "Rubber Seal (1) (1)",
          "Rubber Seal (1) (1):1",
          "Door.001",
          "Door (1) (1)",
          "Door (1) (1):1",
          "Sanjo Fridge 50L - Black (1)",
          "Sanjo Fridge 50L - Black (1):1",
          "Glass.001",
          "Glass (1)",
          "Glass (1):1",
          "Glass",
          "Glass:1",
          "Door.003",
          "Door (1) (2)",
          "Door (1) (2):1",
          "Screen",
          "Screen (2)",
          "Screen (2):1",
          "Body.003",
          "Body.002",
          "Body:1",
          "Handle.007",
          "Handle.006",
          "Handle:1.002",
          "Control Panel.001",
          "Control Panel (1)",
          "Control Panel (1):1",
          "Control Panel",
          "Control Panel:1",
          "Microwave",
          "Microwave:1",
          "Microwave Unit",
          "Microwave Unit:1",

          "Table Rail",
          "ECO Table Rail",
          "ECO Table Rail:1",
        ].map(cleanTargetNameForThreeJS),
      },

      extrasadditionalCookingOptions1: {
        showMeshes: [
          "Back.001",
          "Back (2)",
          "Back (2):1",
          "Drawer Top.002",
          "Drawer Top (1)",
          "Drawer Top (1):1",
          "LED Side.002",
          "LED Side (1)",
          "LED Side (1):1",
          "LED Top.002",
          "LED Top (1)",
          "LED Top (1):1",
          "Drawer Shelf.002",
          "Drawer Shelf (1)",
          "Drawer Shelf (1):1",
          "Main Shelf.003",
          "Main Shelf (1) (1)",
          "Main Shelf (1) (1):1",
          "Shelf Mid.002",
          "Shelf Mid (1)",
          "Shelf Mid (1):1",
          "LED Back.002",
          "LED Back (1)",
          "LED Back (1):1",
          "Fridge R.002",
          "Fridge R (1)",
          "Fridge R (1):1",
          "Fridge L.002",
          "Fridge L (1)",
          "Fridge L (1):1",
          "Microwave Face.001",
          "Microwave Face",
          "Microwave Face:1",
          "Outer.001",
          "Outer (1) (1)",
          "Outer (1) (1):1",
          "Switch.001",
          "Switch (1) (1)",
          "Switch (1) (1):1",
          "Fridge Switch (1)",
          "Fridge Switch (1):1",
          "Main  (1)",
          "Main  (1):1",
          "Body1.001",
          "Component322",
          "Component322:1",
          "Smev.002",
          "Smev (1)",
          "Smev (1):1",
          "Handle.005",
          "Handle (1) (1)",
          "Handle (1) (1):1",
          "Body.001",
          "Body (1) (1)",
          "Body (1) (1):1",
          "Top Cap.001",
          "Top Cap (1) (1)",
          "Top Cap (1) (1):1",
          "Top Cap Fix.001",
          "Top Cap Fix (1) (1)",
          "Top Cap Fix (1) (1):1",
          "Rubber Seal.001",
          "Rubber Seal (1) (1)",
          "Rubber Seal (1) (1):1",
          "Door.001",
          "Door (1) (1)",
          "Door (1) (1):1",
          "Sanjo Fridge 50L - Black (1)",
          "Sanjo Fridge 50L - Black (1):1",
          "Glass.001",
          "Glass (1)",
          "Glass (1):1",
          "Glass",
          "Glass:1",
          "Door.003",
          "Door (1) (2)",
          "Door (1) (2):1",
          "Screen",
          "Screen (2)",
          "Screen (2):1",
          "Body.003",
          "Body.002",
          "Body:1",
          "Handle.007",
          "Handle.006",
          "Handle:1.002",
          "Control Panel.001",
          "Control Panel (1)",
          "Control Panel (1):1",
          "Control Panel",
          "Control Panel:1",
          "Microwave",
          "Microwave:1",
          "Microwave Unit",
          "Microwave Unit:1",

          "Drawer Handle.004",
          "Drawer Handle (4)",
          "Drawer Handle (4):1",
          "Drawer Face.004",
          "Drawer Face (2)",
          "Drawer Face (2):1",
          "Flat Base Plate.020",
          "Flat Base Plate (1) (1) (1) (2) (2) (2)",
          "Flat Base Plate (1) (1) (1) (2) (2) (2):1",
          "Flat Countersink.020",
          "Flat Countersink (1) (1) (1) (2) (2) (2)",
          "Flat Countersink (1) (1) (1) (2) (2) (2):1",
          "R Door Hinge (2) (2)",
          "R Door Hinge (2) (2):1",
          "Flat Base Plate.021",
          "Flat Base Plate (1) (1) (1) (2) (1) (1) (2)",
          "Flat Base Plate (1) (1) (1) (2) (1) (1) (2):1",
          "Flat Countersink.021",
          "Flat Countersink (1) (1) (1) (2) (1) (1) (2)",
          "Flat Countersink (1) (1) (1) (2) (1) (1) (2):1",
          "R Door Hinge (1) (1) (2)",
          "R Door Hinge (1) (1) (2):1",
          "R Door.007",
          "R Door (3) (2)",
          "R Door (3) (2):1",
          "R Door (1) (2)",
          "R Door (1) (2):1",
          "90° Base Plate.012",
          "90° Base Plate (1) (1) (2)",
          "90° Base Plate (1) (1) (2):1",
          "90° Countersink.012",
          "90° Countersink (1) (1) (2)",
          "90° Countersink (1) (1) (2):1",
          "90° Hinge.012",
          "90° Hinge (1) (1) (1) (2)",
          "90° Hinge (1) (1) (1) (2):1",
          "L Door Hinge (2) (2)",
          "L Door Hinge (2) (2):1",
          "90° Base Plate.013",
          "90° Base Plate (2) (1) (2)",
          "90° Base Plate (2) (1) (2):1",
          "90° Countersink.013",
          "90° Countersink (2) (1) (2)",
          "90° Countersink (2) (1) (2):1",
          "90° Hinge.013",
          "90° Hinge (1) (2) (1) (2)",
          "90° Hinge (1) (2) (1) (2):1",
          "L Door Hinge (1) (1) (2)",
          "L Door Hinge (1) (1) (2):1",
          "L Door.007",
          "L Door (3) (2)",
          "L Door (3) (2):1",
          "L Door (1) (2)",
          "L Door (1) (2):1",
          "R Handle.002",
          "R Handle.001",
          "R Handle:1",
          "L Handle.002",
          "L Handle.001",
          "L Handle:1",
          "Doors  (1)",
          "Doors  (1):1",
          "Microwave Unit Doors (1)",
          "Microwave Unit Doors (1):1",
        ].map(cleanTargetNameForThreeJS),
        
        hideMeshes: [
          "Drawer Face :1",
          "R Door Handle:1",
          "R Door Hinge:1",
          "R Door Hinge (1):1",
          "R Door.001",
          "R Door",
          "R Door:1",
          "L Door Handle:1",
          "L Door Hinge:1",
          "L Door Hinge (1):1",
          "L Door.001",
          "L Door",
          "L Door:1",
          "Main Unit Doors",
          "Main Unit Doors:1",
          "Standard Unit Doors",
          "Standard Unit Doors:1",

          "Back",
          "Back (3)",
          "Back (3):1",
          "Fridge R.001",
          "Fridge R",
          "Fridge R:1",
          "Main Shelf.002",
          "Main Shelf (1)",
          "Main Shelf (1):1",
          "Face.002",
          "Face (1)",
          "Face (1):1",
          "LED Back.001",
          "LED Back",
          "LED Back:1",
          "LED Top.001",
          "LED Top",
          "LED Top:1",
          "LED Side.001",
          "LED Side",
          "LED Side:1",
          "Drawer Top.001",
          "Drawer Top",
          "Drawer Top:1",
          "Drawer Shelf.001",
          "Drawer Shelf",
          "Drawer Shelf:1",
          "Gas Shelf.001",
          "Gas Shelf",
          "Gas Shelf:1",
          "Fridge L.001",
          "Fridge L",
          "Fridge L:1",
          "Mid.002",
          "Mid (1)",
          "Mid (1):1",
          "Shelf Mid.001",
          "Shelf Mid",
          "Shelf Mid:1",
          "Switch",
          "Switch (1)",
          "Switch (1):1",
          "Outer",
          "Outer (1)",
          "Outer (1):1",
          "Fridge Switch",
          "Fridge Switch:1",
          "Main. (1)",
          "Main. (1):1",
          "Body1",
          "Component103",
          "Component103:1",
          "Smev.001",
          "Smev",
          "Smev:1",
          "Handle",
          "Handle (1)",
          "Handle (1):1",
          "Body",
          "Body (1)",
          "Body (1):1",
          "Top Cap",
          "Top Cap (1)",
          "Top Cap (1):1",
          "Top Cap Fix",
          "Top Cap Fix (1)",
          "Top Cap Fix (1):1",
          "Rubber Seal",
          "Rubber Seal (1)",
          "Rubber Seal (1):1",
          "Door",
          "Door (1)",
          "Door (1):1",
          "Sanjo Fridge 50L - Black",
          "Sanjo Fridge 50L - Black:1",
          "Standard Unit",
          "Standard Unit:1",

          "Drawer Face",
          "Drawer Face (3)",
          "Drawer Face (3):1",
          "Drawer Handle",
          "Drawer Handle (3)",
          "Drawer Handle (3):1",
          "Drawer Face ",
          "Drawer Face :1",
          "R Door Handle.001",
          "R Door Handle",
          "R Door Handle:1",
          "Flat Base Plate",
          "Flat Base Plate (1) (1) (1) (2)",
          "Flat Base Plate (1) (1) (1) (2):1",
          "Flat Countersink",
          "Flat Countersink (1) (1) (1) (2)",
          "Flat Countersink (1) (1) (1) (2):1",
          "R Door Hinge",
          "R Door Hinge:1",
          "Flat Base Plate.001",
          "Flat Base Plate (1) (1) (1) (2) (1)",
          "Flat Base Plate (1) (1) (1) (2) (1):1",
          "Flat Countersink.001",
          "Flat Countersink (1) (1) (1) (2) (1)",
          "Flat Countersink (1) (1) (1) (2) (1):1",
          "R Door Hinge (1)",
          "R Door Hinge (1):1",
          "R Door.001",
          "R Door",
          "R Door:1",
          "L Door Handle.001",
          "L Door Handle",
          "L Door Handle:1",
          "90° Base Plate",
          "90° Base Plate (1)",
          "90° Base Plate (1):1",
          "90° Countersink",
          "90° Countersink (1)",
          "90° Countersink (1):1",
          "90° Hinge",
          "90° Hinge (1) (1)",
          "90° Hinge (1) (1):1",
          "L Door Hinge",
          "L Door Hinge:1",
          "90° Base Plate.001",
          "90° Base Plate (2)",
          "90° Base Plate (2):1",
          "90° Countersink.001",
          "90° Countersink (2)",
          "90° Countersink (2):1",
          "90° Hinge.001",
          "90° Hinge (1) (2)",
          "90° Hinge (1) (2):1",
          "L Door Hinge (1)",
          "L Door Hinge (1):1",
          "L Door.001",
          "L Door",
          "L Door:1",
          "Main Unit Doors",
          "Main Unit Doors:1",
          "Standard Unit Doors",
          "Standard Unit Doors:1",
        ].map(cleanTargetNameForThreeJS),
      },
      
      extrasexternalStyling0: {
        showMeshes: [
          "Side Bars Black v1"
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },

      extrasexternalStyling1: {
        showMeshes: [
          "Side Bars Chrome v1"
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },


    },

    swatchTargets: {
      splashbacks: [
        "Backsplash.001",
        "Main Panel.002",
        "Main Panel.003",
        "Main Panel.001",
      ].map(cleanTargetNameForThreeJS),

      kitchenUnits: [

        "Back.003",
        "Back (2) (1)",
        "Back (2) (1):1",
        "Drawer Top.003",
        "Drawer Top (1) (1)",
        "Drawer Top (1) (1):1",
        "LED Side.003",
        "LED Side (1) (1)",
        "LED Side (1) (1):1",
        "LED Top.003",
        "LED Top (1) (1)",
        "LED Top (1) (1):1",
        "Drawer Shelf.003",
        "Drawer Shelf (1) (1)",
        "Drawer Shelf (1) (1):1",
        "Main Shelf.004",
        "Main Shelf (1) (1) (1)",
        "Main Shelf (1) (1) (1):1",
        "Shelf Mid.003",
        "Shelf Mid (1) (1)",
        "Shelf Mid (1) (1):1",
        "LED Back.003",
        "LED Back (1) (1)",
        "LED Back (1) (1):1",
        "Fridge R.003",
        "Fridge R (1) (1)",
        "Fridge R (1) (1):1",
        "Fridge L.003",
        "Fridge L (1) (1)",
        "Fridge L (1) (1):1",
        "Oven/Grill Face.001",
        "Oven/Grill Face",
        "Oven/Grill Face:1",
        "Outer.002",
        "Outer (1) (1) (1)",
        "Outer (1) (1) (1):1",
        "Switch.002",
        "Switch (1) (1) (1)",
        "Switch (1) (1) (1):1",
        "Fridge Switch (1) (1)",
        "Fridge Switch (1) (1):1",
        "Main  (1) (1)",

        "Table ",
        "Table .002",
        "Drawer Face",
        "Drawer Face (3)",
        "Drawer Face (3):1",
        "Drawer Handle",
        "Drawer Handle (3)",
        "Drawer Handle (3):1",
        "Drawer Face ",
        "Drawer Face :1",
        "R Door Handle.001",
        "R Door Handle",
        "R Door Handle:1",
        "Flat Base Plate",
        "Flat Base Plate (1) (1) (1) (2)",
        "Flat Base Plate (1) (1) (1) (2):1",
        "Flat Countersink",
        "Flat Countersink (1) (1) (1) (2)",
        "Flat Countersink (1) (1) (1) (2):1",
        "R Door Hinge",
        "R Door Hinge:1",
        "Flat Base Plate.001",
        "Flat Base Plate (1) (1) (1) (2) (1)",
        "Flat Base Plate (1) (1) (1) (2) (1):1",
        "Flat Countersink.001",
        "Flat Countersink (1) (1) (1) (2) (1)",
        "Flat Countersink (1) (1) (1) (2) (1):1",
        "R Door Hinge (1)",
        "R Door Hinge (1):1",
        "R Door.001",
        "R Door",
        "R Door:1",
        "L Door Handle.001",
        "L Door Handle",
        "L Door Handle:1",
        "90° Base Plate",
        "90° Base Plate (1)",
        "90° Base Plate (1):1",
        "90° Countersink",
        "90° Countersink (1)",
        "90° Countersink (1):1",
        "90° Hinge",
        "90° Hinge (1) (1)",
        "90° Hinge (1) (1):1",
        "L Door Hinge",
        "L Door Hinge:1",
        "90° Base Plate.001",
        "90° Base Plate (2)",
        "90° Base Plate (2):1",
        "90° Countersink.001",
        "90° Countersink (2)",
        "90° Countersink (2):1",
        "90° Hinge.001",
        "90° Hinge (1) (2)",
        "90° Hinge (1) (2):1",
        "L Door Hinge (1)",
        "L Door Hinge (1):1",
        "L Door.001",
        "L Door",
        "L Door:1",
        "Main Unit Doors",
        "Main Unit Doors:1",
        "Standard Unit Doors",
        "Standard Unit Doors:1",
        "Back",
        "Back (3)",
        "Back (3):1",
        "Fridge R.001",
        "Fridge R",
        "Fridge R:1",
        "Main Shelf.002",
        "Main Shelf (1)",
        "Main Shelf (1):1",
        "Face.002",
        "Face (1)",
        "Face (1):1",
        "LED Back.001",
        "LED Back",
        "LED Back:1",
        "LED Top.001",
        "LED Top",
        "LED Top:1",
        "LED Side.001",
        "LED Side",
        "LED Side:1",
        "Drawer Top.001",
        "Drawer Top",
        "Drawer Top:1",
        "Drawer Shelf.001",
        "Drawer Shelf",
        "Drawer Shelf:1",
        "Gas Shelf.001",
        "Gas Shelf",
        "Gas Shelf:1",
        "Fridge L.001",
        "Fridge L",
        "Fridge L:1",
        "Mid.002",
        "Mid (1)",
        "Mid (1):1",
        "Shelf Mid.001",
        "Shelf Mid",
        "Shelf Mid:1",
        "Switch",
        "Switch (1)",
        "Switch (1):1",
        "Outer",
        "Outer (1)",
        "Outer (1):1",
        "Fridge Switch",
        "Fridge Switch:1",
        "Main. (1)",
        "Main. (1):1",
        "Top Stop.001",
        "Top Stop",
        "Top Stop:1",
        "Low Stop.001",
        "Low Stop",
        "Low Stop:1",
        "Face.001",
        "Face",
        "Face:1",
        "Front End.001",
        "Front End",
        "Front End:1",
        "Back Top Stop.001",
        "Back Top Stop",
        "Back Top Stop:1",
        "Back Low Stop.001",
        "Back Low Stop",
        "Back Low Stop:1",
        "Back End.001",
        "Back End",
        "Back End:1",
        "Main Shelf.001",
        "Main Shelf",
        "Main Shelf:1",
        "Mid Shelf.001",
        "Mid Shelf",
        "Mid Shelf:1",
        "Top Shelf.001",
        "Top Shelf",
        "Top Shelf:1",
        "Mid.001",
        "Mid",
        "Mid:1",
        "Taylored Back Logo.001",
        "Taylored Back Logo",
        "Taylored Back Logo:1",
        "Upper Back.001",
        "Upper Back",
        "Upper Back:1",
        "Main",
        "L Top Handle.001",
        "L Top Handle",
        "L Top Handle:1",
        "L Door.009",
        "L Door (8)",
        "L Door (8):1",
        "Inset Base Plate (2).006",
        "Inset Base Plate (2) (1) (2) (1) (1) (1)",
        "Inset Base Plate (2) (1) (2) (1) (1) (1):1",
        "Inset Countersink (2).004",
        "Inset Countersink (2) (1) (2) (1) (1) (1)",
        "Inset Countersink (2) (1) (2) (1) (1) (1):1",
        "Inset Hinge (2).006",
        "Inset Hinge (2) (4) (1) (1) (1)",
        "Inset Hinge (2) (4) (1) (1) (1):1",
        "Inset Hinge L L",
        "Inset Hinge L L:1",
        "Inset Base Plate (2).007",
        "Inset Base Plate (2) (1) (2) (2) (1)",
        "Inset Base Plate (2) (1) (2) (2) (1):1",
        "Inset Countersink (2).005",
        "Inset Countersink (2) (1) (2) (2) (1)",
        "Inset Countersink (2) (1) (2) (2) (1):1",
        "Inset Hinge (2).007",
        "Inset Hinge (2) (4) (2) (1)",
        "Inset Hinge (2) (4) (2) (1):1",
        "Inset Hinge L R",
        "Inset Hinge L R:1",
        "L Door (4) (1)",
        "L Door (4) (1):1",
        "R Top Handle.001",
        "R Top Handle",
        "R Top Handle:1",
        "Inset Hinge (2).008",
        "Inset Hinge (2) (4)",
        "Inset Hinge (2) (4):1",
        "Inset Base Plate (2).008",
        "Inset Base Plate (2) (1) (2)",
        "Inset Base Plate (2) (1) (2):1",
        "Inset Countersink (2).006",
        "Inset Countersink (2) (1) (2)",
        "Inset Countersink (2) (1) (2):1",
        "Inset Hinge R R",
        "Inset Hinge R R:1",
        "Inset Base Plate (2).009",
        "Inset Base Plate (2) (1) (2) (1)",
        "Inset Base Plate (2) (1) (2) (1):1",
        "Inset Countersink (2).007",
        "Inset Countersink (2) (1) (2) (1)",
        "Inset Countersink (2) (1) (2) (1):1",
        "Inset Hinge (2).009",
        "Inset Hinge (2) (4) (1)",
        "Inset Hinge (2) (4) (1):1",
        "Inset Hinge R L",
        "Inset Hinge R L:1",
        "R Door.009",
        "R Door (7)",
        "R Door (7):1",
        "R Door (4) (1)",
        "R Door (4) (1):1",
        "Mid Top Handle.001",
        "Mid Top Handle",
        "Mid Top Handle:1",
        "Mid Door.002",
        "Mid Door (2)",
        "Mid Door (2):1",
        "Inset Base Plate (2).010",
        "Inset Base Plate (2) (1) (2) (1) (1)",
        "Inset Base Plate (2) (1) (2) (1) (1):1",
        "Inset Countersink (2).008",
        "Inset Countersink (2) (1) (2) (1) (1)",
        "Inset Countersink (2) (1) (2) (1) (1):1",
        "Inset Hinge (2).010",
        "Inset Hinge (2) (4) (1) (1)",
        "Inset Hinge (2) (4) (1) (1):1",
        "Inset Hinge M L",
        "Inset Hinge M L:1",
        "Inset Base Plate (2).011",
        "Inset Base Plate (2) (1) (2) (2)",
        "Inset Base Plate (2) (1) (2) (2):1",
        "Inset Countersink (2).009",
        "Inset Countersink (2) (1) (2) (2)",
        "Inset Countersink (2) (1) (2) (2):1",
        "Inset Hinge (2).011",
        "Inset Hinge (2) (4) (2)",
        "Inset Hinge (2) (4) (2):1",
        "Inset Hinge M R",
        "Inset Hinge M R:1",
        "Mid Door (2) (1)",
        "Mid Door (2) (1):1",
        "Top Cupboard Doors (1)",
        "Top Cupboard Doors (1):1",
        "Door Handle.001",
        "Door Handle",
        "Door Handle:1",
        "Inset Hinge.004",
        "Inset Hinge (5)",
        "Inset Hinge (5):1",
        "Inset Countersink.003",
        "Inset Countersink (2).003",
        "Inset Countersink (2):1",
        "Inset Base Plate.004",
        "Inset Base Plate (3)",
        "Inset Base Plate (3):1",
        "Back Cupboard Hinge",
        "Back Cupboard Hinge:1",
        "Inset Hinge (1).001",
        "Inset Hinge (1) (2)",
        "Inset Hinge (1) (2):1",
        "Inset Countersink (1).001",
        "Inset Countersink (1) (1)",
        "Inset Countersink (1) (1):1",
        "Inset Base Plate (1).001",
        "Inset Base Plate (1) (1)",
        "Inset Base Plate (1) (1):1",
        "Back Cupboard Hinge (1)",
        "Back Cupboard Hinge (1):1",
        "Door.005",
        "Back Cupboard Door",
        "Back Cupboard Door:1",
        "Top.001",
        "L Side.003",
        "R Side.002",
        "R Mid.001",
        "Base.003",
        "Base.005",
        "Back.004",
        "L Side.005",
        "L Face.002",
        "Top Door.001",
        "Back Top Door.001",
        "Cubby Door.001",
        "L Door.004",
        "R Door.004",
        "Mid Door.001",
        "Back Upper Handle.001",
        "L Top Handle.001",
        "R Top Handle.001",
        "Mid Top Handle.001",
        "Inset Base Plate.005",
        "Inset Hinge.005",
        "Door.006",
        "Base.001",
        "L Mid.001",
        "R Side.003",
        "L Side.004",
        "Front.001",
        "Back.005",
        "Back Lower Handle.001",
        "Back Low Door.002",
        "Back Top Door.002",
        "Lower Handle.001",
        "Low Door.002",
        "Top Handle.002",
        "Cubby Door.002",
        "Upper Handle.001",
        "Top Door.002",
        "L Door.009",
        "R Door.009",
        "Mid Door.002",
        "R Face.002",
        "Pelmet",
        "Pelmet.001",
        "Drawer Face",
        "L Door.001",
        "R Handle",
        "R Door.006",
        "L Handle",
        "Drawer Handle.005",
        "L Handle.003",
        "R Handle.003",
        "R Door.001",
        "L Door.006",
        "Drawer Handle.004",
        "Drawer Face.004",
        "R Handle.002",
        "L Handle.002",
        "Drawer Handle.003",
        "R Door.007",
        "L Door.007",
        "Drawer Face.005",
        "R Door.003",
        "L Door.003",
        "Drawer Face.002",
        "L Door.002",
        "Drawer Face.001",
        "R Door.008",
        "R Door Handle.002",
        "R Door.002",
        "Drawer Face.003",
        "L Door.008",
        "R Door Handle.001",
        "L Door Handle.001",
        "L Door Handle.002",
        "Drawer Handle.001",
        "R Door Handle.003",
        "Drawer Handle",
        "L Door Handle.003",
        "Drawer Handle.002",
        "Low Door Handle",
        "Low Door.001",
        "Back Low Handle.001",
        "Back Low Door.001",
        "R Door.005",
        "R Kick Handle.001",
        "L Kick Handle.001",
        "L Door.010",
        "Front Kick.001",
        "Back Kick.001",
        "Face (1).001",
        "L Door.005",
        "R Door.010",
        "Table Rail",
        "L Side",
        "R Side",
        "Rail.004",
        "Holder R.001",
        "Bit L.001",
        "Bit R.001",
        "Holder L.001",
        "Top Handle.001",
        "Back Top Handle.001",
        "Cubby Handle.001",
        "L Door Handle.004",
        "R Door Handle.004",
        "Mid Door Handle.001",
        "Front End.001",

        "Back.001",
        "Back (2)",
        "Back (2):1",
        "Drawer Top.002",
        "Drawer Top (1)",
        "Drawer Top (1):1",
        "LED Side.002",
        "LED Side (1)",
        "LED Side (1):1",
        "LED Top.002",
        "LED Top (1)",
        "LED Top (1):1",
        "Drawer Shelf.002",
        "Drawer Shelf (1)",
        "Drawer Shelf (1):1",
        "Main Shelf.003",
        "Main Shelf (1) (1)",
        "Main Shelf (1) (1):1",
        "Shelf Mid.002",
        "Shelf Mid (1)",
        "Shelf Mid (1):1",
        "LED Back.002",
        "LED Back (1)",
        "LED Back (1):1",
        "Fridge R.002",
        "Fridge R (1)",
        "Fridge R (1):1",
        "Fridge L.002",
        "Fridge L (1)",
        "Fridge L (1):1",
        "Microwave Face.001",
        "Microwave Face",
        "Microwave Face:1",
        "Outer.001",
        "Outer (1) (1)",
        "Outer (1) (1):1",
        "Switch.001",
        "Switch (1) (1)",
        "Switch (1) (1):1",
        "Fridge Switch (1)",
        "Fridge Switch (1):1",
        "Main  (1)",
        "Main  (1):1",
      ].map(cleanTargetNameForThreeJS),

      worktopFinishes: [
        "Flap.001",
        "Worktop.001",
      ].map(cleanTargetNameForThreeJS),

      fridgeColour: [
        "Handle",
        "Handle (1)",
        "Handle (1):1",
        "Body",
        "Body (1)",
        "Body (1):1",
        "Top Cap",
        "Top Cap (1)",
        "Top Cap (1):1",
        "Top Cap Fix",
        "Top Cap Fix (1)",
        "Top Cap Fix (1):1",
        "Rubber Seal",
        "Rubber Seal (1)",
        "Rubber Seal (1):1",
        "Door",
        "Door (1)",
        "Door (1):1",
        "Sanjo Fridge 50L - Black",
        "Sanjo Fridge 50L - Black:1",
        "Handle.005",
        "Handle (1) (1)",
        "Handle (1) (1):1",
        "Body.001",
        "Body (1) (1)",
        "Body (1) (1):1",
        "Top Cap.001",
        "Top Cap (1) (1)",
        "Top Cap (1) (1):1",
        "Top Cap Fix.001",
        "Top Cap Fix (1) (1)",
        "Top Cap Fix (1) (1):1",
        "Rubber Seal.001",
        "Rubber Seal (1) (1)",
        "Rubber Seal (1) (1):1",
        "Door.001",
        "Door (1) (1)",
        "Door (1) (1):1",
        "Sanjo Fridge 50L - Black (1)",
        "Sanjo Fridge 50L - Black (1):1",
        "Handle.008",
        "Handle (1) (1) (1)",
        "Handle (1) (1) (1):1",
        "Body.004",
        "Body (1) (1) (1)",
        "Body (1) (1) (1):1",
        "Top Cap.002",
        "Top Cap (1) (1) (1)",
        "Top Cap (1) (1) (1):1",
        "Top Cap Fix.002",
        "Top Cap Fix (1) (1) (1)",
        "Top Cap Fix (1) (1) (1):1",
        "Rubber Seal.002",
        "Rubber Seal (1) (1) (1)",
        "Rubber Seal (1) (1) (1):1",
        "Door.004",
        "Door (1) (1) (1)",
        "Door (1) (1) (1):1",
        "Sanjo Fridge 50L - Black (1) (1)",
        "Sanjo Fridge 50L - Black (1) (1):1",
      ].map(cleanTargetNameForThreeJS),
      
      upholstery: [
        "Body1.015",
        "Body1.016",
        "Body1.017",
        "Body1.018",
        "Body1.019",
        "Body1.020",
        "Body1.021",
        "Body1.026",
        "Body1.027",
        "Body1.028",
        "Head Rest",
        "Body1.029",
        "Body1.030",
        "Body1.031",
        "Body1.032",
        "Body1.033",
        "Body1.034",
        "swivel_chair",
        "chair1.002",
        "chair1.001",
        "chair.002",
        "chair.001",
      ].map(cleanTargetNameForThreeJS),

      vanColours: [
        "TopSideRoofRims",
        "Object013.001",
        "Roof_down_02.001",
        "doorhandle.001",
        "doorhandle_key.001",
        "doorhandle_key1.001",
        "side_mirror_1.001",
        "side_mirror_2.001",
        "fender_b1.001",
        "hood1.001",
        "front_car.001",
        "Object004.001",
        "back_car.001",
        "back_car1.001",
        "Roof_up_04.001",
        "Roof_down_01.001",
        "Object002.001",
        "Passenger-side rear window blocked",
        "Driver-side rear window blocked",
      ].map(cleanTargetNameForThreeJS),

      vanWallLining4WayStretchCarpet: [
        "wheelcap_carpet",
        "Roof00.001",
        "frame_s.001",
        "frame_s1.001",
        "fender_s.001",
        "door_s4.001",
        "Passenger-side rear window blocked Internal",
        "Driver-side rear window blocked Internal",
      ].map(cleanTargetNameForThreeJS),

      flooringRange: ["dashboard.003"].map(cleanTargetNameForThreeJS),

      popTopCanvas: ["Roof_up_02.001"].map(cleanTargetNameForThreeJS),

      blindsCurtains: [
        "big_blinds",
        "big_blinds_1",
        "big_blinds_2",
      ].map(cleanTargetNameForThreeJS),

      // seatingRIBAltairBedFabric: [].map(cleanTargetNameForThreeJS),

      // woodEdgeFinish: [].map(cleanTargetNameForThreeJS),
    },
  },

  standardAdventurerSWB: {
    name: "Adventurer",
    imageUrl: "/media/webgl/conversions/thumbnails/adventurer-swb.webp",
    modelUrl:
      "/media/webgl/standard-adventurer-swb-in-a-volkswagen-transporter.glb",
    modelSizeBytes: 0,
    conversionRootNodeName: cleanTargetNameForThreeJS(
      "SWB Adventurer All In v8"
    ),

    defaultMeshVisibility: {
      showMeshes: [].map(cleanTargetNameForThreeJS),
      hideMeshes: ["Roof_up_04.001", "zipline", "window_s3.001", "window_s3.002", "window_frame_s.002", "big_blinds_2", "big_blinds_1", "swivel_chair", "KRS Roof Rack v4", "Solar Panel v5", ].map(cleanTargetNameForThreeJS),
    },
    
    // Meshes to toggle in response to enabled extras
    extraMeshes: {
      extrastableUpgrades1: {
        showMeshes: [
          "Cranked Arm Table v3.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments2: {
        showMeshes: [
          "Vamoose Awning Rail v2.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments1: {
        showMeshes: [
          "Thule Wanderway Bike Rack v9",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      "extrastracking&Safety0": {
        showMeshes: [
          "View Dash Cam v1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras7: {
        showMeshes: ["KRS Roof Rack v4"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraschargingUpgrades3: {
        showMeshes: ["Solar Panel v5"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      hasWindowUpgrades: {
        showMeshes: ["window_s3.001", "window_frame_s.002", "big_blinds_1"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["standard_blocked_off_window_2", "standard_blocked_off_window_2_internal",].map(cleanTargetNameForThreeJS),
      },
      extrasseatingUpgrades3: {
        showMeshes: ["swivel_chair"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["chair1.001", "chair1.002"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras5: {
        showMeshes: ["Roof_up_04.001"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["Roof_up_black"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras8: {
        showMeshes: ["zipline"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
    },

    swatchTargets: {
      kitchenUnits: [
        "L Fridge (2)",
        "Above Drawer.001",
        "Drawer Face.001",
        "R Fridge.001",
        "Front.001",
        "L Door.001",
        "R Door.001",
        "Right Side.001",
        "L Side.001",
        "Face.001",
        "Base.001",
        "R Side.002",
        "Mid Left.001",
        "Mid Right.001",
        "L Door.002",
        "Mid Door.001",
        "R Door.002",
        "Face.002",
        "L Door.003",
        "R Door.003",
        "Back.001",
        "R Side.001",
        "Face",
        "R Lid.001",
        "L Lid.001",
        "Top.001",
        "Mid.002",
      ].map(cleanTargetNameForThreeJS),

      worktopFinishes: [
        "Table.001","Worktop.001"].map(cleanTargetNameForThreeJS),

      fridgeColour: ["Fridge.001"].map(cleanTargetNameForThreeJS),
      
      upholstery: [
        "swivel_chair",
        "Body1.005",
        "Body1.004",
        "Body1.003",
        "Body1.002",
        "Body1.018",
        "Body1.011",
        "Body1.007",
        "Body1.006",
        "Body1.009",
        "Body1.010",
        "Body1.008",
        "Head Rest.001",
        "Body1.016",
        "Body1.012",
        "Body1.013",
        "Body1.014",
        "Body1.015",
        "chair1.001",
        "chair1.002",
        "chair.001",
        "chair.002",
      ].map(cleanTargetNameForThreeJS),

      vanColours: [
        "TopSideRoofRims",
        "Object013.001",
        "Roof_down_02.001",
        "doorhandle.001",
        "doorhandle_key.001",
        "doorhandle_key1.001",
        "side_mirror_1.001",
        "side_mirror_2.001",
        "fender_b1.001",
        "hood1.001",
        "front_car.001",
        "Object004.001",
        "back_car.001",
        "back_car1.001",
        "Roof_up_04.001",
        "Roof_down_01.001",
        "Object002.001",
        "standard_blocked_off_window_1",
        "standard_blocked_off_window_2",
      ].map(cleanTargetNameForThreeJS),

      vanWallLining4WayStretchCarpet: [
        "wheelcap_carpet",
        "Roof00.001",
        "frame_s.001",
        "frame_s1.001",
        "fender_s.001",
        "door_s4.001",
        "standard_blocked_off_window_1_internal",
        "standard_blocked_off_window_2_internal",
      ].map(cleanTargetNameForThreeJS),

      flooringRange: ["dashboard.003"].map(cleanTargetNameForThreeJS),

      popTopCanvas: ["Roof_up_02.001"].map(cleanTargetNameForThreeJS),

      blindsCurtains: [
        "big_blinds",
        "big_blinds_1",
        "big_blinds_2",
      ].map(cleanTargetNameForThreeJS),

      // seatingRIBAltairBedFabric: [].map(cleanTargetNameForThreeJS),

      // woodEdgeFinish: [].map(cleanTargetNameForThreeJS),
    },
  },
  standardDrifterSWB: {
    name: "Drifter",
    imageUrl: "/media/webgl/conversions/thumbnails/drifter-swb.webp",
    modelUrl:
      "/media/webgl/standard-drifter-swb-in-a-volkswagen-transporter.glb",
    modelSizeBytes: 0,
    conversionRootNodeName: cleanTargetNameForThreeJS("Drifter SWB All In v15"),
    
    defaultMeshVisibility: {
      showMeshes: [].map(cleanTargetNameForThreeJS),
      hideMeshes: ["ECO Drifter All In v3", "Roof_up_04.001", "zipline", "window_s3.001", "window_s3.002", "window_frame_s.002", "big_blinds_2", "big_blinds_1", "swivel_chair", "KRS Roof Rack v4", "Solar Panel v5", ].map(cleanTargetNameForThreeJS),
    },
    
    // Meshes to toggle in response to enabled extras
    extraMeshes: {
      extrastableUpgrades1: {
        showMeshes: [
          "Cranked Arm Table v3.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments2: {
        showMeshes: [
          "Vamoose Awning Rail v2.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments1: {
        showMeshes: [
          "Thule Wanderway Bike Rack v9",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      "extrastracking&Safety0": {
        showMeshes: [
          "View Dash Cam v1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras7: {
        showMeshes: ["KRS Roof Rack v4"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraschargingUpgrades3: {
        showMeshes: ["Solar Panel v5"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      hasWindowUpgrades: {
        showMeshes: ["window_s3.001", "window_frame_s.002", "big_blinds_1"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["standard_blocked_off_window_2", "standard_blocked_off_window_2_internal",].map(cleanTargetNameForThreeJS),
      },
      extrasseatingUpgrades3: {
        showMeshes: ["swivel_chair"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["chair1.001", "chair1.002"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras5: {
        showMeshes: ["Roof_up_04.001"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["Roof_up_black"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras8: {
        showMeshes: ["zipline"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasadditionalKitchenStorage1: {
        // Top cupboards
        showMeshes: ["ECO Drifter All In v3"].map(cleanTargetNameForThreeJS),
        hideMeshes: [
          "Body1.022",
          "Body1.023",
          "Body1.025",
          "Body1.024",
          "Body1.030",
          "Body1.026",
          "Body1.029",
          "Body1.027",
          "Body2",
          "Body1.028",
          "Handle (2).001",
        ].map(cleanTargetNameForThreeJS),
      },
    },

    swatchTargets: {
      splashbacks: [
        "Upright.001",
        "Top Hole Back.001",
        "Bottom Hole Back.001",
        "Bottom Hole Side Right.001",
        "Top Hole Side Right.001",
      ].map(cleanTargetNameForThreeJS),

      kitchenUnits: [
        "Front.002",
        "Body1.021",
        "Body1.020",
        "Body1.019",
        "Body1.018",
        "Body1.011",
        "Body1.007",
        "Body1.015",
        "Body1.028",
        "Body2",
        "Body1.029",
        "Body1.027",
        "Body1.026",
        "Body1.025",
        "Body1.022",
        "Body1.023",
        "Body1.024",
        "Body1.030",
        "Body1.003",
        "Front.001",
        "Door",
        "Right DoorTop.001",
        "Right Door Bottom.001",
        "Side Right.001",
        "Rib Cover",
        "Big Side.001",
        "Small Side.001",
        "Back Cover.001",
        "Body2.002",
        "Body1.085",
        "Top.002",
        "Right Top Cupboard.001",
        "R Side.001",
        "Mid Top Cupboard.001",
        "Mid R.001",
        "Mid L.001",
        "Left Top Cupboard.001",
        "L Side.001",
        "Base (1)",
      ].map(cleanTargetNameForThreeJS),

      worktopFinishes: [
        "Table.001","WorkTop.001", "Flap.001"].map(
        cleanTargetNameForThreeJS
      ),

      fridgeColour: ["Fridge.001"].map(cleanTargetNameForThreeJS),
      
      upholstery: [
        "swivel_chair",
        "Body1.034",
        "Body1.033",
        "Body1.032",
        "Body1.031",
        "Body1.047",
        "Body1.040",
        "Body1.036",
        "Body1.035",
        "Body1.038",
        "Body1.039",
        "Body1.037",
        "Head Rest.001",
        "Body1.044",
        "Body1.043",
        "Body1.042",
        "Body1.041",
        "Body1.045",
        "chair1.001",
        "chair1.002",
        "chair.001",
        "chair.002",
      ].map(cleanTargetNameForThreeJS),
      
      vanColours: [
        "TopSideRoofRims",
        "Object013.001",
        "Roof_down_02.001",
        "doorhandle.001",
        "doorhandle_key.001",
        "doorhandle_key1.001",
        "side_mirror_1.001",
        "side_mirror_2.001",
        "fender_b1.001",
        "hood1.001",
        "front_car.001",
        "Object004.001",
        "back_car.001",
        "back_car1.001",
        "Roof_up_04.001",
        "Roof_down_01.001",
        "Object002.001",
        "standard_blocked_off_window_1",
        "standard_blocked_off_window_2",
      ].map(cleanTargetNameForThreeJS),

      vanWallLining4WayStretchCarpet: [
        "wheelcap_carpet",
        "Roof00.001",
        "frame_s.001",
        "frame_s1.001",
        "fender_s.001",
        "door_s4.001",
        "standard_blocked_off_window_1_internal",
        "standard_blocked_off_window_2_internal",
      ].map(cleanTargetNameForThreeJS),

      flooringRange: ["dashboard.003"].map(cleanTargetNameForThreeJS),

      popTopCanvas: ["Roof_up_02.001"].map(cleanTargetNameForThreeJS),

      blindsCurtains: [
        "big_blinds",
        "big_blinds_1",
        "big_blinds_2",
      ].map(cleanTargetNameForThreeJS),

      // seatingRIBAltairBedFabric: [].map(cleanTargetNameForThreeJS),

      // woodEdgeFinish: [].map(cleanTargetNameForThreeJS),
    },
  },
  standardExpeditionSWB: {
    name: "Expedition",
    imageUrl: "/media/webgl/conversions/thumbnails/expedition-swb.webp",
    modelUrl:
      "/media/webgl/standard-expedition-swb-in-a-volkswagen-transporter.glb",
    modelSizeBytes: 0,
    conversionRootNodeName: cleanTargetNameForThreeJS(
      "SWB Expedition All In v12"
    ),

    defaultMeshVisibility: {
      showMeshes: [].map(cleanTargetNameForThreeJS),
      hideMeshes: ["Roof_up_04.001", "zipline", "window_s3.001", "window_s3.002", "window_frame_s.002", "big_blinds_2", "big_blinds_1", "swivel_chair", "KRS Roof Rack v4", "Solar Panel v5", ].map(cleanTargetNameForThreeJS),
    },
    
    // Meshes to toggle in response to enabled extras
    extraMeshes: {
      extrastableUpgrades1: {
        showMeshes: [
          "Cranked Arm Table v3.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments2: {
        showMeshes: [
          "Vamoose Awning Rail v2.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments1: {
        showMeshes: [
          "Thule Wanderway Bike Rack v9",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      "extrastracking&Safety0": {
        showMeshes: [
          "View Dash Cam v1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras7: {
        showMeshes: ["KRS Roof Rack v4"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraschargingUpgrades3: {
        showMeshes: ["Solar Panel v5"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      hasWindowUpgrades: {
        showMeshes: ["window_s3.001", "window_frame_s.002", "big_blinds_1"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["standard_blocked_off_window_2", "standard_blocked_off_window_2_internal",].map(cleanTargetNameForThreeJS),
      },
      extrasseatingUpgrades3: {
        showMeshes: ["swivel_chair"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["chair1.001", "chair1.002"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras5: {
        showMeshes: ["Roof_up_04.001"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["Roof_up_black"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras8: {
        showMeshes: ["zipline"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasadditionalKitchenStorage1: {
        // Top cupboards
        showMeshes: ["ECO Expedition All In v2"].map(cleanTargetNameForThreeJS),
        hideMeshes: [
          "Shelf Sides (1).001",
          "Shelf Sides.001",
          "Shelf front.001",
          "Body1.039",
          "Shelf",
        ].map(cleanTargetNameForThreeJS),
      },
    },

    swatchTargets: {
      splashbacks: ["Upright.001"].map(cleanTargetNameForThreeJS),

      kitchenUnits: [
        "Right Door.001",
        "Left Door.001",
        "Led Back",
        "Front.001",
        "Right.001",
        "Left drawer.001",
        "High Hatch (1)",
        "Left.001",
        "Body1.054",
        "Body1.053",
        "Body1.052",
        "Back Cover.001",
        "Gas Box.001",
        "High End (1).001",
        "High Front (1)",
        "Top Door.001",
        "High Side",
        "Shelf Sides (1).001",
        "Shelf",
        "Body1.039",
        "Shelf Sides.001",
        "High Mid.001",
        "Top Shelf.001",
        "Mid Shelf.001",
        "End Shelf.001",
        "Body1.001",
        "Body2.003",
        "Body1.060",
        "Shelf front.001",
        "Top.002",
        "Right Top Cupboard.001",
        "R Side.001",
        "Mid Top Cupboard.001",
        "Mid R.001",
        "Mid L.001",
        "Left Top Cupboard.001",
        "L Side.001",
        "Base (1)",
      ].map(cleanTargetNameForThreeJS),

      worktopFinishes: [
        "Table.001","Worktop.001", "Flap.001"].map(
        cleanTargetNameForThreeJS
      ),

      fridgeColour: ["Fridge.001"].map(cleanTargetNameForThreeJS),
      
      upholstery: [
        "swivel_chair",
        "Body1.047",
        "Body1.040",
        "Head Rest.001",
        "Body1.044",
        "Body1.043",
        "Body1.042",
        "Body1.041",
        "Body1.045",
        "chair1.001",
        "chair1.002",
        "chair.001",
        "chair.002",
        "Body1.050",
        "Body1.046",
        "Body1.049",
        "Body4",
        "Body2.001",
        "Body3.001",
        "Body1.048",
        "Body2",
        "Body3",
        "Body1.056",
        "Body3.002",
        "Body2.002",
        "Body1.058",
        "Body1.057",
        "Body1.055",
        "Body1.059",
       
      ].map(cleanTargetNameForThreeJS),
      
      vanColours: [
        "TopSideRoofRims",
        "Object013.001",
        "Roof_down_02.001",
        "doorhandle.001",
        "doorhandle_key.001",
        "doorhandle_key1.001",
        "side_mirror_1.001",
        "side_mirror_2.001",
        "fender_b1.001",
        "hood1.001",
        "front_car.001",
        "Object004.001",
        "back_car.001",
        "back_car1.001",
        "Roof_up_04.001",
        "Roof_down_01.001",
        "Object002.001",
        "standard_blocked_off_window_1",
        "standard_blocked_off_window_2",
      ].map(cleanTargetNameForThreeJS),

      vanWallLining4WayStretchCarpet: [
        "wheelcap_carpet",
        "Roof00.001",
        "frame_s.001",
        "frame_s1.001",
        "fender_s.001",
        "door_s4.001",
        "standard_blocked_off_window_1_internal",
        "standard_blocked_off_window_2_internal",
      ].map(cleanTargetNameForThreeJS),

      flooringRange: ["dashboard.003"].map(cleanTargetNameForThreeJS),

      popTopCanvas: ["Roof_up_02.001"].map(cleanTargetNameForThreeJS),

      blindsCurtains: [
        "big_blinds",
        "big_blinds_1",
        "big_blinds_2",
      ].map(cleanTargetNameForThreeJS),

      // seatingRIBAltairBedFabric: [].map(cleanTargetNameForThreeJS),

      // woodEdgeFinish: [].map(cleanTargetNameForThreeJS),
    },
  },
  standardRangerSWB: {
    name: "Ranger",
    imageUrl: "/media/webgl/conversions/thumbnails/ranger-swb.webp",
    modelUrl:
      "/media/webgl/standard-ranger-swb-in-a-volkswagen-transporter.glb",
    modelSizeBytes: 0,
    conversionRootNodeName: cleanTargetNameForThreeJS("SWB Ranger All In v1"),

    defaultMeshVisibility: {
      showMeshes: [].map(cleanTargetNameForThreeJS),
      hideMeshes: ["Roof_up_04.001", "zipline", "standard_blocked_off_window_1", "standard_blocked_off_window_1_internal", "standard_blocked_off_window_2", "standard_blocked_off_window_2_internal", "swivel_chair", "KRS Roof Rack v4", "Solar Panel v5", ].map(cleanTargetNameForThreeJS),
    },
    
    // Meshes to toggle in response to enabled extras
    extraMeshes: {
      extrastableUpgrades1: {
        showMeshes: [
          "Cranked Arm Table v3.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments2: {
        showMeshes: [
          "Vamoose Awning Rail v2.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments1: {
        showMeshes: [
          "Thule Wanderway Bike Rack v9",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      "extrastracking&Safety0": {
        showMeshes: [
          "View Dash Cam v1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras7: {
        showMeshes: ["KRS Roof Rack v4"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraschargingUpgrades3: {
        showMeshes: ["Solar Panel v5"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasseatingUpgrades3: {
        showMeshes: ["swivel_chair"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["chair1.001", "chair1.002"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras5: {
        showMeshes: ["Roof_up_04.001"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["Roof_up_black"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras8: {
        showMeshes: ["zipline"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
    },

    swatchTargets: {
      kitchenUnits: [
        "Body11.001",
        "Body7.001",
        "Body2.002",
        "Body5.001",
        "Body10.001",
        "Front",
        "Body12.001",
        "Body13.001",
        "Body16.001",
        "Body8.001",
        "Shelf Sides",
        "Body1.004",
        "Body1.002",
        "Body2.003",
        "Body1.003",
        "Body2.004",
        "Shelf Side",
        "Body7",
        "Body14",
        "Body6",
        "Body8",
        "Body13",
        "Body17",
        "Body15",
        "Body16",
        "Body9",
        "Body1",
        "Body19",
        "Body3",
        "Body2",
        "Body11",
        "Body20",
        "Body10",
      ].map(cleanTargetNameForThreeJS),

      worktopFinishes: [
        "Table.001","Table.001", "Worktop", "Body2.001",].map(cleanTargetNameForThreeJS),

      fridgeColour: ["Fridge"].map(cleanTargetNameForThreeJS),
      
      upholstery: [
        "swivel_chair",
        "Cushions.001",
        "Mid Seat (1).001",
        "Mid Seat.001",
        "Cushions (1)",
        "Mid Seat (1) (1).001",
        "chair1.001",
        "chair1.002",
        "chair.001",
        "chair.002",
      ].map(cleanTargetNameForThreeJS),

      vanColours: [
        "TopSideRoofRims",
        "Object013.001",
        "Roof_down_02.001",
        "doorhandle.001",
        "doorhandle_key.001",
        "doorhandle_key1.001",
        "side_mirror_1.001",
        "side_mirror_2.001",
        "fender_b1.001",
        "hood1.001",
        "front_car.001",
        "Object004.001",
        "back_car.001",
        "back_car1.001",
        "Roof_up_04.001",
        "Roof_down_01.001",
        "Object002.001",
        "standard_blocked_off_window_1",
        "standard_blocked_off_window_2",
      ].map(cleanTargetNameForThreeJS),

      vanWallLining4WayStretchCarpet: [
        "wheelcap_carpet",
        "Roof00.001",
        "frame_s.001",
        "frame_s1.001",
        "fender_s.001",
        "door_s4.001",
        "standard_blocked_off_window_1_internal",
        "standard_blocked_off_window_2_internal",
      ].map(cleanTargetNameForThreeJS),

      flooringRange: ["dashboard.003"].map(cleanTargetNameForThreeJS),

      popTopCanvas: ["Roof_up_02.001"].map(cleanTargetNameForThreeJS),

      blindsCurtains: [
        "big_blinds",
        "big_blinds_1",
        "big_blinds_2",
      ].map(cleanTargetNameForThreeJS),

      // seatingRIBAltairBedFabric: [].map(cleanTargetNameForThreeJS),

      // woodEdgeFinish: [].map(cleanTargetNameForThreeJS),
    },
  },
  standardRoamerSWB: {
    name: "Roamer",
    imageUrl: "/media/webgl/conversions/thumbnails/roamer-swb.webp",
    modelUrl:
      "/media/webgl/standard-roamer-swb-in-a-volkswagen-transporter.glb",
    modelSizeBytes: 0,
    conversionRootNodeName: cleanTargetNameForThreeJS("SWB Roamer All In v10"),

    defaultMeshVisibility: {
      showMeshes: [].map(cleanTargetNameForThreeJS),
      hideMeshes: ["Roof_up_04.001", "zipline", "window_s3.001", "window_frame_s.002", "big_blinds_2", "big_blinds_1", "swivel_chair", "KRS Roof Rack v4", "Solar Panel v5", ].map(cleanTargetNameForThreeJS),
    },
    
    // Meshes to toggle in response to enabled extras
    extraMeshes: {
      extrastableUpgrades1: {
        showMeshes: [
          "Cranked Arm Table v3.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments2: {
        showMeshes: [
          "Vamoose Awning Rail v2.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments1: {
        showMeshes: [
          "Thule Wanderway Bike Rack v9",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      "extrastracking&Safety0": {
        showMeshes: [
          "View Dash Cam v1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras7: {
        showMeshes: ["KRS Roof Rack v4"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraschargingUpgrades3: {
        showMeshes: ["Solar Panel v5"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      hasWindowUpgrades: {
        showMeshes: ["window_s3.001", "window_s3.002", "window_frame_s.002", "big_blinds_2", "big_blinds_1"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["standard_blocked_off_window_1", "standard_blocked_off_window_1_internal", "standard_blocked_off_window_2", "standard_blocked_off_window_2_internal",].map(cleanTargetNameForThreeJS),
      },
      extrasseatingUpgrades3: {
        showMeshes: ["swivel_chair"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["chair1.001", "chair1.002"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras5: {
        showMeshes: ["Roof_up_04.001"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["Roof_up_black"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras8: {
        showMeshes: ["zipline"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
    },

    swatchTargets: {
      kitchenUnits: ["panel 1", "cupboards", "top shelf"].map(
        cleanTargetNameForThreeJS
      ),

      worktopFinishes: [
        "Table.001","worktop"].map(cleanTargetNameForThreeJS),

      fridgeColour: ["fridge"].map(cleanTargetNameForThreeJS),
      
      upholstery: [
        "swivel_chair",
        "chair.002",
        "chair.001",
        "chair1.002",
        "chair1.001",
        "chairs",
      ].map(cleanTargetNameForThreeJS),

      vanColours: [
        "TopSideRoofRims",
        "Object013.001",
        "Roof_down_02.001",
        "doorhandle.001",
        "doorhandle_key.001",
        "doorhandle_key1.001",
        "side_mirror_1.001",
        "side_mirror_2.001",
        "fender_b1.001",
        "hood1.001",
        "front_car.001",
        "Object004.001",
        "back_car.001",
        "back_car1.001",
        "Roof_up_04.001",
        "Roof_down_01.001",
        "Object002.001",
        "standard_blocked_off_window_1",
        "standard_blocked_off_window_2",
      ].map(cleanTargetNameForThreeJS),

      vanWallLining4WayStretchCarpet: [
        "wheelcap_carpet",
        "Roof00.001",
        "frame_s.001",
        "frame_s1.001",
        "fender_s.001",
        "door_s4.001",
        "standard_blocked_off_window_1_internal",
        "standard_blocked_off_window_2_internal",
      ].map(cleanTargetNameForThreeJS),

      flooringRange: ["dashboard.003"].map(cleanTargetNameForThreeJS),

      popTopCanvas: ["Roof_up_02.001"].map(cleanTargetNameForThreeJS),

      blindsCurtains: [
        "big_blinds",
        "big_blinds_1",
        "big_blinds_2",
      ].map(cleanTargetNameForThreeJS),

      // seatingRIBAltairBedFabric: [].map(cleanTargetNameForThreeJS),

      // woodEdgeFinish: [].map(cleanTargetNameForThreeJS),
    },
  },
  standardVoyagerSWB: {
    name: "Voyager",
    imageUrl: "/media/webgl/conversions/thumbnails/voyager-swb.png",
    modelUrl:
      "/media/webgl/standard-voyager-swb-in-a-volkswagen-transporter.glb",
    modelSizeBytes: 0,
    conversionRootNodeName: cleanTargetNameForThreeJS("Front Loading v2"),

    defaultMeshVisibility: {
      showMeshes: [].map(cleanTargetNameForThreeJS),
      hideMeshes: ["Roof_up_04.001", "zipline", "window_s3.001", "window_frame_s.002", "big_blinds_2", "big_blinds_1", "swivel_chair", "KRS Roof Rack v4", "Solar Panel v5", ].map(cleanTargetNameForThreeJS),
    },
    
    // Meshes to toggle in response to enabled extras
    extraMeshes: {
      extrastableUpgrades1: {
        showMeshes: [
          "Cranked Arm Table v3.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments2: {
        showMeshes: [
          "Vamoose Awning Rail v2.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments1: {
        showMeshes: [
          "Thule Wanderway Bike Rack v9",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      "extrastracking&Safety0": {
        showMeshes: [
          "View Dash Cam v1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras7: {
        showMeshes: ["KRS Roof Rack v4"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraschargingUpgrades3: {
        showMeshes: ["Solar Panel v5"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      hasWindowUpgrades: {
        showMeshes: ["window_s3.001", "window_s3.002", "window_frame_s.002", "big_blinds_2", "big_blinds_1"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["standard_blocked_off_window_1", "standard_blocked_off_window_1_internal", "standard_blocked_off_window_2", "standard_blocked_off_window_2_internal",].map(cleanTargetNameForThreeJS),
      },
      extrasseatingUpgrades3: {
        showMeshes: ["swivel_chair"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["chair1.001", "chair1.002"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras5: {
        showMeshes: ["Roof_up_04.001"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["Roof_up_black"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras8: {
        showMeshes: ["zipline"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasadditionalKitchenStorage1: {
        // Top cupboards
        showMeshes: ["ECO Expedition All In v2"].map(cleanTargetNameForThreeJS),
        hideMeshes: [
          "Shelf Side.001",
          "Shelf Sides.001",
          "Body3.004",
          "Body2.006",
          "Body2.005",
          "Body3.005",
          "Body4.001",
        ].map(cleanTargetNameForThreeJS),
      },
    },

    swatchTargets: {
      kitchenUnits: [
        "Left drawer",
        "Body1",
        "Right (1)",
        "Left.001",
        "Door.001",
        "Front Cover",
        "Body1.020",
        "Body1.019",
        "Back Cover.001",
        "Shelf Side.001",
        "Shelf Sides.001",
        "Body3.004",
        "Body2.006",
        "Body2.005",
        "Body3.005",
        "Body4.001",
        "Right Top Cupboard.001",
        "R Side.001",
        "Mid Top Cupboard.001",
        "Mid R.001",
        "Mid L.001",
        "Left Top Cupboard.001",
        "L Side.001",
        "Base (1)",
        "Table.001",
      ].map(cleanTargetNameForThreeJS),

      worktopFinishes: [
        "Table.001","Top.001"].map(cleanTargetNameForThreeJS),

      fridgeColour: ["Fridge.001"].map(cleanTargetNameForThreeJS),
      
      upholstery: [
        "swivel_chair",
        "chair.002",
        "chair.001",
        "chair1.002",
        "chair1.001",
        "Body1.012",
        "Body1.008",
        "Body1.009",
        "Body1.010",
        "Body2.002",
        "Body3.001",
        "Body1.011",
        "Body1.016",
        "Body3.002",
        "Body2.003",
        "Body1.014",
        "Body1.013",
        "Body1.015",
        "Body1.018",
        "Head Rest.001",
        "Body1.017",
        "Body4",
        "Body1.022",
        "Body3.003",
        "Body2.004",
        "Body1.024",
        "Body1.023",
        "Body1.021",
        "Body1.025",
      ].map(cleanTargetNameForThreeJS),

      vanColours: [
        "TopSideRoofRims",
        "Object013.001",
        "Roof_down_02.001",
        "doorhandle.001",
        "doorhandle_key.001",
        "doorhandle_key1.001",
        "side_mirror_1.001",
        "side_mirror_2.001",
        "fender_b1.001",
        "hood1.001",
        "front_car.001",
        "Object004.001",
        "back_car.001",
        "back_car1.001",
        "Roof_up_04.001",
        "Roof_down_01.001",
        "Object002.001",
        "standard_blocked_off_window_1",
        "standard_blocked_off_window_2",
      ].map(cleanTargetNameForThreeJS),

      vanWallLining4WayStretchCarpet: [
        "wheelcap_carpet",
        "Roof00.001",
        "frame_s.001",
        "frame_s1.001",
        "fender_s.001",
        "door_s4.001",
        "standard_blocked_off_window_1_internal",
        "standard_blocked_off_window_2_internal",
      ].map(cleanTargetNameForThreeJS),

      flooringRange: ["dashboard.003"].map(cleanTargetNameForThreeJS),

      popTopCanvas: ["Roof_up_02.001"].map(cleanTargetNameForThreeJS),

      blindsCurtains: [
        "big_blinds",
        "big_blinds_1",
        "big_blinds_2",
      ].map(cleanTargetNameForThreeJS),

      // seatingRIBAltairBedFabric: [].map(cleanTargetNameForThreeJS),

      // woodEdgeFinish: [].map(cleanTargetNameForThreeJS),
    },
  },
  standardWayfarerSWB: {
    name: "Wayfarer",
    imageUrl: "/media/webgl/conversions/thumbnails/wayfarer-swb.webp",
    modelUrl:
      "/media/webgl/standard-wayfarer-swb-in-a-volkswagen-transporter.glb",
    modelSizeBytes: 0,
    conversionRootNodeName: cleanTargetNameForThreeJS(
      "SWB  Wayfarer All In v2"
    ),

    defaultMeshVisibility: {
      showMeshes: [].map(cleanTargetNameForThreeJS),
      hideMeshes: ["Roof_up_04.001", "zipline", "window_s3.001", "window_s3.002", "window_frame_s.002", "big_blinds_2", "big_blinds_1", "swivel_chair", "KRS Roof Rack v4", "Solar Panel v5", ].map(cleanTargetNameForThreeJS),
    },
    
    // Meshes to toggle in response to enabled extras
    extraMeshes: {
      extrastableUpgrades1: {
        showMeshes: [
          "Cranked Arm Table v3.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments2: {
        showMeshes: [
          "Vamoose Awning Rail v2.001",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extrasexternalAttachments1: {
        showMeshes: [
          "Thule Wanderway Bike Rack v9",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      "extrastracking&Safety0": {
        showMeshes: [
          "View Dash Cam v1",
        ].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras7: {
        showMeshes: ["KRS Roof Rack v4"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      extraschargingUpgrades3: {
        showMeshes: ["Solar Panel v5"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
      hasWindowUpgrades: {
        showMeshes: ["window_s3.001", "window_frame_s.002", "big_blinds_1"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["standard_blocked_off_window_2", "standard_blocked_off_window_2_internal",].map(cleanTargetNameForThreeJS),
      },
      extrasseatingUpgrades3: {
        showMeshes: ["swivel_chair"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["chair1.001", "chair1.002"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras5: {
        showMeshes: ["Roof_up_04.001"].map(cleanTargetNameForThreeJS),
        hideMeshes: ["Roof_up_black"].map(cleanTargetNameForThreeJS),
      },
      extraspoptopOptionalExtras8: {
        showMeshes: ["zipline"].map(cleanTargetNameForThreeJS),
        hideMeshes: [].map(cleanTargetNameForThreeJS),
      },
    },

    swatchTargets: {
      kitchenUnits: [
        "Low Door.001",
        "Mid Drawer.001",
        "Top Drawer.001",
        "Face Top.001",
        "Back Door.001",
        "Back End.001",
        "L Fridge.001",
        "R Fridge.001",
        "R Door.001",
        "L Door.001",
        "Face.001",
        "Front Side.001",
        "Back Side.001",
        "Front.001",
        "Trim (1)",
        "Smev Shelf",
        "R Side (1)",
        "L Side (1)",
        "Back.001",
        "Fridge Door.001",
        "L Side.001",
        "Fridge Shelf.002",
        "Front (1).001",
        "R Side.001",
        "Back (1)",
        "Trim.001",
        "Body58",
        "Body59",
        "R LED Back.001",
        "L LED Back.001",
        "LED Back.001",
        "Back Shelf.002",
        "Main Shelf.001",
        "Mid.002",
        "Back Shelf.001",
        "Mid.001",
        "Fridge Shelf.001",
        "Sides.001",
        "Front.002",
        "back (1)",
        "Sides",
        "Sides.003",
        "Front.003",
        "back (1).001",
        "Sides.002",
      ].map(cleanTargetNameForThreeJS),

      worktopFinishes: [
        "Table.001",
        "Fridge Worktop",
        "Smev Side Worktop.001",
        "Fridge Side Worktop.001",
      ].map(cleanTargetNameForThreeJS),

      fridgeColour: ["Fridge.001"].map(cleanTargetNameForThreeJS),
      
      upholstery: [
        "swivel_chair",
        "chair.001",
        "chair1.001",
        "Body1.048",
        "Body1.050",
        "Body1.056",
        "Body1.051",
        "Head Rest.001",
        "Body1.053",
        "Body1.055",
        "Body1.063",
        "Body1.042",
        "Body2",
        "Body2.001",
        "Body1.044",
        "Body1.040",
        "Body1.043",
        "R Seat.001",
        "L Seat.001",
        "Body1.013",
        "R Seat.003",
        "R Back.001",
        "Body1.017",
        "Body1.016",
        "Head Rest.003",
        "R Back",
        "Body1.016",
        "Body1.017",
        "Body1.018",
        "Body1.015",
        "Body1.019",

        "Body1.052",
        "Body1.047",
        "Body1.054",
        "Body1.049",
        "OuterSeat.001",
        "OuterSeat.002",
        "chair1.002",
        "chair.002",
        "Body4",
        "Mid Seat.001",
        "Back Mid.001",
        "Mid Seat.003",
        "Back Mid.003",
        "L Back",
        "L Seat.001",
        "L Seat.003",
        "L Back.001",
      ].map(cleanTargetNameForThreeJS),

      vanColours: [
        "TopSideRoofRims",
        "Object013.001",
        "Roof_down_02.001",
        "doorhandle.001",
        "doorhandle_key.001",
        "doorhandle_key1.001",
        "side_mirror_1.001",
        "side_mirror_2.001",
        "fender_b1.001",
        "hood1.001",
        "front_car.001",
        "Object004.001",
        "back_car.001",
        "back_car1.001",
        "Roof_up_04.001",
        "Roof_down_01.001",
        "Object002.001",
        "standard_blocked_off_window_1",
        "standard_blocked_off_window_2",
      ].map(cleanTargetNameForThreeJS),

      vanWallLining4WayStretchCarpet: [
        "wheelcap_carpet",
        "Roof00.001",
        "frame_s.001",
        "frame_s1.001",
        "fender_s.001",
        "door_s4.001",
        "standard_blocked_off_window_1_internal",
        "standard_blocked_off_window_2_internal",
      ].map(cleanTargetNameForThreeJS),

      flooringRange: ["dashboard.003"].map(cleanTargetNameForThreeJS),

      popTopCanvas: ["Roof_up_02.001"].map(cleanTargetNameForThreeJS),

      blindsCurtains: [
        "big_blinds",
        "big_blinds_1",
        "big_blinds_2",
      ].map(cleanTargetNameForThreeJS),

      // seatingRIBAltairBedFabric: [].map(cleanTargetNameForThreeJS),

      // woodEdgeFinish: [].map(cleanTargetNameForThreeJS),
    },
  },
};

console.log({conversionsAndSwatchTargets})